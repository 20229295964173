import IdeaBoxIndex from '@/views/IdeaBoxIndex'
import IdeaBoxEdit from '@/views/IdeaBoxEdit'
import SettingsIdeaBox from '@/views/SettingsIdeaBox'

const ideaboxRoutes = [
  {
    path: '/ideabox',
    name: 'IdeaBoxIndex',
    component: IdeaBoxIndex,
    meta: {
      accessConfig:  {
        moduleName: ['ideabox'],
        requiresAuth: true
      }
    }
  },
  {
    path: '/ideabox/:id',
    name: 'IdeaBoxEdit',
    component: IdeaBoxEdit,
    meta: {
      accessConfig:  {
        moduleName: ['ideabox'],
        requiresAuth: true
      }
    }
  },
  {
    path: '/settings/ideabox',
    name: 'SettingsIdeaBox',
    component: SettingsIdeaBox,
    meta: {
      accessConfig:  {
        moduleName: ['ideabox'],
        requiresAuth: true
      }
    }
  }
]

export default ideaboxRoutes
