<template>
    <div>
        <v-select
            filled
            multiple
            :title="$t('learning-portal.trainings.folder-select.title')"
            :label="$t('learning-portal.trainings.folder-select.label')"
            v-model="selectedFolders"
            :items="folders"
            item-text="name"
            item-value="id"
            :loading="loading"
            :rules="[() => {
                    if (required && selectedFolders.length === 0) {
                        return $t('validation.required.default');
                    }
                    return true;
                }
            ]"
        ></v-select>
    </div>
</template>

<script>
import { HTTP } from '@/auth'

export default {
    name: "LearningPortalFolderSelector",
    model: {
        prop: 'model',
        event: 'input'
    },
    props: {
        model: {
            type: Array,
            default: () => []
        },
        required: {
            type: Boolean,
            default: false
        },
        value: {},
    },
    data() {
        return {
            folders: [],
            selectedFolders: this.model,
            loading: false
        }
    },
    watch: {
        selectedFolders: function (val) {
            this.$emit('input', val)
        },
        model: function() {
            this.selectedFolders = this.model
        }
    },
    async created() {
        await this.getFolders()
    },
    methods: {
        async getFolders() {
            this.loading = true
            await HTTP.get('learning_portal_folder').then(function (response) {
                this.folders = response.data
                this.loading = false
            }.bind(this)).catch(function (error) {
                console.log(error)
                this.loading = false
            }.bind(this))
        }
    }
}
</script>
