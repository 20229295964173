<template>
  <div class="has-hero-container">
    <div :class="{'hide-content' : showResult}">

      <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

      <div class="sp-hero sp-bg-light-blue">

        <v-container fluid class="hero-filter">

          <v-slide-group show-arrows>
            <v-slide-item>
              <div class="filter-item temporary">
                <span class="md-caption">{{ t('filter.calendar-week') }}</span>
                <date-picker
                  ref="weekDatePicker"
                  v-model="time1"
                  type="week"
                  :lang="lang"
                  :formatter="wochenFormat"
                  @change="dateSelected('week'); time2=null"
                  @clear="clearSelected()"
                  value-type="date"
                  @panel-change="calenderChanged"
                ></date-picker>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-item temporary">
                <span class="md-caption">{{ t('filter.calendar-month') }}</span>
                <date-picker
                  v-model="time2"
                  type="month"
                  :lang="lang"
                  format="MMMM YYYY"
                  @change="dateSelected('month'); time1=null"
                  @clear="clearSelected()"
                  value-type="date"
                ></date-picker>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-search-list temporary">
                <div class="filter-item">
                  <simple-filter
                    :placeholder="$t('components.simple-filter.label.user')"
                    :output-template="output"
                    :filter-param="['user']"
                    :report-group-type="'time'"
                  ></simple-filter>
                </div>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-search-list">
                <div class="filter-item">
                  <div class="filter-item-wrapper">
                    <app-filter-search-field
                        v-model="searchTermCustomer"
                        :disable="!!currentFilter.selectedCustomer"
                        :filterLabel="$t('components.app-filter-search-field.label.customer')"
                        clearable
                        append-icon="mdi-magnify"
                        @click:clear="clearSearchCustomer"
                    ></app-filter-search-field>
                    <v-list class="search-results elevation-2"
                            v-if="!currentFilter.selectedCustomer && customers.length > 0 && customers.length < 15">
                      <v-list-item v-for="customer in customers" :key="customer.id"
                                  @click="setCustomer(customer.id, customer.name)">
                        <v-list-item-content>
                          <v-list-item-title>{{ customer.name }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </div>
              </div>
            </v-slide-item>
          </v-slide-group>
        </v-container>

      </div>

      <v-container fluid v-if="allDataLoaded && !hasAnyResult" class="statistics">
        <v-row>
          <h3>{{ t('no-data') }}</h3>
        </v-row>
      </v-container>

      <v-container fluid v-if="!userLoaded && activePeriod !== '' && hasAnyResult ">
        <v-row class="data-table-toolbar">
          <v-spacer></v-spacer>
          <v-btn
            v-if="activePeriod === 'week'"
            elevation="0"
            color="primary"
            class="icon-left"
            :disabled="isProcessing"
            :loading="isDownloadingCSV"
            @click="generateRapportListen('week')"
          >
            <v-icon>mdi-download</v-icon>
            {{ $t("timelog-reports.week-report-export", { getSelectedTime: getSelectedTime }) }}
          </v-btn>
          <v-btn
            v-if="activePeriod === 'month'"
            elevation="0"
            color="primary"
            class="icon-left"
            :disabled="isProcessing"
            :loading="isDownloadingCSV"
            @click="generateRapportListen('month')"
          >
            <v-icon>mdi-download</v-icon>
            {{ $t("timelog-reports.month-report-export", { getSelectedTime: getSelectedTime }) }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>

      <v-container fluid v-if="isProcessing" class="statistics">
        <v-row class="justify-center">
          <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
          ></v-progress-circular>
        </v-row>
      </v-container>

      <v-container fluid v-if="userLoaded && activePeriod !== '' && hasAnyResult">
        <v-row class="data-table-toolbar">
          <h2 class="sp-mar-l-1">{{ t('report-title') }}</h2>
          <v-spacer></v-spacer>
          <v-btn
              elevation="0"
              color="primary"
              class="icon-left"
              :disabled="!readyToDownload"
              :loading="isDownloadingCSV"
              @click="saveAsCSV()"
          >
            <v-icon>mdi-download</v-icon>
            {{ t('report-export') }}
          </v-btn>
        </v-row>
      </v-container>

      <!-- only show if user and one of times is selected -->
      <v-container fluid v-if="evaluations['time'] && userLoaded && (time1 || time2)">
        <v-row>
          <time-sheets
            v-if="evaluations['time'].value.hasData"
            :dataCollection="evaluations['time'].value"
            :title="evaluations['time'].title"
          ></time-sheets>
        </v-row>
      </v-container>

      <v-snackbar
        ref="snackbar"
        v-model="snackbar"
        timeout="4000"
      >
        {{ message }}
      </v-snackbar>

    </div>

    <show v-if="showResult" :reportId="reportId"></show>

  </div>

</template>

<script>
import { HTTP } from '@/auth'
import Axios from 'axios'
import moment from 'moment'
import { EventBus } from '@/event-bus.js'
import toolbar from '@/components/layouts/Navigation'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import SimpleFilter from '@/components/filter/SimpleFilter'
import show from '@/views/ReportsShow'
import loading from '@/components/layouts/Loading'
import TimeSheets from '@/components/evaluations/TimeSheets'
import TimesheetStatusFilter from '@/components/filter-components/TimesheetStatusFilter'
import AppFilterSearchField from '@/components/vuetify/AppFilterSearchField'

export default {
  name: 'TimesheetIndex',
  components: {
    TimeSheets,
    SimpleFilter,
    DatePicker,
    toolbar,
    show,
    loading,
    TimesheetStatusFilter,
    AppFilterSearchField
  },
  watch: {
    searchTermCustomer: function (val) {
      this.loading = false
      if (val !== '') {
        this.getSearchResultCustomer(encodeURIComponent(val))
      }
    },
  },
  data() {
    return {
      showResult: false,
      dateStart: {
        timestamp: moment().format()
      },
      dateEnd: {
        timestamp: moment().format()
      },
      lang: {
        formatLocale: {
          weekdaysMin: ['SO', 'MO', 'DI', 'MI', 'DO', 'FR', 'SA'],
          monthsShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
          months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
          firstDayOfWeek: 1,
          firstWeekContainsDate: 1
        }
      },
      wochenFormat: {
        stringify: (date) => {
          return date ? 'Woche ' + moment(date).isoWeek() : ''
        },
        parse: (value) => {
          return value ? moment.isoWeek(value).toDate() : null
        }
      },
      message: null,
      firstDataLoaded: false,
      allDataLoaded: true,
      isDownloadingCSV: false,
      isProcessing: false,
      searchTermCustomer: '',
      error: null,
      evaluations: {},
      hasAnyResult: false,
      userLoaded: false,
      activePeriod: '',
      time1: null,
      time2: null,
      customers: [],
      output: [{
        title: 'Zeiterfassung',
        type: 'time',
        visible: true,
        options: []
      }],
      currentFilter: {
        id: null,
        filter: {
          filterGroups: [],
          reportGroupType: 'time'
        },
        sortBy: null,
        sortType: null,
        name: null,
        output: [{
          title: 'Zeiterfassung',
          type: 'time',
          visible: true,
          options: []
        }],
        status: null,
        selectedCustomer: null,
      },
      sort: {
        sortBy: null,
        sortType: null
      },
      evaluationCallStack: null,
      httpCancelHandler: null,
      snackbar: false
    }
  },
  mounted() {
    this.setFirstDateToFirstWeekOfYear(moment()) // for correct numbers of calender week
    EventBus.$on('http-error', function (msg) {
      this.message = msg
      this.isDownloadingCSV = false
      this.error = true
      this.openSnackbar()
    }.bind(this))
    EventBus.$on('current-filter', function (filter) {
      this.currentFilter = filter
      this.currentFilter.filter.reportGroupType = 'time'
      this.userLoaded = this.currentFilter.filter.filterGroups.length === 1
      this.evaluations = {}
      
      if (this.time1 != null || this.time2 != null) {
        this.dateSelected(this.activePeriod);
      }

    }.bind(this))
    EventBus.$on('sorting-changed', function (sort) {
      this.sort.sortBy = sort.name
      this.sort.sortType = sort.type
    }.bind(this))
  },
  created() {
  },
  beforeDestroy() {
    this.evaluationCallStack = []
    EventBus.$off('current-filter')
    EventBus.$off('http-error')
  },
  
  computed: {
    readyToDownload: function () {
      return this.allDataLoaded && !this.isDownloadingCSV
    },
    getSelectedTime: function () {
      return this.activePeriod === 'week' ? moment(this.dateStart.timestamp).isoWeek() : this.getMonthName(moment(this.dateStart.timestamp).month())
    }
  },
  methods: {
    t: function (key) {
      return this.$t('timelog-reports.' + key);
    },
    setFirstDateToFirstWeekOfYear(date) { // for correct numbers of calender week
      let startOfYear = moment(date).startOf('year')
      let firstDayofFirstWeek = 1
      if( startOfYear.isoWeek() != 1) {
        firstDayofFirstWeek = startOfYear.add(1, 'w').startOf('isoWeek').dayOfYear()
      }
      this.lang.formatLocale.firstWeekContainsDate = firstDayofFirstWeek
    },
    calenderChanged(panel, oldPanel) {
      this.setFirstDateToFirstWeekOfYear(this.$refs.weekDatePicker.$refs.popup.$children[0].innerCalendar)
    },
    dateSelected(type) {
      if (type === 'week') {
        this.dateStart.timestamp = this.time1 ? moment(this.time1).startOf('isoWeek').format() : null
        this.dateEnd.timestamp = this.time1 ? moment(this.time1).endOf('isoWeek').format() : null
      } else {
        this.dateStart.timestamp = this.time2 ? moment(this.time2).startOf('month').format() : null
        this.dateEnd.timestamp = this.time2 ? moment(this.time2).endOf('month').format() : null
      }
      this.activePeriod = type
      this.getTimeSheets()
    },
    clearSelected() {
      this.activePeriod = ''
    },
    getTimeSheets() {
      this.isProcessing = true
      if (!this.currentFilter) {
        return
      }
      if (this.httpCancelHandler) {
        this.httpCancelHandler()
      }
      this.evaluations = {}
      this.evaluationCallStack = []
      this.httpCancelHandler = null
      this.firstDataLoaded = false
      this.allDataLoaded = false
      this.hasAnyResult = false
      this.error = null
      var outputFormats = this.currentFilter.output.filter(x => x.visible)
      if (outputFormats.some(x => x.type === 'time')) {
        this.evaluationCallStack.push('time')
      }
      this.callEvaluationStack(0, this.currentFilter.filter)
    },
    callEvaluationStack(filter) {

      if (this.evaluationCallStack.length === 0) {
        this.allDataLoaded = true
        this.isProcessing = false
        return
      }
      var currentObjectTypeForRequest = this.evaluationCallStack.pop()
      var url = this.preprareUrl();

      HTTP.post('statistics/' + currentObjectTypeForRequest + '/de' + url, this.currentFilter, {
        cancelToken: new Axios.CancelToken(function executor(c) {
          this.httpCancelHandler = c
        }.bind(this))
      }).then(function (response) {
        if (!response.data || response.data.length === 0) {
          this.callEvaluationStack(filter)
          return
        }
        if (response.data.hasData) {
          response.data.timelogs = response.data.timelogs.filter(x => moment(x.endDate).format() > moment(this.dateStart.timestamp).format())
        }
        this.evaluations[currentObjectTypeForRequest] = {
          type: currentObjectTypeForRequest,
          title: this.output.find(x => x.type === currentObjectTypeForRequest).title,
          value: response.data
        }

        if (response.data.hasData) {
          this.hasAnyResult = true
          this.firstDataLoaded = true
        }

        this.callEvaluationStack(filter)
      }.bind(this))
    },
    saveAsCSV() {
      this.isDownloadingCSV = true
      var url = this.preprareUrl()

      this.currentFilter.type = this.activePeriod
      this.currentFilter.timelogs = this.evaluations.time.value.timelogs

      HTTP.post('statistics/rapport/' + 'de' + url, this.currentFilter, {responseType: 'arraybuffer'}).then(function (response) {
        let name = this.activePeriod === 'week' ? 'Wochenzeitrapport' : 'Monatszeitrapport'
        let headers = response.headers
        let blob = new Blob([response.data], {type: headers['content-type']})
        let link = document.createElement('a')
        link.style = 'display: none'
        link.href = window.URL.createObjectURL(blob)
        link.download = 'TimeApp_' + moment().format('DDMMYYYY-HHmm') + '_' + name.replace(' ', '-') + '.xlsx'
        document.body.appendChild(link)
        link.click()
        setTimeout(function () {
          document.body.removeChild(link)
          window.URL.revokeObjectURL(blob)
        }, 100)
        this.isDownloadingCSV = false
      }.bind(this))
    },
    generateRapportListen(type) {
      this.isDownloadingCSV = true
      var url = this.preprareUrl()

      this.currentFilter.type = type

      HTTP.post('statistics/rapportlisten/' + 'de' + url, this.currentFilter, {responseType: 'arraybuffer'}).then(function (response) {
        let name = this.activePeriod === 'week' ? 'Wochenzeitrapporte Woche ' + moment(this.dateStart.timestamp).isoWeek() : 'Monatszeitrapporte Monat ' + this.getMonthName(moment(this.dateStart.timestamp).month())
        let headers = response.headers
        let blob = new Blob([response.data], {type: headers['content-type']})
        let link = document.createElement('a')
        link.style = 'display: none'
        link.href = window.URL.createObjectURL(blob)
        link.download = 'TimeApp_' + name.replace(' ', '-') + '.zip'
        document.body.appendChild(link)
        link.click()
        setTimeout(function () {
          document.body.removeChild(link)
          window.URL.revokeObjectURL(blob)
        }, 100)
        this.isDownloadingCSV = false
      }.bind(this))
    },
    openSnackbar() {
      setTimeout(() => {
        this.snackbar = true
      }, 2000)
    },
    getMonthName(i) {
      return this.lang.formatLocale.months[i]
    },
    preprareUrl() {
      let dateStartMinus1 = moment(this.dateStart.timestamp).subtract(1, 'days')
      return '?after=' + encodeURIComponent(dateStartMinus1.format()) + '&before=' + encodeURIComponent(this.dateEnd.timestamp) + '&week=' + moment(this.dateStart.timestamp).isoWeek() + '&year=' + moment(this.dateStart.timestamp).year()
    },
    getSearchResultCustomer(val) {
      if (this.cancelHTTP) {
        this.cancelHTTP()
      }
      HTTP.get('customers?q=' + val, {
        cancelToken: new Axios.CancelToken(function executor(c) {
          this.cancelHTTP = c
        }.bind(this))
      }).then(function (response) {
        this.customers = response.data.data;
        this.error = false;
        this.dataLoaded = true;
      }.bind(this)).catch(function (error) {
        this.errorCancel = error;
      }.bind(this))
    },
    setCustomer(id, name) {
      this.currentFilter.selectedCustomer = id;
      this.customers = [];
      this.searchTermCustomer = name;
      this.filterChanged = true;
      if (this.time1 != null || this.time2 != null) {
        this.dateSelected(this.activePeriod);
      }
    },
    clearSearchCustomer() {
      this.currentFilter.selectedCustomer = null;
      this.customers = [];
      this.searchTermCustomer = '';
      this.filterChanged = true;
      if (this.time1 != null || this.time2 != null) {
        this.dateSelected(this.activePeriod);
      }
    }
  }
}
</script>

<style lang="scss">
/* temp */
.filter-search-list.temporary {

  .md-caption {
    font-weight: 500;
    letter-spacing: 0.01em;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF !important;
  }

  .sp-search-field-lists {
    margin: 0;
    padding-top: 24px;

    &:after {
      content: none;
    }

    .sp-search-field-inner {
      border-radius: 4px;
    }
  }
}

.filter-item.temporary {

  .md-caption {
    display: block;
    font-weight: 500;
    letter-spacing: 0.01em;
    font-size: 14px;
    line-height: 24px;
  }

  .mx-datepicker {
    margin: 0;
    padding: 0;

    .mx-input-wrapper {
      border-radius: 4px;
    }
  }
}

</style>

<style scoped lang="scss">
/* temp */
.sp-hero .filter-search-list {
  padding-top: 26px;
  // width: calc(66.66% - 134px);
}

/* temp */
.sp-app.v-application {

  .container--fluid.hero-filter {
    padding-bottom: 28px;
  }
}

/* temp */
.data-table-toolbar h2 {
  margin: 0;
}

/* temp */
.md-table-card {
  margin-bottom: 60px;
}

.user-selected .md-icon {
  margin: 4px !important;
  color: #FFF !important;
}

.user-selected .md-icon.delete {
  margin-left: auto !important;
}

.user-selected .md-icon.delete:hover {
  cursor: pointer;
}
</style>

