<template>
  <div>
    <BasicNewContainer
      :title="t('title')"
      ref="editor"
      @cancel="close"
      :save-action="saveItem"
      @saved="onSaved"
      :has-changes="hasChanges"
    >
      <timelog-sites-content v-model="data.data" />
    </BasicNewContainer>
  </div>
</template>

<script>
import { HTTP } from "./../auth"
import BasicNewContainer from "@/components/inputs/BasicNewContainer"
import TimelogSitesContent from "@/views/TimelogSettingsSitesContent.vue"

export default {
  name: "TimelogSitesCreate",
  components: {
    TimelogSitesContent,
    BasicNewContainer
  },
  data() {
    return {
      data: {
        name: ""
      },
      dataOriginal: null,
      indexRouteName: "TimelogSitesIndex"
    }
  },
  created() {
    this.dataOriginal = JSON.parse(JSON.stringify(this.data))
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal)
    }
  },
  methods: {
    t: function(key) {
      return this.$t("timelog-sites-create." + key)
    },
    saveItem() {
      return HTTP.post("timelog/sites", this.data.data)
    },
    async onSaved() {
      this.dataOriginal = null
      this.close()
    },
    close() {
      this.$router.push({ name: this.indexRouteName })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
