<template>
  <div class="filter-item">
    <div class="filter-requests-state-filter">
      <v-select
        v-model="value"
        :label="$t('components.salutation.label')"
        :items="filterArea"
        item-text="name"
        null-value="null"
        item-value="slug"
        @input="emitInput"
        class="mb-4"
        chips
        data-cy="salutation"
        clearable
      ></v-select>
    </div>
  </div>

</template>

<script>
export default {
  name: "SalutationSelect",
  props: {
    value: String,
  },
  data() {
    return {
      filterArea: [
        {
          name: this.t("mr"),
          slug: 'mr'
        },
        {
          name: this.t("ms"),
          slug: 'ms'
        },
        {
          name: this.t("dr"),
          slug: 'dr'
        },
        {
          name: this.t("prof"),
          slug: 'prof'
        }
      ],
    }
  },
  methods: {
    t: function (key) {
      return this.$t('components.salutation.values.' + key);
    },
    emitInput(newValue) {
      this.$emit('input', newValue);
    },
  }
}
</script>
