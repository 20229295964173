<template>
  <div>
    <BasicEditContainer
      :title="t('title')"
      ref="editor"
      :data-loaded="dataLoaded"
      v-if="dataLoaded"
      @cancel="cancel"
      :save-action="saveItem"
      @saved="close"
      :has-changes="hasChanges"
      :allowDelete="false">

      <timelog-sites-content v-model="data" />

    </BasicEditContainer>
  </div>
</template>

<script>
import { HTTP } from "./../auth"
import BasicEditContainer from "@/components/inputs/BasicEditContainer"
import TimelogSitesContent from "@/views/TimelogSettingsSitesContent.vue"

export default {
  name: "TimelogSitesEdit",
  components: {
    TimelogSitesContent,
    BasicEditContainer
  },
  data() {
    return {
      itemId: null,
      data: null,
      dataOriginal: null,
      dataLoaded: false,
      indexRouteName: "TimelogSitesIndex"
    }
  },
  async created() {
    this.itemId = this.$route.params.id
    await this.getItem()
  },
  computed: {
    hasChanges() {
      if (!this.dataOriginal) {
        return false
      }
      return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal)
    }
  },
  methods: {
    t: function(key) {
      return this.$t("timelog-sites-edit." + key)
    },
    async getItem() {
      let response = await HTTP.get("timelog/sites/" + this.itemId)
      this.data = response.data
      this.dataOriginal = JSON.parse(JSON.stringify(this.data))
      this.dataLoaded = true
    },
    saveItem() {
      return HTTP.put("timelog/sites/" + this.itemId, this.data)
    },
    async onSaved() {
      this.dataOriginal = null
      this.close()
    },
    cancel() {
      this.close()
    },
    close() {
      this.$router.push({ name: this.indexRouteName })
    }
  }
}
</script>

<style scoped lang="scss">

</style>

