<template>
    <div>
        <BasicEditContainer
            :title="t('title')"
            ref="editor"
            @cancel="cancel"
            :saveAction="saveItem"
            :data-loaded="dataLoaded"
            :delete-action="deleteItem"
            @deleted="close"
            @saved="close"
            :has-changes="hasChanges"
        >
            <template v-slot:default>
                <v-card elevation="0">
                    <v-card-title>{{ t('details-title') }}</v-card-title>
                    <v-card-text>
                        <content-customer
                            :label="t('choose_client')"
                            v-model="data.customer_id"
                            data-cy="customer_id"
                            :get-search-url="getCustomerSearchUrl"
                            :rules="[
								() => !!data.customer_id || $t('validation.required.client')
							]"
                        ></content-customer>
                        
                        <v-row>
                            <v-col>
                                <content-filter-user
                                    class="mt-3"
                                    v-model="data.colleague_id"
                                    data-cy="colleague_id"
                                    :label="t('employee')"
                                    :rules="[
                                        () => !!data.colleague_id || $t('validation.required.employee')
                                    ]"
                                ></content-filter-user>
                            </v-col>
                        </v-row>
                        
                        <v-textarea
                            :label="t('notes')"
                            counter="1000"
                            class="mt-3"
                            v-model="data.notes"
                            data-cy="notes"
                            filled
                            hide-details="auto"
                            :rules="[
								() => !!data.notes || $t('validation.required.notes'),
                                () => (!data.notes || (data.notes && data.notes.length <= 1000)) || t('validation.error-max-length')
							]"
                        ></v-textarea>
                    </v-card-text>
                </v-card>

                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('picture-title') }}</v-card-title>
                    <v-card-text>
                        <picture-upload-multi v-model="data.picture"></picture-upload-multi>
                    </v-card-text>
                </v-card>

                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('qm-title') }}</v-card-title>
                    <v-card-text>
                        <div class="custom-label ">{{ t('qm-enabled') }}</div>
                        <v-switch
                            class="mt-2"
                            v-model="data.qm"
                            data-cy="qm"
                            inset
                            :label="data.qm ? t('yes') : t('no')"
                        ></v-switch>
                            
                        <v-textarea
                            v-if="data.qm"
                            class="mt-4"
                            :label="t('qm_note')"
                            counter="1000"
                            v-model="data.qm_note"
                            data-cy="qm_note"
                            filled
                            :rules="[
                                () => (!data.qm_note || (data.qm_note && data.qm_note.length <= 1000)) || t('validation.error-max-length')
							]"
                        ></v-textarea>
                    </v-card-text>
                </v-card>

                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('special-cleaning-title') }}</v-card-title>
                    <v-card-text>
                        <div class="custom-label ">{{ t('special-cleaning-enabled') }}</div>
                        <v-switch
                            class="mt-2"
                            v-model="data.special_cleaning"
                            data-cy="special_cleaning"
                            inset
                            :label="data.special_cleaning ? t('yes') : t('no')"
                        ></v-switch>
                            
                        <v-textarea
                            v-if="data.special_cleaning"
                            class="mt-4"
                            :label="t('qm_note')"
                            counter="1000"
                            v-model="data.special_cleaning_notes"
                            data-cy="special_cleaning_notes"
                            filled
                            :rules="[
                                () => (!data.special_cleaning_notes || (data.special_cleaning_notes && data.special_cleaning_notes.length <= 1000)) || t('validation.error-max-length')
							]"
                        ></v-textarea>
                    </v-card-text>
                </v-card>

                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('proposal-title') }}</v-card-title>
                    <v-card-text>
                        <div class="custom-label ">{{ t('proposal-enabled') }}</div>
                        <v-switch
                            class="mt-2"
                            v-model="data.proposal"
                            data-cy="proposal"
                            inset
                            :label="data.proposal ? t('yes') : t('no')"
                        ></v-switch>

                        <v-select
                            v-if="data.proposal"
                            class="mt-4"
                            :items="selectItems"
                            v-model="data.proposal_approved"
                            data-cy="proposal_approved"
                            item-text="label"
                            item-value="id"
                            :label="t('proposal-approved')"
                        ></v-select>

                        <v-textarea
                            v-if="data.proposal"
                            :label="t('proposal-notes')"
                            counter="1000"
                            class="mt-3"
                            v-model="data.proposal_notes"
                            data-cy="proposal_notes"
                            filled
                            :rules="[
                                () => (!data.proposal_notes || (data.proposal_notes && data.proposal_notes.length <= 1000)) || t('validation.error-max-length')
							]"
                        ></v-textarea>
                    </v-card-text>
                </v-card>

                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('leader-visitation-title') }}</v-card-title>
                    <v-card-text>
                        <div class="custom-label ">{{ t('leader-visitation-enabled') }}</div>
                        <v-switch
                            class="mt-2"
                            v-model="data.leader_visitation"
                            data-cy="leader_visitation"
                            inset
                            :label="data.leader_visitation ? t('yes') : t('no')"
                        ></v-switch>
                        
                        <v-row v-show="data.leader_visitation" class="mt-1 mb-0">
                            <v-col class="pr-2 pb-2">
                                <app-filter-date-picker
                                    :filterLabel="t('date')"
                                    v-model="data.leader_visitation_date"
                                    data-cy="leader_visitation_date"
                                    id="dateStart"
                                    :clearable="true"
                                ></app-filter-date-picker>
                            </v-col>

                            <v-col class="pl-2 pb-2">
                                <content-filter-user
                                    v-model="data.leader_id"
                                    data-cy="leader_id"
                                    :label="t('leader')"
                                ></content-filter-user>
                            </v-col>
                        </v-row>
                            
                        <v-textarea
                            v-if="data.leader_visitation"
                            :label="t('leader_notes')"
                            counter="1000"
                            v-model="data.leader_notes"
                            data-cy="leader_notes"
                            filled
                            :rules="[
                                () => (!data.leader_notes || (data.leader_notes && data.leader_notes.length <= 1000)) || t('validation.error-max-length')
							]"
                        ></v-textarea>
                    </v-card-text>
                </v-card>

                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('client-communication-title') }}</v-card-title>
                    <v-card-text>
                        <div class="custom-label ">{{ t('client-communication-enabled') }}</div>
                        <v-switch
                            class="mt-2"
                            v-model="data.client_communication"
                            data-cy="client_communication"
                            inset
                            :label="data.client_communication ? t('yes') : t('no')"
                        ></v-switch>
                        
                        <v-row v-show="data.client_communication" class="mt-1 mb-0">
                            <v-col class="pr-2 pb-2">
                                <app-filter-date-picker
                                    :filterLabel="t('date')"
                                    v-model="data.client_communication_date"
                                    data-cy="client_communication_date"
                                    id="dateStart"
                                    :clearable="true"
                                ></app-filter-date-picker>
                            </v-col>

                            <v-col class="pl-2 pb-2">
                                <content-filter-user
                                    v-model="data.client_communication_user_id"
                                    data-cy="client_communication_user_id"
                                    :label="t('as')"
                                ></content-filter-user>
                            </v-col>
                        </v-row>

                        <v-text-field
                            v-if="data.client_communication"
                            :label="t('contact_person')"
                            v-model="data.contact_person"
                            data-cy="contact_person"
                            filled
                            hide-details="auto"
                        ></v-text-field>
                            
                        <v-textarea
                            v-if="data.client_communication"
                            :label="t('leader_notes')"
                            counter="1000"
                            class="mt-4"
                            v-model="data.contact_person_notes"
                            data-cy="contact_person_notes"
                            filled
                            :rules="[
                                () => (!data.contact_person_notes || (data.contact_person_notes && data.contact_person_notes.length <= 1000)) || t('validation.error-max-length')
							]"
                        ></v-textarea>
                    </v-card-text>
                </v-card>

                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('offer-title') }}</v-card-title>
                    <v-card-text>
                        <div class="custom-label ">{{ t('offer-enabled') }}</div>
                        <v-switch
                            class="mt-2"
                            v-model="data.offer_created"
                            data-cy="offer_created"
                            inset
                            :label="data.offer_created ? t('yes') : t('no')"
                        ></v-switch>

                        <v-text-field
                            v-if="data.offer_created"
                            class="mt-4 mb-0 pb-0"                      
                            :label="t('offer-number')"
                            v-model="data.offer_number"
                            data-cy="offer_number"
                            filled
                            hide-details="auto"
                        ></v-text-field>

                        <div class="mt-4 custom-label ">{{ t('surface-enabled') }}</div>
                        <v-switch
                            class="mt-2"
                            v-model="data.surface_created"
                            data-cy="surface_created"
                            inset
                            :label="data.surface_created ? t('yes') : t('no')"
                        ></v-switch>

                        <picture-upload-multi
                            v-if="data.surface_created"
                            class="mt-4"
                            v-model="data.sample_area_picture">
                        </picture-upload-multi>
              
                        <app-filter-date-picker
                            v-if="data.surface_created"
                            class="mt-4"
                            :filterLabel="t('date')"
                            v-model="data.offer_date"
                            data-cy="offer_date"
                            id="dateStart"
                            :clearable="true"
                        ></app-filter-date-picker>

                        <v-textarea
                            v-if="data.surface_created"
                            class="mt-4"
                            :label="t('offer-notes')"
                            counter="1000"
                            v-model="data.offer_notes"
                            data-cy="offer_notes"
                            filled
                            :rules="[
                                () => (!data.offer_notes || (data.offer_notes && data.offer_notes.length <= 1000)) || t('validation.error-max-length')
							]"
                        ></v-textarea>
                    </v-card-text>
                </v-card>

            </template>

            <template v-slot:meta>
                <v-card elevation="0" >
                    <v-card-title>{{ t('date-title') }}</v-card-title>
                    <v-card-text>
                        <div>
                            <app-filter-date-picker
                                :filterLabel="t('note-date-choose')"
                                v-model="data.note_date"
                                data-cy="note_date"
                                id="dateStart"
                                :clearable="true"
                            ></app-filter-date-picker>
                        </div>
                    </v-card-text>
                </v-card>

                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('status') }}</v-card-title>
                    <v-card-text>
                        <div>
                            <customer-notes-status-filter :multiple="false" v-model="data.status"  data-cy="status"/>
                        </div>
                    </v-card-text>
                </v-card>
            </template>
        </BasicEditContainer>
    </div>
</template>

<script>
import { HTTP } from '@/auth'
import BasicEditContainer from '@/components/inputs/BasicEditContainer'
import UserMultiSelector from '@/components/UserMultiSelector'
import PictureUpload from '@/components/inputs/PictureUpload'
import AppFilterDatePicker from '@/components/vuetify/AppFilterDatePicker'
import CustomerNotesStatusFilter from '@/components/filter-components/CustomerNotesStatusFilter'
import ContentFilterEmployee from '@/components/filter-components/ContentFilterEmployee'
import ContentFilterUser from '@/components/filter-components/ContentFilterUser'
import ContentCustomer from '@/components/filter-components/ContentCustomer'
import PictureUploadMulti from '@/components/inputs/PictureUploadMulti'
import ContentFilterCustomer from '@/components/filter-components/ContentFilterCustomer'

export default {
    name: 'CustomerNotesEdit',
    components: {
      ContentFilterCustomer,
        PictureUpload,
        UserMultiSelector,
        BasicEditContainer,
        AppFilterDatePicker,
        CustomerNotesStatusFilter,
        ContentFilterEmployee,
        ContentFilterUser,
        ContentCustomer,
        PictureUploadMulti
    },
    created() {
        this.itemId = this.$route.params.id
        this.getNote()
    },
    data() {
        return {
            itemId: null,
            dataLoaded: false,
            data: {
                customer_id: null,
                colleague_id: null,
                notes: '',
                picture: '',
                sample_area_picture: '',
                note_date: '',
                status: [],
                qm: false,
                qm_note : '',
                special_cleaning: false,
                special_cleaning_notes: '',
                proposal: false,
                proposal_approved: false,
                proposal_notes: '',
                leader_visitation: false,
                leader_visitation_date: '',
                leader_id: {},
                leader_notes: '',
                client_communication: false,
                client_communication_date: '',
                client_communication_user_id: {},
                contact_person: '',
                contact_person_notes: '',
                offer_created: false,
                offer_number: '',
                surface_created: false,
                offer_date: '',
                offer_notes: ''
            },
            assignedCustomer: null,
            dataOriginal: null,
            selectItems: [
                { id: 0, label: this.t('no') },
                { id: 1, label: this.t('yes') }
            ]
        }
    },
    computed: {
        hasChanges() {
            return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal);
        }
    },
    methods: {
        getPreviewUrl(file) {
          return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = () => {
                resolve(reader.result);
              };
              reader.onerror = (error) => {
                reject(error);
              };
              reader.readAsDataURL(file);
          });
        },
        t: function (key) {
            return this.$t('customer_notes.create.' + key)
        },
        deleteItem() {
            return HTTP.delete('customer_notes/' + this.itemId)
        },
        getNote() {
            HTTP.get('customer_notes/' + this.itemId).then((response) => {
                let refactor = response.data

                if (refactor.customer != null) {
                    refactor.customer_id = refactor.customer
                }

                if (refactor.leader != null) {
                    refactor.leader_id = refactor.leader
                }

                if (refactor.colleague != null) {
                    refactor.colleague_id = refactor.colleague
                }

                if (refactor.communication_user != null) {
                    refactor.client_communication_user_id = refactor.communication_user
                }

                this.data = refactor
                this.dataOriginal = JSON.parse(JSON.stringify(this.data))
                this.dataLoaded = true                         
            })
        },
        saveItem() {        
            let data = Object.assign({}, this.data)
            
            if (data.client_communication_user_id != null) {
                data.client_communication_user_id = data.client_communication_user_id.id
            }

            if (data.colleague_id != null) {
                data.colleague_id = data.colleague_id.id
            }

            if (data.customer_id != null) {
                data.customer_id = data.customer_id.id
            }

            if (data.leader_id != null) {
                data.leader_id = data.leader_id.id
            }           

            data.qm = data.qm ? 1 : 0
            data.special_cleaning = data.special_cleaning ? 1 : 0
            data.proposal = data.proposal ? 1 : 0
            data.leader_visitation = data.leader_visitation ? 1 : 0
            data.client_communication = data.client_communication ? 1 : 0
            data.offer_created = data.offer_created ? 1 : 0
            data.surface_created = data.surface_created ? 1 : 0

            HTTP.put('/customer_notes/' + data.id, data).then(function () {
                this.close()
            }.bind(this)).catch(function (error) {
                console.log(error)
            }.bind(this))
        },
        close() {
            this.cancel()
        },
        cancel() {
            this.$router.push({ name: 'CustomerNotesIndex' })
        },
        getCustomerSearchUrl(val) {
            return 'tasks/customers?q=' + val
        }
    }
}
</script>
