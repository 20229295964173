<template> 
  <div class="selected-filter-status status-icon-widget">
      <v-tooltip bottom v-if="hideText">
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            :class="'status-' + filterObject.slug" 
            v-bind="attrs" 
            v-on="on"
            class="tooltip"
            >
            <v-icon>{{ filterObject.icon }}</v-icon>
          </v-chip>
        </template>
        <span>{{ filterObject.name }}</span>
      </v-tooltip>

      <v-chip v-else
        :class="'status-' + filterObject.slug">
        <v-icon>{{ filterObject.icon }}</v-icon>
        {{ filterObject.name }}
      </v-chip>
  </div>
</template>

<script>
import { getFilterStatus } from '@/components/filter-components/SelectStatus'

export default {
  name: "StatusIconWidget",
  props: {
    status: {
      type: String,
      required: true
    },
    hideText: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      filterStatus: this.getFilterStatus(),
      filterObject: {}
    }
  }, 
  created() {
      let temp = this.filterStatus.filter(obj => {
      return obj.slug === this.status
    })
    if (temp.length > 0) {  
      this.filterObject = temp[0]
    }
  },  
  methods: {
    getFilterStatus
  }
}
</script>