<template>
  <div>

    <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

    <v-container fluid class="edit-screen no-hero-container">

      <v-row class="data-table-toolbar">
        <v-spacer></v-spacer>
        <v-btn
            elevation="0"
            color="primary"
            class="icon-left"
            :to="{ name: 'LearningPortalFoldersCreate'}"
        >
          <v-icon>mdi-plus</v-icon>
          {{ t('create') }}
        </v-btn>
      </v-row>

      <v-row v-if="dataLoaded && folders.length === 0">
        <div class="empty-datatable">
          <v-icon>mdi-alert-circle-outline</v-icon>
          {{ t('no-data') }}
        </div>
      </v-row>

      <v-row v-else>

        <v-data-table
            :headers="headers"
            :items="folders"
            :loading="loading"
            class="elevation-0"
            hide-default-footer           
        >
          <template #body="props">
            <tbody v-if="loading"><tr><td colspan="6" class="table-loader"><v-progress-linear indeterminate color="primary"></v-progress-linear></td></tr></tbody>
            <draggable
              v-else
              :list="props.items"
              tag="tbody"
              :move="onMoveCallback"
              :clone="onCloneCallback"
              @end="onDropCallback(props.items)"
            >
              <data-table-learning-portal-folder-row-handler
                v-for="(item, index) in props.items"
                :row-index="index"
                :item="item"
                :headers="headers"
              ></data-table-learning-portal-folder-row-handler>
            </draggable>
          </template>
        </v-data-table>
      </v-row>

    </v-container>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import toolbar from '@/components/layouts/Navigation'
import DataTableLearningPortalFolderRowHandler from '@/components/DataTableLearningPortalFolderRowHandler'
import draggable from 'vuedraggable'

export default {
  name: 'LearningPortalFoldersIndex',
  components: {
    toolbar,
    DataTableLearningPortalFolderRowHandler,
    draggable
  },
  data() {
    return {
      folders: [],
      dataLoaded: false,
      loading: false,
      error: false
    }
  },
  computed: {
    headers() {
      return [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'drag',
          width: 30
        },
        {
          text: this.t('table.name'),
          align: 'start',
          sortable: false,
          value: 'name'
        },
        {
          text: this.t('table.status'),
          align: 'start',
          sortable: false,
          value: 'status',
          width: 150
        },
        {
          text: this.t('table.edit'),
          value: 'edit',
          sortable: false,
          align: 'center',
          width: 75
        }
      ]
    }
  },
  created() {
    this.getFolders()
  },
  methods: {
    t: function (key) {
      return this.$t('learning-portal.folders.list.' + key)
    },
    getFolders() {
      this.loading = true
      HTTP.get('learning_portal_folder').then(function (response) {
        this.folders = response.data
        this.dataLoaded = true
        this.loading = false
        this.error = false
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
        }
      }.bind(this))
    },
    onCloneCallback(item) {
      const cloneMe = JSON.parse(JSON.stringify(item))
      return cloneMe
    },
    onMoveCallback(evt) {
        const item = evt.draggedContext.element
        const itemIdx = evt.draggedContext.futureIndex
        if (item.locked) {
          return false
        }
        return true
      },
      onDropCallback(items) {
        HTTP.post('learning_portal_folder_sort', items).then(function () {
          this.$root.infoNotification.showMessage(this.$t('learning-portal.folders.list.sorting-sucessful'))
        }.bind(this))
    }
  }
}
</script>

<style scoped lang="scss">
.table-loader {
  padding: 0 !important;
  height: auto !important;
}
</style>
