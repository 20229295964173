import TimestampsIndex from '@/views/TimestampsIndex'
import TimeIndex from '@/views/TimeIndex'
import TimeSheetIndex from '@/views/TimeSheetIndex'
import TimeSheetAllIndex from '@/views/TimeSheetAllIndex'
import TimeSheetMonthIndex from '@/views/TimeSheetMonthIndex'
import TimeLockIndex from '@/views/TimeLockIndex'
import TimelogSettings from '@/views/TimelogSettings'
import TimelogSettingsTargetHours from '@/views/TimelogSettingsTargetHours.vue'
import TimelogSettingsTimeManagement from '@/views/TimeManagementBalanceIndex.vue'
import TimeManagementBalanceEdit from '@/views/TimeManagementBalanceEdit.vue'
import TimeProofReportIndex from '@/views/TimeProofReportIndex.vue'
import TimelogExpenseReportIndex from '@/views/TimelogExpenseReportIndex.vue'
import TimeEdit from '@/views/TimeEdit.vue'
import TimeCreate from '@/views/TimeCreate.vue'
import TimelogSettingsSites from "@/views/TimelogSettingsSitesIndex.vue";
import TimelogSettingsSitesCreate from "@/views/TimelogSettingsSitesCreate.vue";
import TimelogSettingsSitesEdit from "@/views/TimelogSettingsSitesEdit.vue";

const timelogRoutes =  [
    {
        path: '/timestamps',
        name: 'Timestamps',
        component: TimestampsIndex,
        meta: {
            accessConfig:  {
                moduleName: ['timelog_qr'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/time',
        name: 'Time',
        component: TimeIndex,
        meta: {
            accessConfig:  {
                moduleName: ['timelog_basic'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/time/create',
        name: 'TimeCreate',
        component: TimeCreate,
        meta: {
            accessConfig:  {
                moduleName: ['timelog_basic'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/time/:id',
        name: 'TimeEdit',
        component: TimeEdit,
        meta: {
            accessConfig:  {
                moduleName: ['timelog_basic'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/time-sheets',
        name: 'Time-Sheets',
        component: TimeSheetIndex,
        meta: {
            accessConfig:  {
                moduleName: ['timelog_report'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/time-sheets-all',
        name: 'Time-Sheets-All',
        component: TimeSheetAllIndex,
        meta: {
            accessConfig:  {
                moduleName: ['timelog_report'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/time-sheets-month',
        name: 'Time-Sheets-Month',
        component: TimeSheetMonthIndex,
        meta: {
            accessConfig:  {
                moduleName: ['timelog_report'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/time-lock',
        name: 'TimeLockIndex',
        component: TimeLockIndex,
        meta: {
            accessConfig:  {
                moduleName: ['timelog_basic'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/timelog',
        name: 'SettingsTimelog',
        component: TimelogSettings,
        meta: {
            accessConfig:  {
                moduleName: ['timelog_basic'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/time-management/sites/create',
        name: 'TimelogSitesCreate',
        component: TimelogSettingsSitesCreate,
        meta: {
            accessConfig:  {
                moduleName: ['timelog_targethours'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/time-management/sites/:id',
        name: 'TimelogSitesEdit',
        component: TimelogSettingsSitesEdit,
        meta: {
            accessConfig:  {
                moduleName: ['timelog_targethours'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/time-management/sites/',
        name: 'TimelogSitesIndex',
        component: TimelogSettingsSites,
        meta: {
            accessConfig:  {
                moduleName: ['timelog_targethours'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/time-management/sites/:id/target-hours',
        name: 'TimelogSitesTargetHours',
        component: TimelogSettingsTargetHours,
        meta: {
            accessConfig:  {
                moduleName: ['timelog_targethours'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/time-management/balances',
        name: 'SettingsTimelogTimeManagement',
        component: TimelogSettingsTimeManagement,
        meta: {
            accessConfig:  {
                moduleName: ['timelog_targethours'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/time-management/balances/:userId',
        name: 'TimeManagementBalanceEdit',
        component: TimeManagementBalanceEdit,
        meta: {
            accessConfig:  {
                moduleName: ['timelog_targethours'],
                requiresAuth: true
            }
        }
    },

    {
        path: '/timelog-proof-report',
        name: 'Timelog Proof Report',
        component: TimeProofReportIndex,
        meta: {
            accessConfig:  {
                moduleName: ['timelog_proof_report'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/timelog-expense-report',
        name: 'Timelog Expense Report',
        component: TimelogExpenseReportIndex,
      
    },

    


]

export default timelogRoutes
