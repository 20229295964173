import SettingsGlobal from '@/views/SettingsGlobal'
import SettingsClient from '@/views/SettingsClient'
import SettingsTimeTracking from '@/views/SettingsTimeTracking'
import SettingsWebview from '@/views/SettingsWebview'
import SettingsUploadImport from '@/views/SettingsUploadImport'
import SettingsPermissionGroups from '@/views/SettingsPermissionGroups'
import SettingsPermissionGroupsCreate from '@/views/SettingsPermissionGroupsCreate'
import SettingsPermissionGroupsEdit from '@/views/SettingsPermissionGroupsEdit'

const settingsRoutes = [
    {
        path: '/settings/global',
        name: 'SettingsGlobal',
        component: SettingsGlobal,
        meta: {
            accessConfig:  {
                moduleName: null,
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/client',
        name: 'SettingsClient',
        component: SettingsClient,
        meta: {
            accessConfig:  {
                moduleName: null,
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/timetracking',
        name: 'SettingsTimeTracking',
        component: SettingsTimeTracking,
        meta: {
            accessConfig:  {
                moduleName: null,
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/webview',
        name: 'SettingsWebview',
        component: SettingsWebview,
        meta: {
            accessConfig:  {
                moduleName: ['timelog_wv', 'surveys_wv'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/upload/import',
        name: 'SettingsUploadImport',
        component: SettingsUploadImport,
        meta: {
            accessConfig:  {
                moduleName: null,
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/permission-groups',
        name: 'SettingsPermissionGroups',
        component: SettingsPermissionGroups,
        meta: {
            accessConfig:  {
                moduleName: null,
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/permission-groups/create',
        name: 'SettingsPermissionGroupsCreate',
        component: SettingsPermissionGroupsCreate,
        meta: {
            accessConfig:  {
                moduleName: null,
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/permission-groups/:id?',
        name: 'SettingsPermissionGroupsEdit',
        component: SettingsPermissionGroupsEdit,
        meta: {
            accessConfig:  {
                moduleName: null,
                requiresAuth: true
            }
        }
    }
]

export default settingsRoutes
