<template>
  <div>
    <toolbar :dataLoaded="true">{{ $t('module-config.title') }}</toolbar>

    <loading :dataLoaded="dataLoaded"></loading>
    
    <v-container fluid v-if="dataLoaded" class="mt-8 edit-screen pt-0">
      <v-row>
        <v-col
          v-for="(section, index) in ['communication', 'organisation', 'time', 'theme']"
          :key="index"
          :cols="12" :md="3"
          class="mb-4"
        >
          <v-card elevation="0">
            <v-card-title>{{ $t(`module-config.${section}`) }}</v-card-title> 
            <v-card-text>
              <div v-if="section !== 'theme'">
                <v-checkbox
                  v-for="(value) in data.filter(item => item.type === section)"
                  :key="value.id"
                  :label="getLabel(value)"
                  v-model="value.active"
                  class="modul-checkbox pt-0"
                  hide-details
                ></v-checkbox>
              </div>

              <div v-else> 
                <v-text-field 
                  :label="$t('module-config.theme-label')"
                  v-model="theme" 
                  filled
                  hide-details
                ></v-text-field>

                <v-btn 
                  href="/push-configuration" 
                  elevation="0"
                  color="primary"
                  outlined
                  class="mt-4 icon-left"
                >
                  <v-icon>mdi-bell-ring-outline</v-icon>
                  {{ $t('module-config.push-configuration') }}
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <div class="bottom-navigation modal-nav">
      <v-container fluid>
        <v-row>
          <v-spacer></v-spacer>
          <v-btn
            @click="save"
            color="primary"
            elevation="0"
            class="sp-float-right icon-left"
          >
            {{ $t('module-config.save') }}
          </v-btn>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/auth'
import { EventBus } from "@/event-bus"
import store from 'store'
import toolbar from '@/components/layouts/Navigation'
import Loading from '@/components/layouts/Loading'

export default {
  name: "ModulesConfiguration",
  components: {
    Loading,
    toolbar
  },
  data() {
    return {
      dataLoaded: false,
      locale: localStorage.getItem("currentLocale"),
      data: {},
      theme: '',
    }
  },
  async mounted() {
    await this.loadData()
  },
  methods: {
    async loadData() {
      this.dataLoaded = false;
      let response = await HTTP.get('client-config?v2');
      this.data = response.data.modules; 
      this.theme = response.data.theme;
      this.dataLoaded = true;
    },
    async save() { 
      this.dataLoaded = false;
      await HTTP.post('client-config', {
        modules: this.data,
        theme: this.theme,
      }).finally(function () {
        this.dataLoaded = true;
      }.bind(this));

      store.set('modules', this.data);

      await this.loadData();

      this.$root.infoNotification.showMessage('Module configuration saved.');

      EventBus.$emit('module-config-changed');
    },
    getLabel(module) {
      return module["title_" + this.locale];
    },
  }
}
</script>

<style scoped>
  .modul-checkbox:first-child{
    margin-top: 0; 
  }
</style>
