<template>
  <div>
    <v-card>
      <v-card-title>
        {{ t('title') }}
      </v-card-title>

      <v-card-text class="d-flex">
        <v-form ref="form">
          <v-container>
            <v-row>
              <span>{{ t('form.value-date') }}</span>
              <v-date-picker v-model="data.date" class="mb-8"/>
            </v-row>
            <v-row>
              <number-input
                :label="t('form.amount') + '*'"
                v-model="data.amount"
                :decimals="2"
                :suffix="reimbursement_currency"
                :rules="rules"
              >
              </number-input>
            </v-row>
            <v-row>
              <v-text-field filled :label="t('form.comment')" v-model="data.comment"/>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancel()" text>
          <v-icon>mdi-close</v-icon>
          {{ t('cancel') }}
        </v-btn>
        <v-btn @click="save()" color="primary" elevation="0">
          <v-icon>mdi-check</v-icon>
          {{ t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import NumberInput from '@/components/inputs/NumberInput'

export default {
  name: "ExpenseEditReimbursement",
  components: {
    NumberInput
  },
  props: ["initialAmount", "reimbursementCurrency"],
  data() {
    return {
      data: {
        title: this.$t('expenses.reimbursement.title'),
        date: new Date().toISOString().substring(0, 10),
        amount: '',
        comment: ''
      },
      rules: [],
      reimbursement_currency: ''
    }
  },
  mounted() {
    this.data.amount = this.initialAmount
    this.reimbursement_currency = this.reimbursementCurrency
  },
  methods: {
    t: function (key) {
      return this.$t('expenses.reimbursement.' + key)
    },
    cancel() {
      this.$emit('cancel')
    },
    save() {
      this.rules.push(() => !isNaN(parseFloat(this.data.amount)) || this.t('validation.required-amount'))
      if (this.$refs.form.validate()) {
        this.$emit('save', this.data)
      }
    }
  }
}
</script>
