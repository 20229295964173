<template>
  <v-card
    elevation="0"
  >
    <v-card-title>{{ t("details-group_title") }}</v-card-title>
    <v-card-text>

      <v-text-field
        :label="t('details-name')"
        v-model="dataInternal.name"
        data-cy="name"
        counter="120"
        filled
        :rules="[
								() => !!dataInternal.name || $t('validation.required'),
								() => (dataInternal.name && dataInternal.name.length <= 120) || $t('validation.length')
							]"
      ></v-text-field>

    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: 'TimelogSitesContent',
  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({ name: "" })
    }
  },
  data() {
    return {
      dataInternal: { ...this.value }
    };
  },
  watch: {
    value: {
      handler(newValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(this.dataInternal)) {
          this.dataInternal = { ...newValue };
        }
      },
      deep: true
    },
    dataInternal: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true
    }
  },
  methods: {
    t: function(key) {
      return this.$t('timelog-sites-content.' + key);
    },
  }
}

</script>

<style scoped lang="scss">

</style>