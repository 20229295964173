<template>
    <md-list-item>
        <v-icon>{{ item.icon }}</v-icon>
        <span class="module-title">{{ item.title }}</span>
        <md-list-expand>
            <md-list>
                <md-list-item class="md-inset">
                    <router-link :to="{ path: '/learning-portal' }">
                    <span>{{ $t('navigation.learning-portal') }}</span>
                    </router-link>
                </md-list-item>
                <md-list-item class="md-inset">
                    <router-link :to="{ path: '/learning-portal-evaluations' }">
                    <span>{{ $t('navigation.learning-portal-evaluations') }}</span>
                    </router-link>
                </md-list-item>
                <md-list-item class="md-inset">
                    <router-link :to="{ path: '/learning-portal-folders' }">
                    <span>{{ $t('navigation.learning-portal-folders') }}</span>
                    </router-link>
                </md-list-item>
            </md-list>
        </md-list-expand>
    </md-list-item>
</template>

<script>
export default {
    name: 'LearningPortalListItem',
    data() {
        return {}
    },
    props: {
        item: {
            type: Object,
            required: false,
            default: {}
        },
        user: {
            type: Object,
            required: false,
            default: {}
        },
        modules: {
            type: Array,
            required: false,
            default: []
        }
    }
    
}
</script>