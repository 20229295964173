<template>
  <div class="has-hero-container">
    <div>

      <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

      <div class="sp-hero sp-bg-light-blue">

        <v-breadcrumbs divider="" :items="breadcrumbs">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              class="mt-0"
              @click="openFolder(item)"
            >
              <v-btn text outlined>{{ item.title }}</v-btn>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </div>

      <v-container fluid>

        <v-row class="data-table-toolbar">
          <v-spacer></v-spacer>
          <div>
            <v-btn
              elevation="0"
              color="primary"
              class="icon-left"
              @click="createFolder()"
            >
              <v-icon>mdi-folder-plus</v-icon>
              {{ t('create-folder') }}
            </v-btn>
          </div>

          <div>
            <v-btn
              elevation="0"
              color="primary"
              class="icon-left"
              @click="createGallery()"
            >
              <v-icon>collections</v-icon>
              {{ t('create-gallery') }}
            </v-btn>
          </div>
        </v-row>

        <v-row v-if="dataLoaded && items.length === 0">
          <div class="empty-datatable">
            <v-icon>mdi-alert-circle-outline</v-icon>
            {{ t('no-data') }}
          </div>
        </v-row>

        <v-row class="mb-2">
          <v-col cols="10"><strong>{{ t('name') }}</strong></v-col>
          <v-col cols="1" align="center"><strong>{{ t('amount-images') }}</strong></v-col>
          <v-col cols="1" align="center"><strong>{{ t('edit') }}</strong></v-col>
        </v-row>

        <v-divider></v-divider>

        <draggable v-model="items" @end="onDragged">
          <v-row v-for="(item, index) in items" class="mt-2" style="cursor: move;" align="center">
            <v-col cols="10">
              <div @dblclick="open(item)">
                <v-icon v-if="item.type == 'Folder'" small class="edit-row-button">folder</v-icon>
                <v-icon v-if="item.type == 'Gallery'" small class="edit-row-button">collections</v-icon>
                {{ item.title }}
              </div>
            </v-col>
            <v-col cols="1" align="center">
              <div>
                {{ item.photoCount }}
              </div>
            </v-col>
            <v-col cols="1" align="center">
              <div>
                <v-btn text @click="edit(item)">
                  <v-icon small class="edit-row-button">edit</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </draggable>

      </v-container>

    </div>
  </div>
</template>

<script>
import { HTTP } from '@/auth'
import toolbar from '@/components/layouts/Navigation'
import loading from '@/components/layouts/Loading'
import draggable from 'vuedraggable'
import router from '@/router'

export default {
  name: 'GalleryIndex',
  components: {
    toolbar,
    loading,
    draggable,
  },
  data() {
    return {
      folder: null,
      items: [],
      breadcrumbs: [],
      dataLoaded: false
    }
  },
  async created() {
    if (this.$route.params.folderId) {
      this.folder = this.$route.params.folderId
    } else if (this.$route.query.folderId) {
      this.folder = this.$route.query.folderId
    }
    await this.loadData()
  },
  methods: {
    t: function (key) {
      return this.$t('galleries.list.' + key);
    },
    async loadData() {
      let idText = ''
      if (this.folder) {
        idText = '/' + this.folder
      }
      await HTTP.get('galleries' + idText).then(function (response) {
        this.items = response.data.items
        this.breadcrumbs = response.data.breadcrumbs
      }.bind(this)).catch(function (error) {
        console.log(error)
      }.bind(this))
    },
    async createGallery() {
      let folderString = this.folder ?? ''
      await this.$router.push('galleries/create-gallery/' + folderString)
    },
    async createFolder() {
      let folderString = this.folder ?? ''
      await this.$router.push('galleries/create-folder/' + folderString)
    },
    async open(item) {
      if (item.type === 'Folder') {
        await this.openFolder(item)
      }
    },
    async openFolder(item) {
      this.folder = item.id
      await router.push({query: {folderId: this.folder}})
      await this.loadData()
    },
    async edit(item) {
      switch (item.type) {
        case 'Gallery':
          await this.$router.push('galleries/gallery-edit/' + item.id)
          break;
        case 'Folder':
          await this.$router.push('galleries/folder-edit/' + item.id)
          break;
        default:
          console.log('Invalid item type: ' + item.type)
          break;
      }
    },
    async onDragged() {
      let newSort = this.items.map(function (z) {
        return z.id
      })

      let idText = ''
      if (this.folder) {
        idText = this.folder
      }
      await HTTP.post('galleries/set-order/' + idText, {sort: newSort})
    }
  }
}
</script>
