<template>
    <div>
        <BasicCreateEditContainer
            :title="t('title')"
            ref="editor"
            :object-type="$t('qc-notifications.list.title')"
            :object-name="data.customer ? data.customer.name : ''"
            :data-loaded="dataLoaded"
            @cancel="close"
            :save-action="saveQCNotification"
            @saved="onSaved"
            :allow-delete="mode == 'edit'"
            :delete-action="deleteQCNotification"
            :deletion-hint-text="t('deletion-hint') "
            @deleted="onDeleted"
            :has-changes="hasChanges"
        >
            <template v-slot:content>

                <v-card elevation="0">
                    <v-card-title>{{ t('details-title') }}</v-card-title>
                    <v-card-text>
                        <content-customer
                            :label="t('choose_client')"
                            v-model="data.customer_id"
                            data-cy="customer_id"
                            :get-search-url="getCustomerSearchUrl"
                            :rules="[
                                () => !!data.customer_id || $t('validation.required.client')
                            ]"
                        ></content-customer>

                        <v-text-field
                            class="mt-2 mb-0 pb-0"
                            :label="t('contact_person')"
                            v-model="data.contact_person"
                            data-cy="contact_person"
                            hide-details="auto"
                            filled
                        ></v-text-field>

                        <contact-type-filter 
                            class="mt-4"
                            v-model="data.contact_type_id"
                            data-cy="contact_type_id"
                        ></contact-type-filter>
                        
                        <user-filter-widget
                            v-if="dataLoaded"
                            :preselected="data.project_leader_id"
                            class="mt-4"
                            v-model="data.project_leader_id"
                            data-cy="customproject_leader_ider_id"
                            :label="$t('components.content-filter-object-leader.label') + '*'"
                            :rules="[
                                () => !!data.project_leader_id || $t('validation.required.project_leader')
                            ]"
                        ></user-filter-widget>
                        
                        <q-c-notification-room-filter class="mt-2" v-model="data.room_id" data-cy="room_id"/>

                        <q-c-subject-area-filter class="mt-4" v-model="data.subject_area_id" data-cy="subject_area_id"/>
                        
                        <v-textarea
                            :label="t('notes')"
                            class="no-validation mt-4"
                            v-model="data.notes"
                            data-cy="notes"
                            filled
                            hide-details="auto"
                        ></v-textarea>
                    </v-card-text>
                </v-card>

                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('picture-title') }}</v-card-title>
                    <v-card-text>
                        <div v-if="mode === 'edit'">
                            <img :src="data.thumbnail_url">
                        </div>
                        <div v-else>
                            <picture-upload
                                :show-preview="true"
                                v-model="data.picture"
                                aspectMode="quadratic"/>
                        </div>
                    </v-card-text>
                </v-card>

                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('cause-title') }}</v-card-title>
                    <v-card-text>
                        <user-filter-widget
                            v-if="dataLoaded"
                            :preselected="data.cause_user_id"
                            v-model="data.cause_user_id"
                            data-cy="cause_user_id"
                            :label="t('who')"
                        ></user-filter-widget>

                        <v-textarea
                            :label="t('cause_notes')"
                            class="no-validation mt-2"
                            v-model="data.cause_notes"
                            data-cy="cause_notes"
                            filled
                            hide-details="auto"
                        ></v-textarea>
                    </v-card-text>
                </v-card>

                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('add-task.group-title') }}</v-card-title>
                    <v-card-text>
                        <div v-show="data.tasks.length < 4" class="text-right">
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        elevation="0"
                                        color="primary"
                                        outlined
                                        class="icon-left"
                                        v-bind="attrs"
                                        v-on="on"
                                        :disabled="taskEditDisabled"
                                    >
                                        <v-icon>mdi-plus</v-icon>
                                        {{ t('add-task.add-button') }}
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item
                                        v-for="(task, index) in taskTypes"
                                        :key="index"
                                        class="task-list-item"
                                        :disabled="isSelected(task.type)"
                                        @click="addTask(index, task.type)"
                                    >
                                        <v-list-item-title>
                                            <v-icon>{{ task.icon }}</v-icon>
                                            {{ task.name }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>

                        <div v-if="data.tasks.length > 0" class="tasks-wrapper sp-mar-top-2">
                            <div v-for="(task, index) in data.tasks" :index="index">

                                <v-card elevation="0">
                                    <v-card-title>
                                        {{ t(task.type + '-title') }}
                                        <span
                                            v-if="!taskEditDisabled"
                                            @click="removeTask(index)"
                                            class="remove-task"
                                            :title="$t('components.blocks-editor.remove')"
                                        >
                                            <v-icon>mdi-delete-outline</v-icon>
                                        </span>
                                        <span
                                            v-else
                                            @click="goToTask(task.id)"
                                            class="goto-task"
                                            :title="t('goto-task')"
                                        >
                                            <v-icon>mdi-eye-outline</v-icon>
                                        </span>
                                    </v-card-title>
                                    <v-card-text>
                                        <div v-if="taskEditDisabled" class="task-element-overlay"></div>
                                        <v-row>
                                            <v-col class="pr-2">
                                                <user-filter-widget
                                                    :preselected="task.user_id"
                                                    v-model="task.user_id"
                                                    :label="t('who') + '*'"
                                                    :rules="[v => !!v || $t('validation.required.user')]"
                                                ></user-filter-widget>
                                            </v-col>

                                            <v-col class="pl-2">
                                                <app-date-time-picker
                                                    :label="t('due-date') + '*'"
                                                    v-model="task.date"
                                                    date-format="dd.MM.yyyy"
                                                    :id="'dueDate'"
                                                    :required="true"
                                                    :clearable="false"
                                                    :rules="[v => !!v || $t('validation.required.due-date')]"
                                                ></app-date-time-picker>
                                            </v-col>
                                        </v-row>

                                        <v-textarea
                                            :label="t('qm_note') + '*'"
                                            class="mt-0"
                                            v-model="task.notes"
                                            filled
                                            hide-details="auto"
                                            :rules="[v => !!v || $t('validation.required.comment')]"
                                        ></v-textarea>
                                    </v-card-text>
                                </v-card>

                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </template>

            <template v-slot:meta>
                <v-card elevation="0">
                    <v-card-title>{{ t('notification-type') }}</v-card-title>
                    <v-card-text>
                        <div>
                            <q-c-notification-type-filter
                                :filterLabel="t('notification-type-filter')"
                                v-model="data.type_id"
                                data-cy="type_id"
                                id="dateStart"
                                :clearable="true"
                            ></q-c-notification-type-filter>
                        </div>
                    </v-card-text>
                </v-card>

                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('notification-rating') }}</v-card-title>
                    <v-card-text>
                        <div>
                            <q-c-notification-rating-filter
                                :filterLabel="t('notification-rating-filter')"
                                v-model="data.rating_id"
                                data-cy="rating_id"
                                id="dateStart"
                                :clearable="true"
                            ></q-c-notification-rating-filter>
                        </div>
                    </v-card-text>
                </v-card>

                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('status') }}</v-card-title>
                    <v-card-text>
                        <div>
                            <q-c-notifications-status-filter
                                v-model="data.status"
                                :filter-label="t('status-filter') + '*'"
                                data-cy="status"
                                :mode="mode"
                                :status="dataOriginal ? dataOriginal.status : 'submitted'"
                                :rules="[
                                    () => !!data.status || $t('validation.required.status')
                                ]"
                            ></q-c-notifications-status-filter>
                        </div>
                    </v-card-text>
                </v-card>
            </template>
        </BasicCreateEditContainer>
    </div>
</template>

<script>
import { HTTP } from '@/auth'
import BasicCreateEditContainer from '@/components/inputs/BasicCreateEditContainer'
import PictureUpload from '@/components/inputs/PictureUpload'
import AppDateTimePicker from '@/components/vuetify/AppDateTimePicker'
import ContentCustomer from '@/components/filter-components/ContentCustomer'
import QCNotificationsStatusFilter from '@/components/filter-components/QCNotificationsStatusFilter'
import QCNotificationRatingFilter from '@/components/filter-components/QCNotificationRatingFilter'
import QCNotificationTypeFilter from '@/components/filter-components/QCNotificationTypeFilter'
import QCSubjectAreaFilter from '@/components/filter-components/QCSubjectAreaFilter'
import ContactTypeFilter from '@/components/filter-components/ContactTypeFilter'
import QCNotificationRoomFilter from '@/components/filter-components/QCNotificationRoomFilter'
import UserFilterWidget from '@/components/filter-components/UserFilterWidget'

export default {
    name: 'QCNotificationsCreateEdit',
    components: {
        PictureUpload,
        BasicCreateEditContainer,
        AppDateTimePicker,
        ContentCustomer,
        UserFilterWidget,
        QCNotificationsStatusFilter,
        QCNotificationRatingFilter,
        QCNotificationTypeFilter,
        QCSubjectAreaFilter,
        QCNotificationRoomFilter,
        ContactTypeFilter
    },
    created() {
        this.data.id = this.$route.params.id
        if (this.data.id) {
            this.getQCNotification()
            this.mode = 'edit'
        } else {
            this.mode = 'create'
            this.dataOriginal = JSON.parse(JSON.stringify(this.data))
            this.dataLoaded = true
        }
    },
    data() {
        return {
            dataLoaded: false,
            dataOriginal: null,
            data: {
                id: null,
                dataLoaded: false,
                customer_id: null,
                contact_person: '',
                contact_type_id: null,
                project_leader_id: null,
                room_id: '',
                subject_area_id: null,
                notes: '',
                type_id: null,
                rating_id: null,
                status: null,
                picture: '',
                cause_notes: '',
                cause_user_id: null,
                tasks: []
            },
            taskTypes: [
                {
                    type: 'immediate_measures',  
                    name: this.t('add-task.types.immediate_measures'),
                    icon: 'mdi-clock-alert-outline',
                    selected: false
                },
                {
                    type: 'preventive_measures',
                    name: this.t('add-task.types.preventive_measures'),
                    icon: 'mdi-eye-check-outline',
                    selected: false
                },
                {
                    type: 'phone_feedback',  
                    name: this.t('add-task.types.phone_feedback'),
                    icon: 'mdi-phone-forward-outline',
                    selected: false
                },
                {
                    type: 'aek',
                    name: this.t('add-task.types.aek'),
                    icon: 'mdi-clipboard-check-multiple-outline',
                    selected: false
                }
            ]
        }
    },
    computed: {
        hasChanges() {
            return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal)
        },
        taskEditDisabled() {
            return this.dataOriginal && this.dataOriginal.status && this.dataOriginal.status !== 'submitted'
        }
    },
    methods: {
        t: function (key) {
            return this.$t('qc-notifications.create.' + key)
        },
        getQCNotification() {
            HTTP.get('qc_notifications/' + this.data.id).then(function (response) {
                let refactor = response.data
                if (refactor.customer != null) refactor.customer_id = refactor.customer
                if (refactor.project_leader != null) refactor.project_leader_id = refactor.project_leader
                if (refactor.cause_user != null) refactor.cause_user_id = refactor.cause_user
                this.data = response.data

                this.dataOriginal = JSON.parse(JSON.stringify(this.data))
                this.dataLoaded = true
            }.bind(this)).catch(function (error) {
                console.log(error)
                this.dataLoaded = true
            }.bind(this))
        },      
        saveQCNotification() {  
            let data = JSON.parse(JSON.stringify(this.data))  
            if (data.customer_id != null) data.customer_id = data.customer_id.id
            if (data.project_leader_id != null) data.project_leader_id = data.project_leader_id.id
            if (data.cause_user_id != null) data.cause_user_id = data.cause_user_id.id

            if (data.tasks.length > 0) {
                data.tasks.forEach((task) => {
                    if (task.user_id != null) {
                        task.user_id = task.user_id.id
                    }
                })
            }

            if (this.mode === 'create') {
                return HTTP.post('/qc_notifications', data)
            } else {
                return HTTP.put('/qc_notifications/' + data.id, data)
            }
        },
        addTask(index, type) {
            this.data.tasks.push({
                'id': null,
                'type': type,
                'title': this.t(type + '-title'),
                'user_id': null,
                'date': null,
                'notes': null
            })
            this.taskTypes[index].selected = true
        },
        removeTask(index) {
            this.data.tasks.splice(index, 1)
        },
        isSelected(type) {
            return this.data.tasks.filter((t) => {
                return t.type === type
            }).length > 0
        },
        onSaved() {
            this.dataOriginal = null
            this.close()
        },
        deleteQCNotification() {
            return HTTP.delete('qc_notifications/' + this.data.id)
        },
        onDeleted() {
            this.dataOriginal = null
            this.close()
        },
        close() {
            this.cancel()
        },
        cancel() {
            this.$router.push({ name: 'QCNotificationsIndex' })
        },
        getCustomerSearchUrl(val) {
            return 'tasks/customers?q=' + val
        },
        goToTask(taskId) {
            this.$router.push({ name: 'Pendenz', params: { id: taskId }})
        }
    }
}
</script>

<style scoped lang="scss">
.task-list-item {
    padding: 0 8px;

    .v-icon {
        position: relative;
        top: -2px;
        margin-right: 4px;
    }
}

.remove-task,
.goto-task  {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    width: 56px;
    height: 56px;
    padding: 16px;
    border-left: 1px solid #E0E0E0;
    transition: background-color 0.3s;
  
    &:hover {
      background: #FFFFFF;
    }
}

.tasks-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .task-element-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.5);
        z-index: 1;
    }
}
</style>