<template>
    <div>
      <BasicCreateEditContainer
          :title="mode == 'create' ? t('create.title') : t('edit.title')"
          ref="editor"
          :object-type="t('card-title')"
          :object-name="folder.name"
          :data-loaded="dataLoaded"
          @cancel="close"
          :save-action="saveFolder"
          @saved="onSaved"
          :allow-delete="mode == 'edit'"
          :delete-action="deleteFolder"
          @deleted="onDeleted"
          :has-changes="hasChanges"
      >
        <template v-slot:content>
          <v-card elevation="0">
            <v-card-title>{{ t('card-title') }}</v-card-title>
            <v-card-text>
  
              <v-text-field
                  :label="t('placeholder')"
                  v-model="folder.name"
                  counter="40"
                  filled
                  :rules="[
                    () => !!folder.name || t('validation.error-name-required'),
                    () => (folder.name && folder.name.length <= 40) || t('validation.error-max-name-40')
                  ]"
              ></v-text-field>
  
            </v-card-text>
          </v-card> 
        </template>

        <template v-slot:meta>
          <v-card elevation="0">
            <v-card-title>{{ t('active.card-title') }}</v-card-title>
            <v-card-text>
  
                <v-switch
                    v-model="folder.is_active"
                    :label="folder.is_active ? t('active.yes') : t('active.no')"
                    color="primary"
                    hide-details
                ></v-switch>
  
            </v-card-text>
          </v-card>

          <v-card elevation="0" class="sp-mar-top-3">
            <v-card-title>{{ $t('components.permissiongroup-select.label') + '*' }}</v-card-title>
            <v-card-text>
              <permission-group-selector
                v-model="folder.assigned_permission_groups"
                :required="true"
                mode="dropdown"
              ></permission-group-selector>
            </v-card-text>
          </v-card>
        </template>
  
      </BasicCreateEditContainer>
    </div>
  </template>
  
  <script>
  import { HTTP } from '@/auth'
  import BasicCreateEditContainer from '@/components/inputs/BasicCreateEditContainer'
  import PermissionGroupSelector from '@/components/PermissionGroupSelector'

  export default {
    name: 'LearningPortalFoldersCreateEdit',
    components: {
      BasicCreateEditContainer,
      PermissionGroupSelector
    },
    data () {
      return {
        folder: {
          id: null,
          name: '',
          is_active: true,
          assigned_permission_groups: []
        },
        dataOriginal: null,
        dataLoaded: false,
        mode: null,
        error: null
      }
    },
    created() {
      this.folder.id = this.$route.params.id
      if (this.folder.id) {
        this.getFolder()
        this.mode = 'edit'
      } else {
        this.mode = 'create'
        this.dataOriginal = JSON.parse(JSON.stringify(this.folder))
        this.dataLoaded = true
      }
    },
    computed: {
      hasChanges() {
        return JSON.stringify(this.folder) !== JSON.stringify(this.dataOriginal)
      },
    },
    methods: {
      t: function (key) {
        return this.$t('learning-portal.folders.' + key)
      },
      getFolder() {
        HTTP.get('learning_portal_folder/' + this.folder.id).then(function (response) {
          this.folder = response.data
          const tempPermGroupsIds = []
          response.data.assigned_permission_groups.forEach((element) => {
              tempPermGroupsIds.push(element.permission_group_id)
          })

          this.folder.assigned_permission_groups = tempPermGroupsIds
          this.dataOriginal = JSON.parse(JSON.stringify(this.folder))
          this.dataLoaded = true
        }.bind(this)).catch(function (error) {
          if (error.response.status && error.response.status === 404) {
              this.$root.infoNotification.showMessage(this.$t('learning-portal.folders.validation.not-found', { folderId: this.folder.id }))
          } else {
            this.$root.infoNotification.showMessage(this.$t('learning-portal.folders.validation.unknown-error') + error.response.statusText)
          }
          this.close()
        }.bind(this))
      },
      saveFolder() {
        if (this.mode == 'create') {
          return HTTP.post('learning_portal_folder', this.folder)
        } else {
          return HTTP.patch('learning_portal_folder/' + this.folder.id, this.folder)
        }
      },
      onSaved() {
        this.dataOriginal = null
        this.close()
      },
      deleteFolder() {
        return HTTP.delete('learning_portal_folder/' + this.folder.id)
      },
      onDeleted() {
        this.dataOriginal = null
        this.close()
      },
      close() {
        this.$router.push({ name: 'LearningPortalFoldersIndex' })
      }
    }
  }
  </script>