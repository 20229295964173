import Vue from 'vue'
import VueRouter from 'vue-router'
import store from 'store'

import AppLogout from '@/views/AppLogout'
import LoginCallback from '@/views/LoginCallback'
import Dashboard from '@/views/Dashboard'
import PasswordReset from '@/views/PasswordReset'
import PasswordResetToken from '@/views/PasswordResetToken'

import routesSurveys from './routes/surveys'
import absencesRoutes from './routes/absences'
import jobsRoutes from './routes/jobs'
import newsRoutes from './routes/news'
import documentsRoutes from './routes/documents'
import privacyPolicyRoutes from './routes/privacy-policy'
import enrollmentsRoutes from './routes/enrollments'
import tasksRoutes from './routes/tasks'
import emergencyContactsRoutes from './routes/emergency-contacts'
import customersRoutes from './routes/customers'
import customerNotesRoutes from './routes/customer-notes'
import qualityReportRoutes from './routes/quality-report'
import timelogRoutes from './routes/timelog'
import usersRoutes from './routes/users'
import settingsRoutes from './routes/settings'
import groupsRoutes from './routes/groups'
import expensesRoutes from './routes/expense'
import timesheetApprovalRoutes from './routes/timesheet-approval'
import ModulesConfiguration from '@/views/ModulesConfiguration'
import chatRoutes from './routes/chat'
import ideaboxRoutes from './routes/ideabox'
import instructionsRoutes from './routes/instructions'
import servapptRoutes from './routes/servappt'
import workReportRoutes from '@/router/routes/work-report'
import ProfileIndex from '@/views/ProfileIndex'
import galleriesRoutes from './routes/galleries'
import QCNotificationsRoutes from './routes/qcnotifications'
import shopRoutes from './routes/shop'
import objectControlRoutes from './routes/object-control'
import learningPortalRoutes from './routes/learning-portal'
import PushConfiguration from '@/views/PushConfiguration'
import menu from './routes/menu'

Vue.use(VueRouter)

const routes = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            accessConfig: {
                moduleName: null,
                requiresAuth: true
            }
        }
    },
    {
        path: '/me',
        name: 'ProfileIndex',
        component: ProfileIndex,
    },
    {
        path: '/logout',
        name: 'Logout',
        component: AppLogout,
        meta: {
            accessConfig: {
                moduleName: null,
                requiresAuth: false
            }
        }
    },
    {
        path: '/oidc-callback',
        name: 'LoginCallback',
        component: LoginCallback,
        meta: {
            accessConfig: {
                moduleName: null,
                requiresAuth: false
            }
        }
    },

    {
        path: '/module-configuration',
        name: 'ModuleConfiguration',
        component: ModulesConfiguration
    },
    {
        path: '/push-configuration',
        name: 'PushConfiguration',
        component: PushConfiguration
    },
    {
        path: '/password-reset',
        name: 'PasswordReset',
        component: PasswordReset
    },
    {
        path: '/password-reset/:token?',
        name: 'PasswordResetToken',
        component: PasswordResetToken
    },
    ...groupsRoutes,
    ...timelogRoutes,
    ...usersRoutes,
    ...qualityReportRoutes,
    ...settingsRoutes,
    ...privacyPolicyRoutes,
    ...documentsRoutes,
    ...galleriesRoutes,
    ...newsRoutes,
    ...jobsRoutes,
    ...absencesRoutes,
    ...routesSurveys,
    ...enrollmentsRoutes,
    ...tasksRoutes,
    ...emergencyContactsRoutes,
    ...customersRoutes,
    ...customerNotesRoutes,
    ...expensesRoutes,
    ...timesheetApprovalRoutes,
    ...chatRoutes,
    ...ideaboxRoutes,
    ...instructionsRoutes,
    ...servapptRoutes,
    ...workReportRoutes,
    ...menu,
    ...QCNotificationsRoutes,
    ...shopRoutes,
    ...objectControlRoutes,
    ...learningPortalRoutes,
    {
        path: '*',
        name: 'NotFound',
        redirect: {
            name: 'Dashboard'
        },
        meta: {
            accessConfig: {
                moduleName: null,
                requiresAuth: false
            }
        }
    }
]

// check if module allowed/active
function accessModule(moduleName) {

    let userModules = store.get('userModules')
    let active = false;
    // Using a for...of loop

    // Check if moduleName is a string or an array
    const isString = typeof moduleName === "string";
    const isArray = Array.isArray(moduleName);
    

    for (let module of userModules) {
        // Check if module_name matches with moduleName (string)
        if (isString && moduleName === module.module_name) {
            active = module.active;
            break; // No need to continue iterating if match found
        }

        // Check if module_name is included in moduleName (array)
        if (isArray && moduleName.includes(module.module_name)) {
            active = module.active;
            break; // No need to continue iterating if match found
        }
    }

    return active;  
}

// check if authenticated
function isAuthenticated(to, from, next) {
    return store.get('user')
}

const router = new VueRouter({
    mode: 'history',
    base: '',
    routes
})

// check route config
router.beforeEach((to, from, next) => {
    let toConfig = to.meta.accessConfig

    // check if config defined
    if (typeof toConfig !== 'undefined') {

        // check auth
        //let isAuth = isAuthenticated(to, from, next);
        //if (!isAuth) {
        //     console.log('nav staring login oidc')
        //     Auth.loginOidc(to.path)
        //     return;
        //}

        // check if module accessible
        if (typeof toConfig.moduleName !== 'undefined' && toConfig.moduleName) {
            let isAccessible = accessModule(toConfig.moduleName);
            if (!isAccessible) {
               next({name: "Dashboard"}) // redirect to safety route if module is not on the list
               return;
            }
        }

        next(); // continue

    } else {
        next(); // continue if no config
    }

})

export default router
