<template>
    <v-select
        class="mb-3"
        v-model="selectedFilterStatus"
        :label="$t('components.qc-notifications-room.status-filter.label')"
        :items="filterStatus"
        item-text="title"
        item-value="id"
        chips
        :data-cy="dataCy"
    ></v-select>
</template>

<script>
import { HTTP } from '@/auth'

export default {
    name : "QCNotificationRoomFilter",
    model: {
        prop: 'model',
        event: 'input'
    },
    props: {
        dataCy: {
            default: ''
        },
        model: {
            type: [Number, Array, String],
            default: () => [null]       
        },
    },
    data() {
        return {
            selectedFilterStatus: null,
            filterStatus: []
        }
    },
    watch: {
        selectedFilterStatus: function (val) {
            this.$emit('input', val)
        },
        model: function(val) {
            this.selectedFilterStatus = val
        }
    },
    created() {
        this.getNotificationRooms()
    },
    methods: {
        async getNotificationRooms() {
            await HTTP.get('notification_rooms').then((response) => {
                this.filterStatus = response.data
            })
        }
    }
}
</script>