<template>
  <div>
    <v-card v-if="dataLoaded">
      <v-card-title>
        {{ t("title") }}
      </v-card-title>

      <v-card-text class="d-flex">
        <v-container>
          <v-row>
            <v-col>
              <v-select
                v-model="data.siteId"
                :label="t('form.site')"
                :items="sites"
                item-text="name"
                item-value="id"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                filled
                :label="t('form.degree')"
                v-model="data.degree"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <span>{{ t("form.valid-from") }}</span>
              <v-date-picker v-model="data.validFrom" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancel()"
               text
        >
          <v-icon>mdi-close</v-icon>
          {{ t("cancel") }}
        </v-btn>
        
        <v-btn @click="save()"
               color="primary"
               elevation="0"
        >
          <v-icon>mdi-check</v-icon>
          {{ t("save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { HTTP } from "@/auth"

export default {
  name: "TimeManagementBalanceEditEmploymentDegreeBooking",
  data() {
    return {
      dataLoaded: false,
      sites: [],
      data: {
        siteId: null,
        degree: 100,
        validFrom: new Date().toISOString().substr(0, 10)
      }
    }
  },
  async created() {
    await this.loadSites()
  },
  methods: {
    t: function(key) {
      return this.$t("time-management.detail.employment-degree-booking." + key)
    },
    async loadSites() {
      let response = await HTTP.get("timelog/sites")
      this.sites = response.data
      this.dataLoaded = true
    },
    cancel() {
      this.$emit("cancel")
    },
    save() {
      this.$emit("save", this.data)
    }
  }
}
</script>
