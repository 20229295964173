<template>
  <v-select
    chips
    v-model="selectedFilterStatus"
    item-text="name"
    item-value="slug"
    :label="labelString"
    :items="computedFilterStatus"
    :multiple="multiple"
    :disabled="disabled"
    :readonly="readonly"
    class="selected-filter-status"
  >
    <template #selection="{ item }">
      <v-chip
        :class="'status-' + item.slug"
      >
        <v-icon>{{ item.icon }}</v-icon>
        {{ item.name }}
      </v-chip>
    </template>
  </v-select>
</template>

<script>
import { getFilterStatus } from '@/components/filter-components/SelectStatus'

export default {
  name: "SelectStatusFilterWidget",
  props: {
    value: {},  
    rules: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    filterList: {
      type: Array,
      required: true,
      default: () => []
    },
  },
  data() {
    return {
      selectedFilterStatus: this.value,
      filterStatus: this.getFilterStatus()
    }
  },
  watch: {
    selectedFilterStatus: function (val) {
      this.$emit('input', val);
    }
  },
  created() {
    this.selectedFilterStatus.forEach((status, index) => { 
      this.selectedFilterStatus[index] = status.toLowerCase()
    })
    this.$emit('input', this.selectedFilterStatus);
  },
  computed: {
    labelString: function () {
      return this.label !== '' ? this.label : this.$t('components.select-status-filter-widget.label')
    },
    computedFilterStatus: function () {
      let filterStatus = []
      if (this.filterStatus.length > 0) {
        this.filterStatus.forEach((status) => {
          if (this.filterList.includes(status.slug)) {
            filterStatus.push(status)
          }  
        })
      }
      return filterStatus 
    },
  },
  methods: {
    getFilterStatus
  }
}
</script>