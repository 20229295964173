<template>
  <div>
    <BasicSettingsContainer
      :title="t('target-hours.title')"
      :data-loaded="dataLoaded"
      :has-changes="hasChanges"
      @reset="reset"
      :save-action="save"
    >
      <v-card elevation="0" class="sp-mar-top-3">
        <v-card-title>
          <v-container>
            <v-row justify="center">
              <v-col cols="9">
                {{ t("target-hours.title") }}
              </v-col>

              <v-col cols="3">
                <v-select
                  filled
                  :label="t('target-hours.year')"
                  :items="years"
                  v-model="filter.year"
                  @change="applyFilter"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="9">
                {{ t("target-hours.site") }}: {{ item.site.name }}
              </v-col>
            </v-row>
          </v-container>


        </v-card-title>
        <v-card-text>
          <v-container v-if="item.entries.length > 0">
            <v-row>
              <v-col><strong>{{ t("target-hours.date") }}</strong></v-col>
              <v-col><strong>{{ t("target-hours.target-hours") }}</strong></v-col>
              <v-col><strong>{{ t("target-hours.day-description") }}</strong></v-col>
            </v-row>
            <v-row v-for="entry in item.entries" class="compact-row">
              <v-col class="date-col">
                <div>
                  {{ formatDate(entry.date)}}
                </div>
              </v-col>
              <v-col class="compact-col">
                <v-text-field dense filled hide-details="auto"
                              v-model="entry.target_hours"
                />
              </v-col>
              <v-col class="compact-col">
                <v-text-field dense filled hide-details="auto"
                              v-model="entry.day_description"
                />
              </v-col>
            </v-row>
          </v-container>

          <v-container v-if="item.entries.length === 0">
            <div>{{ t("target-hours.no-hours-for-this-date") }}</div>
            <br />
            <v-text-field filled v-model="create.target_hours" :label="t('target-hours.label-target-for-date')" />

            <app-filter-date-picker
              :filter-label="t('target-hours.easter-sunday')"
              v-model="create.easter_sunday"
              :clearable="true"
            />
            <v-btn class="sp-mar-top-3" @click="generateTargetHours" color="primary"
                   outlined>{{ t("target-hours.generate-target-hour") }}
            </v-btn>
          </v-container>
        </v-card-text>
      </v-card>

    </BasicSettingsContainer>

  </div>

</template>

<script>
import { HTTP } from "@/auth"
import moment from 'moment'
import toolbar from "@/components/layouts/Navigation"
import loading from "@/components/layouts/Loading"
import AppDialogConfirmSave from "@/components/vuetify/AppDialogConfirmSave"
import BasicSettingsContainer from "@/components/inputs/BasicSettingsContainer"
import AppFilterDatePicker from "@/components/vuetify/AppFilterDatePicker"

export default {
  name: "TimelogSettingsTargetHours",
  components: {
    AppFilterDatePicker,
    BasicSettingsContainer,
    toolbar,
    loading,
    AppDialogConfirmSave
  },
  data() {
    return {
      siteId: null,
      years: [
        new Date().getFullYear() - 2,
        new Date().getFullYear() - 1,
        new Date().getFullYear(),
        new Date().getFullYear() + 1,
        new Date().getFullYear() + 2],
      filter: {
        year: new Date().getFullYear()
      },
      create: {
        target_hours: 8,
        easter_sunday: new Date().getFullYear() + "-04-01"
      },
      item: {
        site: {
          name: null
        },
        entries: []
      },
      itemOriginal: null,
      dataLoaded: false
    }
  },
  async created() {
    this.siteId = this.$route.params.id
    await this.getData()
  },
  computed: {
    hasChanges() {
      if (!this.itemOriginal) {
        return false
      }
      return JSON.stringify(this.item) !== JSON.stringify(this.itemOriginal)
    }
  },
  methods: {
    t: function(key) {
      return this.$t("time-management.detail." + key)
    },
    formatDate (date) {
      return moment(date).format('dd DD.MM.YYYY')
    },
    easterDateLLongre: function(Y) {
      let M = 3, G = Y % 19 + 1, C = ~~(Y / 100) + 1, L = ~~((3 * C) / 4) - 12,
        E = (11 * G + 20 + ~~((8 * C + 5) / 25) - 5 - L) % 30, D
      E < 0 && (E += 30);
      (E == 25 && G > 11 || E == 24) && E++;
      (D = 44 - E) < 21 && (D += 30);
      (D += 7 - (~~((5 * Y) / 4) - L - 10 + D) % 7) > 31 && (D -= 31, M = 4)
      const month = String(M).padStart(2, '0');
      const day = String(D).padStart(2, '0');

      return `${Y}-${month}-${day}`;
    },
    async getData() {
      this.dataLoaded = false
      let response = await HTTP.get("timelog/sites/" + this.siteId + "/target-hours?year=" + this.filter.year)
      this.item.entries = response.data.entries
      this.item.site = response.data.site
      this.itemOriginal = JSON.parse(JSON.stringify(this.item))
      this.dataLoaded = true
      this.create.easter_sunday = this.easterDateLLongre(this.filter.year)
    },
    async reset() {
      await this.getData()
    },
    async save() {
      await HTTP.post("timelog/sites/" + this.siteId + "/target-hours?year=" + this.filter.year, this.item)
    },
    async applyFilter() {
      await this.getData()
    },
    async generateTargetHours() {
      let data = {
        year: this.filter.year,
        target_hours: this.create.target_hours,
        easter_sunday: this.create.easter_sunday
      }
      await HTTP.post("timelog/sites/" + this.siteId + "/target-hours/generate", data)
      await this.getData()
    }
  }
}
</script>

<style scoped lang="scss">
.no-label {
  margin-top: 0px;
}

.settings-wrapper {
  justify-content: space-between;

  .v-card {
    width: calc(50% - 16px);
    margin-bottom: 32px;
  }
}

.date-col {
  padding-top: 0;
}

.compact-col {
  padding: 1px 4px;
}
</style>
