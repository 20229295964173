<template>
  <div>
    <BasicEditContainer
      v-if="dataLoaded"
      ref="editor"
      :data-loaded="dataLoaded"
      :title="t('edit.title')"
      @cancel="cancel"
      :save-action="saveItem"
      @saved="close"
      :delete-action="deleteItem"
      @deleted="close"
      :object-type="$t('absence.data.object-type')"
      :object-name="data.name"
      :has-changes="hasChanges">
      <absences-reasons-content v-model="data" />
    </BasicEditContainer>
  </div>
</template>

<script>
import { HTTP } from "@/auth"
import DeleteButton from "@/components/inputs/DeleteButton"
import BasicEditContainer from "@/components/inputs/BasicEditContainer"
import AbsencesReasonsContent from "@/views/AbsencesReasonsContent"

export default {
  components: {
    AbsencesReasonsContent,
    BasicEditContainer,
    DeleteButton
  },
  data() {
    return {
      itemId: null,
      data: null,
      dataOriginal: null,
      dataLoaded: false
    }
  },
  async created() {
    this.itemId = this.$route.params.id
    await this.getItem()
  },
  mounted() {
  },
  computed: {
    hasChanges() {
      if (!this.dataOriginal) {
        return false
      }
      return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal)
    }
  },
  methods: {
    t: function(key) {
      return this.$t("absences.reasons.detail." + key)
    },
    async getItem() {
      let response = await HTTP.get("absences/reasons/" + this.itemId)
      this.data = response.data
      this.dataOriginal = JSON.parse(JSON.stringify(this.data))
      this.dataLoaded = true
    },
    saveItem() {
      return HTTP.patch("absences/reasons/" + this.itemId, this.data)
    },
    deleteItem() {
      return HTTP.delete("absences/reasons/" + this.itemId, this.data)
    },
    async onSaved() {
      this.dataOriginal = null
      this.close()
    },
    cancel() {
      this.close()
    },
    close() {
      this.$router.push({ name: "AbsencesReasonsIndex" })
    }
  }
}
</script>
