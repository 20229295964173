import LearningPortalTrainingsIndex from '@/views/LearningPortalTrainingsIndex'
import LearningPortalTrainingsCreateEdit from '@/views/LearningPortalTrainingsCreateEdit'
import LearningPortalFoldersIndex from '@/views/LearningPortalFoldersIndex'
import LearningPortalFoldersCreateEdit from '@/views/LearningPortalFoldersCreateEdit'
import LearningPortalEvaluationsIndex from '@/views/LearningPortalEvaluationsIndex'
import LearningPortalEvaluationsShow from '@/views/LearningPortalEvaluationsShow'

const learningPortalRoutes = [
    {
        path: '/learning-portal',
        name: 'LearningPortalTrainingsIndex',
        component: LearningPortalTrainingsIndex,
        meta: {
            accessConfig:  {
                moduleName: ['learning_portal'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/learning-portal/create',
        name: 'LearningPortalTrainingsCreate',
        component: LearningPortalTrainingsCreateEdit,
        meta: {
            accessConfig:  {
                moduleName: ['learning_portal'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/learning-portal/:id?',
        name: 'LearningPortalTrainingsEdit',
        component: LearningPortalTrainingsCreateEdit,
        meta: {
            accessConfig:  {
                moduleName: ['learning_portal'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/learning-portal-evaluations',
        name: 'LearningPortalEvaluationsIndex',
        component: LearningPortalEvaluationsIndex,
        meta: {
            accessConfig:  {
                moduleName: ['learning_portal'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/learning-portal-evaluations/:id?',
        name: 'LearningPortalEvaluationsShow',
        component: LearningPortalEvaluationsShow,
        meta: {
            accessConfig:  {
                moduleName: ['learning_portal'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/learning-portal-folders',
        name: 'LearningPortalFoldersIndex',
        component: LearningPortalFoldersIndex,
        meta: {
            accessConfig:  {
                moduleName: ['learning_portal'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/learning-portal-folders/create',
        name: 'LearningPortalFoldersCreate',
        component: LearningPortalFoldersCreateEdit,
        meta: {
            accessConfig:  {
                moduleName: ['learning_portal'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/learning-portal-folders/:id?',
        name: 'LearningPortalFoldersEdit',
        component: LearningPortalFoldersCreateEdit,
        meta: {
            accessConfig:  {
                moduleName: ['learning_portal'],
                requiresAuth: true
            }
        }
    }
]

export default learningPortalRoutes
