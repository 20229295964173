<template>
    <div>
      <BasicCreateEditContainer
        :title="mode == 'create' ? t('create.title') : $t('shop.products.edit.title', { productName: product.title })"
        ref="editor"
        object-type="Product"
        :object-name="product.title"
        :data-loaded="dataLoaded"
        @cancel="close"
        :save-action="saveProduct"
        @saved="onSaved"
        :allow-delete="mode === 'edit'"
        :delete-action="deleteProduct"
        @deleted="onDeleted"
        :has-changes="hasChanges"
      >
        <template v-slot:content> 
            <v-card elevation="0">
                <v-card-title>{{ t('create.card-title') }}</v-card-title>
                <v-card-text>

                    <v-text-field
                        :label="t('create.details.name')"
                        v-model="product.title"
                        counter="40"
                        filled
                        :rules="[
                            () => !!product.title || t('validation.required.name'),
                            () => (product.title && product.title.length <= 40) || t('validation.length.name')
                        ]"
                    ></v-text-field>

                    <v-text-field
                        :label="t('create.details.description')"
                        v-model="product.description"
                        counter="120"
                        filled
                        :rules="[
                            () => !!product.description || $t('validation.required.description'),
                            () => (product.description && product.description.length <= 120) || $t('validation.length.description')
                        ]"
                    />

                </v-card-text>
            </v-card>

            <v-card elevation="0" class="sp-mar-top-3">
                <v-card-title>{{ t('create.image.card-title') }}</v-card-title>
                <v-card-text>

                    <v-progress-linear v-show="imageLoading" class="card-loader" height="2" slot="progress" color="primary" indeterminate></v-progress-linear>

                    <picture-upload
                        :initial-picture="previewPictureLink"
                        :show-preview="true"
                        :required="true"
                        aspect-mode="quadratic"
                        v-model="new_picture"
                        @image-changed="imageChanged($event)"
                        control-id="shop-preview" />

                </v-card-text>
            </v-card>

            <v-card elevation="0" class="sp-mar-top-3">
                <v-card-title>{{ t('create.additional-images.card-title') }} {{ '(' + additionalPictures.length + '/' + maxAdditionalPictures + ')' }}</v-card-title>
                <v-card-text>

                    <div>
                        <v-btn
                            v-if="additionalPictures.length < maxAdditionalPictures"
                            elevation="0"
                            color="primary"
                            outlined
                            class="icon-left"
                            @click="addAdditionalImage()"
                        >
                            <v-icon>mdi-plus</v-icon>
                            {{ t('create.additional-images.add-image') }}
                        </v-btn>
                    </div>

                    <div v-for="(ap, index) in additionalPictures" :key="index">
                        <div class="additional-image">
                            <picture-upload
                                :initial-picture="additionalPictures[index].preview"
                                :show-preview="true"
                                :required="true"
                                aspect-mode="quadratic"
                                v-model="additionalPictures[index].image"
                                @image-changed="additionalPictureChanged($event, index)"
                                :control-id="'shop-preview-' + index"
                            ></picture-upload>

                            <span class="remove-additional-image">
                                <v-btn
                                    elevation="0"
                                    color="primary"
                                    text
                                    @click="removeAdditionalImage(index)"
                                >
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>
                            </span>
                        </div>
                    </div>

                </v-card-text>
            </v-card>

            <v-card elevation="0" class="sp-mar-top-3">
                <v-card-title>{{ t('create.infos.card-title') }}</v-card-title>
                <v-card-text>

                    <div>
                        <v-btn
                            elevation="0"
                            color="primary"
                            outlined
                            class="icon-left"
                            @click="addInfoBlock()"
                        >
                        <v-icon>mdi-plus</v-icon>
                        {{ t('create.infos.add-info-block') }}
                        </v-btn>
                    </div>

                    <draggable
                        :list="product.product_infos"
                        tag="div"
                    >
                        <v-card v-for="(info, index) in product.product_infos" :key="index" elevation="0" class="sp-mar-top-3">
                            <v-card-title class="product-info-header">
                                <v-icon class="drag-handle">mdi-drag</v-icon>
                                {{ t('create.infos.section-title') }}
                                <v-icon @click="removeInfoBlock(index)" class="delete-handle">mdi-trash-can-outline</v-icon>
                            </v-card-title>
                            <v-card-text>

                                <v-text-field
                                    :label="t('create.infos.title')"
                                    v-model="info.title"
                                    counter="40"
                                    filled
                                    :rules="[
                                        () => !!info.title || t('validation.required.name'),
                                        () => (info.title && info.title.length <= 40) || t('validation.length.name')
                                    ]"
                                ></v-text-field>

                                <v-textarea
                                    :label="t('create.infos.content')"
                                    v-model="info.content"
                                    filled
                                    :rules="[
                                        () => !!info.content || t('validation.required.content'),
                                    ]"
                                />
            
                            </v-card-text>
                        </v-card>
                    </draggable>

                </v-card-text>
            </v-card>

            <v-card elevation="0" class="sp-mar-top-3">
                <v-card-title>{{ t('create.variants.card-title') }}</v-card-title>
                <v-card-text>

                    <v-switch
                        color="info"
                        v-model="product.has_variants"
                        :false-value="0"
                        :true-value="1"
                        inset
                        :label="t('create.variants.toggle')"
                        :disabled="mode === 'edit'"
                        @change="variantsManagementChanged($event)"
                    ></v-switch>

                    <div v-if="product.has_variants">

                        <div class="sp-mar-top-3">
                            <v-btn
                                elevation="0"
                                color="primary"
                                outlined
                                class="icon-left"
                                :disabled="product.product_variant_types.length > 1"
                                @click="addVariantBlock()"
                            >
                            <v-icon>mdi-plus</v-icon>
                            {{ t('create.variants.add-variant-block') }}
                            </v-btn>
                        </div>

                        <draggable
                            :list="product.product_variant_types"
                            tag="div"
                        >
                            <v-card v-for="(variant, index) in product.product_variant_types" :key="index" elevation="0" class="sp-mar-top-3">
                                <v-card-title class="product-variant-header">
                                    <v-icon class="drag-handle">mdi-drag</v-icon>
                                    {{ t('create.variants.section-title') }}
                                    <v-icon @click="removeVariantBlock(index)" class="delete-handle">mdi-trash-can-outline</v-icon>
                                </v-card-title>
                                <v-card-text>

                                    <v-text-field
                                        :label="t('create.variants.title')"
                                        v-model="variant.name"
                                        counter="40"
                                        filled
                                        persistent-placeholder
                                        :placeholder="t('create.variants.title-placeholder')"
                                        :rules="[
                                            () => !!variant.name || t('validation.required.name'),
                                            () => (variant.name && variant.name.length <= 40) || t('validation.length.name')
                                        ]"
                                    ></v-text-field>

                                    <div class="variant-element-selection">

                                        <div class="elements-input-label">{{ t('create.variants.elements-title') }}</div>

                                        <div class="variant-element-wrapper" :class="[{ 'has-content': variant.values.length > 0 }]">
                                            <v-chip
                                                v-for="(value, eindex) in variant.values"
                                                :key="eindex"
                                                color="info"
                                                class="variant-element"
                                            >
                                                {{ value }}
                                                <v-icon @click.stop="removeChip(index, eindex)" class="close-icon">mdi-window-close</v-icon>
                                            </v-chip>
                                        </div>

                                        <v-text-field
                                            ref="elementsInput"
                                            filled
                                            dense
                                            hide-details="auto"
                                            persistent-placeholder
                                            v-model="variant.valuesText"
                                            @input="checkForComma(index)"
                                            @keyup.enter.prevent="handleEnter($event, index)"
                                            @paste.stop.prevent="handlePaste($event, index)"
                                            class="elements-input"
                                            :placeholder="variant.values.length === 0 ? t('create.variants.elements-placeholder') : ''"
                                            :rules="[
                                                () => variant.values.length > 0 || t('validation.required.variants'),
                                            ]"
                                        ></v-text-field>
                                    </div>

                                </v-card-text>
                            </v-card>
                        </draggable>

                    </div>

                </v-card-text>
            </v-card>

            <v-card v-if="product.has_variants" elevation="0" class="sp-mar-top-3">
                <v-card-title>{{ t('create.variant-details.card-title') }}</v-card-title>
                <v-card-text>

                    <v-data-table
                        :headers="detailsHeaders(false)"
                        :items="product.product_variants"
                        :no-data-text="t('create.table.no-data')"
                        class="simple-data-table elevation-0 mb-0"
                        hide-default-footer
                        :items-per-page="-1"
                        :refreshKey="refreshKey"
                    >
                        <template v-slot:[`item.identifier`]="{ item }">
                            {{ item.product_variant_values.map((pvv) => pvv.value).join(' | ') }}
                        </template>     
                        
                        <template v-slot:[`item.inventory`]="{ item }">
                            <div v-if="isEditAndStockManagement && item.id" class="fields-wrapper">  
                                {{ item.inventory }}
                                <span v-if="item.inventory_change">
                                    (
                                        <span v-if="item.inventory_change >= 0">+</span>
                                        <span v-else>-</span>
                                        {{ item.inventory_change | absValue }}
                                    )
                                </span>
                            </div>
                            <div v-else>
                                <v-text-field
                                    class="in-table-field"
                                    type="number"
                                    v-model.number="item.inventory"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    :rules="[
                                        () => !isNaN(parseInt(item.inventory)) || t('validation.required.inventory'),
                                    ]"
                                ></v-text-field>
                            </div>
                        </template>

                        <template v-slot:[`item.sku`]="{ item }">
                            <v-text-field
                                class="in-table-field"
                                v-model="item.sku"
                                outlined
                                dense
                                hide-details="auto"
                                :rules="[
                                    () => !!item.sku || t('validation.required.sku'),
                                ]"
                            ></v-text-field>
                        </template>

                        <template v-slot:[`item.active`]="{ item }">
                            <span @click="item.status = 'inactive'" class="status-chip active" v-if="item.status === 'active'">            
                                <v-icon left>
                                    mdi-eye-outline
                                </v-icon>
                                {{ t('create.table.status.active') }}
                            </span>
                            <span @click="item.status = 'active'" class="status-chip inactive" v-else>
                                <v-icon left>
                                    mdi-eye-off-outline
                                </v-icon>
                                {{ t('create.table.status.inactive') }}
                              </span>
                        </template>

                        <template v-slot:[`item.edit`]="{ item, index }">
                            <span @click="editVariantDetail(item, index)" class="in-table-edit">
                                <v-icon
                                    small
                                    class="edit-row-button"
                                >
                                    edit
                                </v-icon>
                            </span>
                          </template>
                    </v-data-table>

                </v-card-text>
            </v-card>

        </template>

        <template v-slot:meta>
            <v-card v-if="!product.has_variants" elevation="0">
                <v-card-title>{{ t('create.sku.card-title') + '*' }}</v-card-title>
                <v-card-text>
                  
                    <v-text-field
                        :label="t('create.sku.label')"
                        v-model="product.sku"
                        filled
                        hide-details="auto"
                        :rules="[
                            () => !!product.sku || t('validation.required.sku')
                        ]"
                    ></v-text-field>
      
                </v-card-text>
            </v-card>

            <v-card elevation="0" :class="[{ 'sp-mar-top-3': !product.has_variants }]">
                <v-card-title>{{ $t('components.category-select.label') + '*' }}</v-card-title>
                <v-card-text>
                  
                    <shop-category-selector
                        v-model="product.categories"
                        :required="true"
                    ></shop-category-selector>
      
                </v-card-text>
            </v-card>

            <v-card elevation="0" class="sp-mar-top-3">
                <v-card-title>{{ t('status.card-title') }}</v-card-title>
                <v-card-text>

                    <v-select
                        filled
                        :label="t('status.status-select-label')"
                        v-model="product.status"
                        :items="productStatusItems"
                        item-text="name"
                        item-value="slug"
                    ></v-select>

                </v-card-text>
            </v-card>

            <v-card elevation="0" class="sp-mar-top-3">
                <v-card-title>{{ t('create.inventory.card-title') }}</v-card-title>
                <v-card-text>

                    <v-switch
                        color="info"
                        v-model="product.has_inventory"
                        :false-value="0"
                        :true-value="1"
                        inset
                        :disabled="mode === 'edit'"
                        :label="t('create.inventory.toggle')"
                    ></v-switch>

                    <div v-if="product.has_inventory && !product.has_variants" class="sp-mar-top-3">
                        <div v-if="isEditAndStockManagement" class="fields-wrapper">    
                            <div>
                                <span class="product-stock-label">{{ t('create.inventory.stock') }}</span>
                                <span class="product-stock">{{ product.inventory }}</span>
                            </div>
                            <v-text-field
                                :label="t('create.inventory.change-label')"
                                type="number"
                                v-model.number="product.inventory_change"
                                filled
                                hide-details="auto"
                            ></v-text-field>
                        </div>
                        <div v-else class="fields-wrapper"> 
                            <v-text-field
                                :class="[{ 'is-edit-mode': mode === 'edit' }]"
                                :label="t('create.inventory.label')"
                                type="number"
                                v-model.number="product.inventory"
                                filled
                                :readonly="mode === 'edit'"
                                hide-details="auto"
                                :rules="[
                                    () => !isNaN(parseInt(product.inventory)) || t('validation.required.inventory')
                                ]"
                            ></v-text-field>
                        </div>
                        
                    </div>

                </v-card-text>
            </v-card>

            <v-card v-if="isEditAndStockManagement && !product.has_variants && product.inventory_change && !isNaN(parseInt(product.inventory_change))" elevation="0" class="sp-mar-top-3">
                <v-card-title>{{ t('create.inventory-change.card-title') }}</v-card-title>
                <v-card-text>

                    <v-text-field
                        :label="t('create.inventory-change.inventory-label')"
                        v-model.number="product.inventory_change_comment"
                        filled
                        hide-details="auto"
                    ></v-text-field>

                </v-card-text>
            </v-card>
        </template>

    </BasicCreateEditContainer>

    <v-dialog
        v-model="showVariantDetails"
        width="1200"
        persistent
        content-class="variant-details-dialog"
    >
        <v-card>
            <v-card-title>{{ t('create.variant-dialog.card-title') }}</v-card-title>
            <v-card-text v-if="selectedVariant">
            
                <v-data-table
                    :headers="detailsHeaders(true)"
                    :items="[selectedVariant]"
                    class="simple-data-table elevation-0 mb-0"
                    hide-default-footer
                >
                    <template v-slot:[`item.identifier`]="{ item }">
                        {{ item.product_variant_values.map((pvv) => pvv.value).join(' | ') }}
                    </template>

                    <template v-slot:[`item.inventory`]="{ item }">
                        <div v-if="isEditAndStockManagement && item.id" class="fields-wrapper">    
                            {{ item.inventory }}
                        </div>
                        <div v-else>
                            <v-text-field
                                class="in-table-field"
                                type="number"
                                v-model.number="item.inventory"
                                outlined
                                dense
                                hide-details="auto"
                                :rules="[
                                    () => !isNaN(parseInt(item.inventory)) || t('validation.required.inventory'),
                                ]"
                            ></v-text-field>
                        </div>
                    </template> 

                    <template v-slot:[`item.inventory-change`]="{ item }">
                        <v-text-field
                            class="in-table-field"
                            type="number"
                            v-model.number="item.inventory_change"
                            outlined
                            dense
                            hide-details="auto"
                        ></v-text-field>
                    </template> 

                    <template v-slot:[`item.sku`]="{ item }">
                        <v-text-field
                            class="in-table-field"
                            v-model="item.sku"
                            outlined
                            dense
                            hide-details="auto"
                            :rules="[
                                () => !!item.sku || t('validation.required.sku'),
                            ]"
                        ></v-text-field>
                    </template>

                    <template v-slot:[`item.active`]="{ item }">
                        <span @click="item.status = 'inactive'" class="status-chip active" v-if="item.status === 'active'">            
                            <v-icon left>
                                mdi-eye-outline
                            </v-icon>
                            {{ t('create.table.status.active') }}
                        </span>
                        <span @click="item.status = 'active'" class="status-chip inactive" v-else>
                            <v-icon left>
                                mdi-eye-off-outline
                            </v-icon>
                            {{ t('create.table.status.inactive') }}
                            </span>
                    </template>
                </v-data-table>

                <v-card v-if="isEditAndStockManagement && selectedVariant.inventory_change && !isNaN(parseInt(selectedVariant.inventory_change))" elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('create.inventory-change.card-title') }}</v-card-title>
                    <v-card-text>
    
                        <v-text-field
                        :label="t('create.inventory-change.inventory-label')"
                        v-model.number="selectedVariant.inventory_change_comment"
                        filled
                        hide-details="auto"
                    ></v-text-field>
    
                    </v-card-text>
                </v-card>

                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('create.infos.card-title') }}</v-card-title>
                    <v-card-text>
    
                        <div>
                            <v-btn
                                elevation="0"
                                color="primary"
                                outlined
                                class="icon-left"
                                @click="addInfoBlock(selectedVariant)"
                            >
                            <v-icon>mdi-plus</v-icon>
                            {{ t('create.infos.add-info-block') }}
                            </v-btn>
                        </div>
    
                        <draggable
                            :list="selectedVariant.product_variant_infos"
                            tag="div"
                        >
                            <v-card v-for="(info, index) in selectedVariant.product_variant_infos" :key="index" elevation="0" class="sp-mar-top-3">
                                <v-card-title class="product-info-header">
                                    <v-icon class="drag-handle">mdi-drag</v-icon>
                                    {{ t('create.infos.section-title') }}
                                    <v-icon @click="removeInfoBlock(index, selectedVariant)" class="delete-handle">mdi-trash-can-outline</v-icon>
                                </v-card-title>
                                <v-card-text>
    
                                    <v-text-field
                                        :label="t('create.infos.title')"
                                        v-model="info.title"
                                        counter="40"
                                        filled
                                        :rules="[
                                            () => !!info.title || t('validation.required.name'),
                                            () => (info.title && info.title.length <= 40) || t('validation.length.name')
                                        ]"
                                    ></v-text-field>
    
                                    <v-textarea
                                        :label="t('create.infos.content')"
                                        v-model="info.content"
                                        filled
                                        :rules="[
                                            () => !!info.content || t('validation.required.content'),
                                        ]"
                                    />
                
                                </v-card-text>
                            </v-card>
                        </draggable>
    
                    </v-card-text>
                </v-card>
                
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="primary"
                    text
                    elevation="0"
                    class="icon-left"
                    @click="closeVariantDetail"
                >
                    <v-icon>mdi-window-close</v-icon>
                    {{ t('create.variant-dialog.close') }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    elevation="0"
                    class="icon-left"
                    @click="saveVariantDetail"
                >
                    <v-icon>mdi-window-close</v-icon>
                    {{ t('create.variant-dialog.save') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</div>
</template>

<script>
  import { HTTP, API } from '@/auth'
  import { Buffer } from 'buffer'
  import store from 'store'
  import BasicCreateEditContainer from '@/components/inputs/BasicCreateEditContainer'
  import ShopCategorySelector from '@/views/ShopCategorySelector'
  import PictureUpload from '@/components/inputs/PictureUpload'
  import draggable from 'vuedraggable'

  export default {
    name: 'ShopProductsCreateEdit',
    components: {
      BasicCreateEditContainer,
      ShopCategorySelector,
      PictureUpload,
      draggable
    },
    data () {
      return {
        product: {
          id: null,
          title: '',
          description: '',
          categories: [],
          status: 'active',
          image: null,
          product_images: [],
          product_infos: [],
          product_variants: [],
          product_variant_types: [],
          has_variants: false,
          has_inventory: false
        },
        previewPictureLink: null,
        new_picture: null,
        additionalPictures: [],
        maxAdditionalPictures: 5,
        pictureChanged: false,
        dataOriginal: null,
        dataLoaded: false,
        mode: null,
        productStatusItems: [
            {
                "slug": "active",
                "name": this.$t('shop.status.active')
            },
            {
                "slug": "inactive",
                "name": this.$t('shop.status.inactive')
            }
        ],
        refreshKey: 0,
        imageLoading: false,
        showVariantDetails: false,
        selectedVariant: null,
        selectedVariantIndex: null
      }
    },
    filters: {
        absValue: function (value) {
            return Math.abs(value)
        }
    },
    created() {
        this.product.id = this.$route.params.id
        if (this.product.id) {
            this.getProduct()
            this.mode = 'edit'
        } else {
            this.mode = 'create'
            this.dataOriginal = JSON.parse(JSON.stringify(this.product))
            this.dataLoaded = true
        }
    },
    computed: {
        hasChanges() {
            return JSON.stringify(this.product) !== JSON.stringify(this.dataOriginal) || this.additionalPictures.length !== this.product.product_images.length || this.imagesChanged || this.pictureChanged
        },
        isEditAndStockManagement() {
            return this.dataLoaded && this.mode === 'edit' && this.product.has_inventory == this.dataOriginal.has_inventory
        },
        imagesChanged() {
            return this.additionalPictures.filter(ap => ap.changed).length > 0
        }
    },
    methods: {
        t: function (key) {
            return this.$t('shop.products.' + key)
        },
        getProduct() {
            HTTP.get('shop_products/' + this.product.id).then(function (response) {
                this.product = response.data
                if (!this.product.has_variants) {
                    this.$set(this.product, 'sku', this.product.product_variants[0].sku)
                    if (this.product.product_variants[0].inventory) {
                        this.$set(this.product, 'inventory', this.product.product_variants[0].inventory)
                        this.$set(this.product, 'inventory_change', null)
                        this.$set(this.product, 'inventory_change_comment', null)
                    }
                }

                this.product.product_variant_types.forEach((pvt) => {
                    pvt.values = JSON.parse(pvt.values)
                })

                let categories = []
                this.product.categories.forEach((cat) => {
                    categories.push({
                        'id': cat.id,
                        'name': cat.name
                    })
                })
                this.product.categories = categories

                if (this.product.product_images.length > 0) {
                    this.product.product_images.forEach((pi, index) => {
                        this.additionalPictures.push({
                            'id': pi.id,
                            'image': null,
                            'changed': false,
                            'preview': null 
                        })
                        this.getPreviewAdditionalPictureLink(pi.id, index)
                    })
                }

                this.dataOriginal = JSON.parse(JSON.stringify(this.product))
                this.getPreviewPictureLink()
                this.dataLoaded = true
            }.bind(this)).catch(function (error) {
                console.log(error)
                this.dataLoaded = true
            }.bind(this))
        },
        getPreviewPictureLink() {  
            this.imageLoading = true
            HTTP.get('/shop_products/image/' + this.product.id, {
                headers: {
                    Authorization: 'Bearer ' + store.get('id_token')
                },
                responseType: 'arraybuffer',
            }).then(function (response) {
                const buffer = Buffer.from(response.data, 'binary')
                const base64Image = buffer.toString('base64')
                this.previewPictureLink = `data:image/jpeg;base64,${base64Image}`
                this.imageLoading = false
            }.bind(this)).catch(function (error) {
                console.error("Error fetching image:", error)
                this.previewPictureLink = ''
                this.imageLoading = false
            }.bind(this))
        },
        getPreviewAdditionalPictureLink(id, index) {  
            HTTP.get('/shop_products/additional-image/' + id, {
                headers: {
                    Authorization: 'Bearer ' + store.get('id_token')
                },
                responseType: 'arraybuffer',
            }).then(function (response) {
                const buffer = Buffer.from(response.data, 'binary')
                const base64Image = buffer.toString('base64')
                this.additionalPictures[index].preview = `data:image/jpeg;base64,${base64Image}`
            }.bind(this)).catch(function (error) {
                console.error("Error fetching image:", error)
                this.additionalPictures[index].preview = null
            }.bind(this))
        },
        saveProduct() {
            if (this.product.product_infos) {
                this.product.product_infos.forEach((info, index) => {
                    info.order = index
                })
            }

            if (this.product.product_variant_types) {
                this.product.product_variant_types.forEach((variant, index) => {
                    variant.id = variant.id ? variant.id : 'pvt-' + index
                    variant.order = index
                })
            }

            if (this.product.product_variants) {
                this.product.product_variants.forEach((variant, index) => {
                    variant.order = index
                    if (variant.product_variant_infos) {
                        variant.product_variant_infos.forEach((pvi, iindex) => {
                            pvi.order = iindex
                        })
                    }
                })
            }

            if (this.pictureChanged && this.new_picture) {
                this.product.new_picture = this.new_picture
            }

            if (this.additionalPictures.length > 0) {
                this.product.additionalPictures = JSON.parse(JSON.stringify(this.additionalPictures))
                let order = 0
                this.product.additionalPictures.forEach((ap) => {
                    ap.order = order
                    if (!ap.changed) {
                        delete ap.image
                    }
                    delete ap.preview
                    order++
                })
            } else {
                this.product.additionalPictures = []
            }

            if (this.mode == 'create') {
                return HTTP.post('shop_products', this.product)
            } else {
                return HTTP.patch('shop_products/' + this.product.id, this.product)
            }
        },
        onSaved() {
            this.dataOriginal = null;
            this.close()
        },
        deleteProduct() {
            return HTTP.delete('shop_products/' + this.product.id)
        },
        onDeleted() {
            this.dataOriginal = null
            this.close()
        },
        addAdditionalImage() {
            this.additionalPictures.push({
                'id': null,
                'image': null,
                'changed': false,
                'preview': null
            })
        },
        removeAdditionalImage(index) {
            this.additionalPictures.splice(index, 1)
        }, 
        addInfoBlock(selectedVariant) {
            if (selectedVariant) {
                let pushIndex = this.selectedVariant.product_variant_infos.length
                let productInfo = typeof this.product.product_infos[pushIndex] !== 'undefined' ? this.product.product_infos[pushIndex] : null
                if (productInfo) {
                    this.selectedVariant.product_variant_infos.push(productInfo) 
                } else {
                    this.selectedVariant.product_variant_infos.push({
                            'title': '',
                            'content': ''
                    })
                }
            } else {
                this.product.product_infos.push({
                        'title': '',
                        'content': ''
                })
            }
        },
        removeInfoBlock(index, selectedVariant) {
            if (selectedVariant) {
                this.selectedVariant.product_variant_infos.splice(index, 1)
            } else {
                this.product.product_infos.splice(index, 1)
            }
        },
        addVariantBlock() {
            this.product.product_variant_types.push({
                'name': '',
                'values': [],
                'valuesText': ''
           })
           this.updateProductVariantsList()
        },
        removeVariantBlock(index) {
            this.product.product_variant_types.splice(index, 1)
            this.updateProductVariantsList()
        },
        handleEnter(event, index) {
            this.product.product_variant_types[index].valuesText += ','
            this.checkForComma(index)
        },
        checkForComma(index) {
            if (this.product.product_variant_types[index].valuesText.endsWith(',')) {
                const newElement = this.product.product_variant_types[index].valuesText.slice(0, -1).trim()
                if (newElement) {
                    this.product.product_variant_types[index].values.push(newElement)
                    this.updateProductVariantsList()
                }
                this.product.product_variant_types[index].valuesText = ''
            }
        },
        handlePaste(event, index) {
            const pastedData = event.clipboardData.getData('text')
            const items = pastedData.split(/[\n,]+/).map(item => item.trim())
            items.forEach(item => {
                if (item) {
                    this.product.product_variant_types[index].values.push(item)
                }
            })
            this.validateChips()
            this.updateProductVariantsList()
        },
        removeChip(index, eindex) {
            this.product.product_variant_types[index].values.splice(eindex, 1)
            this.updateProductVariantsList()
        },
        validateChips() {
            this.$nextTick(function () {
                this.$refs.elementsInput[0].validate()
            }.bind(this))
        },
        updateProductVariantsList() {
            let identifiers = []
            this.product.product_variant_types.forEach((variant, vindex) => {
                let identifiersToAdd = []
                variant.values.forEach((element, eindex) => {
                    if (vindex === 0) {
                        identifiersToAdd.push(vindex + '-' + eindex)
                    } else {
                        identifiers.forEach(identifier => {
                            identifiersToAdd.push(identifier + '-' + vindex + '-' + eindex)
                        })
                    }
                })
                identifiers = [...identifiersToAdd]
            })
            let variantsToAdd = []
            identifiers.forEach((identifier, index) => {
                let identifierName = this.getIdentifierName(identifier)
                let el = this.product.product_variants.filter((pv) => { 
                    return pv.product_variant_values ? pv.product_variant_values.map((pvv) => pvv.value).join(' | ') === identifierName : false
                })
                if (el.length === 0) {
                    variantsToAdd.push({
                        'product_id': this.product.id ? this.product.id : null,
                        'name': this.product.title ? this.product.title : null,
                        'sku': null,
                        'inventory': null,
                        'status': 'active',
                        'product_variant_infos': [],
                        'product_variant_values': this.getIdentifierValues(identifier),
                        'updated': true,
                        'update_index': index
                    })
                } else {
                    el[0].updated = true
                }
            })
            variantsToAdd.forEach((vta) => {
                this.product.product_variants.splice(vta.update_index, 0, vta)
            })
            let variantsToRemove = []
            this.product.product_variants.forEach((pv, index) => {
                if (!pv.updated) {
                    variantsToRemove.push(index)
                }
            })
            if (variantsToRemove.length > 0) {
                let variantsToRemoveReverse = variantsToRemove.reverse()
                variantsToRemoveReverse.forEach((vtr) => {
                    this.product.product_variants.splice(vtr, 1)
                })
            }
            this.product.product_variants.forEach((pv, index) => {
                pv.updated = false
            })
            this.refreshKey++
        },
        getIdentifierName(identifier) {
            let indizes = identifier.split('-')
            let names = []
            for (let i = 0; i < indizes.length / 2; i++) {
                names.push(this.product.product_variant_types[indizes[i*2]].values[indizes[(i*2)+1]])
            }
            return names.join(' | ')
        },
        getIdentifierValues(identifier) {
            let indizes = identifier.split('-')
            let values = []
            for (let i = 0; i < indizes.length / 2; i++) {
                let pvtId = this.product.product_variant_types[indizes[i*2]].id
                values.push({
                    'product_variant_type_id': pvtId ? pvtId : 'pvt-' + indizes[i*2],
                    'product_variant_id': identifier,
                    'value': this.product.product_variant_types[indizes[i*2]].values[indizes[(i*2)+1]]
                })
            }
            return values
        },
        editVariantDetail(item, index) {
            this.selectedVariantIndex = index
            let itemCopy = JSON.parse(JSON.stringify(item))
            this.$set(itemCopy, 'inventory_change', item.inventory_change ?? null )
            this.$set(itemCopy, 'inventory_change_comment', item.inventory_change_comment ?? null)
            if (!itemCopy.product_variant_infos && this.product.product_infos.length > 0) {
                this.$set(itemCopy, 'product_variant_infos', JSON.parse(JSON.stringify(this.product.product_infos)))
            }
            this.selectedVariant = itemCopy
            this.showVariantDetails = true
        },
        saveVariantDetail() {
            Object.assign(this.product.product_variants[this.selectedVariantIndex], this.selectedVariant)
            this.selectedVariant = null
            this.selectedVariantIndex = null
            this.showVariantDetails = false
        },
        closeVariantDetail() {
            this.selectedVariant = null
            this.selectedVariantIndex = null
            this.showVariantDetails = false
        },
        variantsManagementChanged(event) {
            console.log(event)
            if (event === 0) {
                this.product.product_variants.splice(0)
                this.product.product_variant_types.splice(0)
            }
        },
        imageChanged(event) {
            this.pictureChanged = event
        },
        additionalPictureChanged(event, index) {
            this.additionalPictures[index]['changed'] = event
        },
        detailsHeaders(showInventoryChange) {
            let headers = []
            if (this.product.has_variants) {
                headers.push(
                    {
                        text: this.t('create.table.identifier'),
                        align: 'start',
                        sortable: false,
                        value: 'identifier',
                        cellClass: 'text-middle'
                    }
                )
            }
            if (this.product.has_inventory) {
                headers.push(
                    {
                        text: this.isEditAndStockManagement || this.mode === 'create' ? this.t('create.table.inventory') : this.t('create.table.stock'),
                        align: 'start',
                        sortable: false,
                        value: 'inventory',
                        width: 200,
                        cellClass: this.isEditAndStockManagement ? 'text-middle' : ''
                    }
                )
                if (showInventoryChange && this.selectedVariant && this.selectedVariant.id && this.isEditAndStockManagement) {
                    headers.push(
                        {
                            text: this.t('create.table.inventory-change'),
                            align: 'start',
                            sortable: false,
                            value: 'inventory-change',
                            width: 200
                        }
                    )
                }
            }
            headers.push(
                {
                    text: this.t('create.table.sku'),
                    align: 'start',
                    sortable: false,
                    value: 'sku'
                }
            )
            if (this.product.has_variants) {
                headers.push(
                    {
                        text: this.t('create.table.active'),
                        align: 'start',
                        sortable: false,
                        value: 'active',
                        width: 150,
                        cellClass: 'text-middle'
                    }
                )   
            }
            if (!showInventoryChange) {
                headers.push(
                    {
                        text: this.t('create.table.edit'),
                        value: 'edit',
                        sortable: false,
                        align: 'center',
                        width: 100,
                        cellClass: 'text-middle'
                    }
                )
            }
            return headers
        },
        close() {
            this.$router.push({ name: 'ShopProductsIndex' })
        }
    }
}
</script>

<style scoped lang="scss">
.product-info-header,
.product-variant-header {
    cursor: grab;

    .delete-handle {
        float: right;
        margin: 0;
        color: black;
        cursor: pointer;
    }
}

.variant-element-selection {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background: #F5F5F5 !important;
    border-radius: 4px;

    .elements-input-label {
        width: 100%;
        padding: 6px 10px 0 10px;
        font-size: 13px;
    }

    .variant-element-wrapper {

        &.has-content {
            padding: 0 0 6px 10px;
        }  

        .variant-element {
            margin-right: 6px;
        }
    }

    .close-icon {
        margin-left: 6px;
        margin-top: -1px;
        cursor: pointer;
        width: 20px;
        height: 20px;
        font-size: 16px;
    }

    .elements-input {
        border: none;
        outline: none;
        flex-grow: 1;
        margin-top: -6px;

        ::v-deep .v-text-field__details {
            padding-top: 6px;
            margin-bottom: 0;
            background: white;
        }
    }
}

.status-chip {
    display: inline-block; 
    font-size: 14px;
    line-height: 20px;
    padding: 1px 8px;
    border-radius: 8px;
    cursor: pointer;
  
    &.active {
        color: #49894B;
        background: rgba(112, 210, 115, 0.19);
    
        .v-icon {
            color: #49894B;
        }
    }
  
    &.inactive {
        color: #FB8C00;
        background: rgba(254, 197, 125, 0.24);
    
        .v-icon {
            color: #FB8C00;
        }
    }
}

.card-loader {
    position: absolute;
    top: 0;
    left: 0;
}

.in-table-edit {
    display: inline-block;
    cursor: pointer;
}

.fields-wrapper {
    display: flex;
    gap: 16px;

    .product-stock-label {
        display: block;
        color: black;
        font-size: 12px;
        margin-top: 3px;
    }

    .product-stock {
        color: black;
        font-size: 16px;
    }
}

.additional-image {
    position: relative;
    display: flex;
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid #E0E0E0;

    & > div {
        flex-basis: 100%;
    }

    .remove-additional-image {
        position: absolute;
        top: 16px;
        right: 0;
    }
}
</style>