import QCNotificationsIndex from '@/views/QCNotificationsIndex'
import QCNotificationsCreateEdit from '@/views/QCNotificationsCreateEdit'
import SettingsQcNotifications from '@/views/SettingsQcNotifications'

const QCNotificationsRoutes = [
  {
    path: '/qc_notifications',
    name: 'QCNotificationsIndex',
    component: QCNotificationsIndex,
    meta: {
      accessConfig:  {
          moduleName: ['qc_notifications'],
          requiresAuth: true
      }
    }
  },
  {
    path: '/qc_notifications/create',
    name: 'QCNotificationsCreate',
    component: QCNotificationsCreateEdit,
    meta: {
      accessConfig:  {
          moduleName: ['qc_notifications'],
          requiresAuth: true
      }
    }
  },
  {
    path: '/qc_notifications/:id?',
    name: 'QCNotificationsEdit',
    component: QCNotificationsCreateEdit,
    meta: {
      accessConfig:  {
          moduleName: ['qc_notifications'],
          requiresAuth: true
      }
    }
    
  },
  {
    path: '/settings/qc-notifications',
    name: 'SettingsQcNotifications',
    component: SettingsQcNotifications,
    meta: {
      accessConfig:  {
          moduleName: ['qc_notifications'],
          requiresAuth: true
      }
  }
  }
]

export default QCNotificationsRoutes
