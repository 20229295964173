<template>
  <div class="empty-datatable">
    <v-icon>mdi-alert-circle-outline</v-icon>
    {{ t("no-data") }}
  </div>
</template>
<script lang="ts">

export default {
  name: "NoData",
  methods: {
    t: function(key) {
      return this.$t("errors." + key)
    }
  }
}
</script>