var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('toolbar',{attrs:{"dataLoaded":_vm.dataLoaded}},[_vm._v(_vm._s(_vm.title))]),_c('v-container',{staticClass:"edit-screen no-hero-container",attrs:{"fluid":""}},[_c('v-row',{staticClass:"data-table-toolbar"},[_vm._t("toolbar-buttons-left"),_c('v-spacer'),_vm._t("toolbar-buttons-right")],2),_c('v-row',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"sort-by":_vm.initialSortBy,"sort-desc":_vm.initialSortDesc,"footer-props":{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
          'items-per-page-options': [25, 50, 100, 200],
          pageText: '{0}-{1} von {2}',
          'items-per-page-text': _vm.$t('table.items-per-page')
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(column){return {key:("item." + (column.value)),fn:function(ref){
        var item = ref.item;
return [_vm._t(("column-" + (column.value)),null,{"item":item,"column":column})]}}})],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }