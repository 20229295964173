<template>
  <div class="has-hero-container">
    <div>

      <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

      <div class="sp-hero sp-bg-light-blue">

        <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter(); options.page = 1">

          <v-btn
            v-if="filterChanged"
            elevation="0"
            color="primary"
            class="filter-apply icon-left"
            @click="applyFilter(); options.page = 1"
          >
            <v-icon>mdi-check</v-icon>
            {{ $t('components.filter-apply.label') }}
          </v-btn>

          <v-slide-group show-arrows>
            <v-slide-item>
              <div>
                <div class="filter-item">
                  <app-filter-date-picker
                    :filterLabel="$t('components.app-filter-date-picker.label.startdate')"
                    :date="dateStart"
                    id="dateStart"
                  ></app-filter-date-picker>
                </div>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div>
                <div class="filter-item">
                  <app-filter-date-picker
                    :filterLabel="$t('components.app-filter-date-picker.label.enddate')"
                    :date="dateEnd"
                    id="dateEnd"
                  ></app-filter-date-picker>
                </div>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-search-list">
                <div class="filter-item">
                  <div class="filter-item-wrapper">
                    <app-filter-search-field
                      v-model="searchTermCustomer"
                      :disable="!!selectedCustomer"
                      :filterLabel="$t('components.app-filter-search-field.label.customer')"
                      clearable
                      append-icon="mdi-magnify"
                      @click:clear="clearSearchCustomer"
                    ></app-filter-search-field>
                    <v-list
                      v-if="!selectedCustomer && customers.length > 0 && customers.length < 15"
                      class="search-results elevation-2"
                    >
                      <v-list-item 
                        v-for="customer in customers"
                        :key="customer.id"
                        @click="setCustomer(customer.id, customer.name)"
                      >
                        <v-list-item-content>
                          <v-list-item-title>{{ customer.name }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </div>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-search-list">
                <div class="filter-item">
                  <div class="filter-item-wrapper">
                    <user-filter-widget
                      v-model="selectedUser"
                      :include-inactive="true"
                    ></user-filter-widget>
                  </div>
                </div>
              </div>
            </v-slide-item>
          </v-slide-group>

        </v-container>

      </div>

      <v-container fluid>

        <v-row class="data-table-toolbar">
          <v-spacer></v-spacer>
          <div v-show="dataLoaded && timelogItems.length > 0">
            <v-btn
              elevation="0"
              color="primary"
              outlined
              class="icon-left"
              :disabled="!readyToDownload"
              :loading="isDownloadingCSV"
              @click="saveAsCSV()"
            >
              <v-icon>mdi-download</v-icon>
              {{ t('excel-export') }}
            </v-btn>
          </div>
        </v-row>

        <v-row v-if="dataLoaded && timelogItems.length === 0">
          <div class="empty-datatable">
            <v-icon>mdi-alert-circle-outline</v-icon>
            {{ t('no-data') }}
          </div>
        </v-row>

        <v-row v-else>
          <v-data-table
            :headers="headers"
            :items="timelogItems"
            :options.sync="options"
            :server-items-length="totalTimelogs"
            :loading="loading"
            class="elevation-0 small-font-size"
            :sort-by="initialSortBy"
            :sort-desc="initialSortDesc"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-options': [25, 50, 100, -1],
              pageText: '{0}-{1} von {2}',
              'items-per-page-text': t('table.items-per-page')
            }"
          >
            <template v-slot:[`item.date`]="{ item }">
              {{ item.stamped_in | momentWeekday }}<br>{{ item.stamped_in | momentDate }}
            </template>

            <template v-slot:[`item.number`]="{ item }">
              {{ item.user.number }}
            </template>

            <template v-slot:[`item.staff_number`]="{ item }">
              {{ item.user.staff_number }}
            </template>

            <template v-slot:[`item.lastname`]="{ item }">
              {{ item.user.name }}
            </template>

            <template v-slot:[`item.firstname`]="{ item }">
              {{ item.user.firstname }}
            </template>

            <template v-slot:[`item.object`]="{ item }">
              <span v-if="item.customer.radius === null">{{ item.customer.name }} (n/a)</span>
              <span v-else>{{ item.customer.name }} ({{ item.customer.radius }} m)</span>
            </template>

            <template v-slot:[`item.stampin`]="{ item }">
              <div v-if="item.radius_in" class="radius-value-wrapper" :class="{'radius-alert' : item.radiusInOver}">
                <div class="radius-value">{{ item.radius_in }} m</div>
                <div v-if="item.stamped_in_location" class="radius-map">
                  <v-btn
                    target="_blank"
                    :href="'https://maps.google.com/maps?q=loc:' + item.stamped_in_location.lat + '+,' + item.stamped_in_location.lng"
                    small
                    icon
                    color="red"
                  >
                    <v-icon>mdi-map</v-icon>
                  </v-btn>
                </div>
              </div>
              <span v-else>n/a</span>
            </template>

            <template v-slot:[`item.stampindate`]="{ item }">
              {{ item.stamped_in | momentDate }}<br>{{ item.stamped_in | momentTime }}
            </template>

            <template v-slot:[`item.stampout`]="{ item }">
              <div v-if="item.radius_out" class="radius-value-wrapper" :class="{'radius-alert' : item.radiusOutOver}">
                <div class="radius-value">{{ item.radius_out }} m</div>
                <div v-if="item.stamped_out_location" class="radius-map">
                  <v-btn
                    target="_blank"
                    :href="'https://maps.google.com/maps?q=loc:' + item.stamped_out_location.lat + '+,' + item.stamped_out_location.lng"
                    small
                    icon
                    color="red"
                  >
                    <v-icon>mdi-map</v-icon>
                  </v-btn>
                </div>
              </div>
              <span v-else>n/a</span>
            </template>

            <template v-slot:[`item.stampoutdate`]="{ item }">
              <span v-if="item.stamped_out === null">n/a</span>
              <span v-else>{{ item.stamped_out | momentDate }}<br>{{ item.stamped_out | momentTime }}</span>
              <span v-if="item.stamped_out_forced_at">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-bind="attrs" v-on="on">info</v-icon>
                  </template>
                  <div>
                    Forced Stamp-Out<br/>
                    {{ item.stamped_out_forced_at | momentDateTime }}<br/>
                    {{ item.stamped_out_forced_by_display_name }}
                  </div>
                </v-tooltip>
              </span>
            </template>

            <template v-slot:[`item.time`]="{ item }">
              {{ item.duration | momentSecondsToHours }}
            </template>

            <template v-slot:[`item.total`]="{ item }">
              {{ (item.totalDuration).toFixed(2) }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <div class="text-center">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn plain v-bind="attrs" v-on="on" v-if="!item.stamped_out">
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-btn
                        v-if="!item.stamped_out"
                        @click="doForceStampOut(item)"
                        text
                        class="icon-left"
                      >
                        <v-icon>mdi-timer-stop-outline</v-icon>
                        {{ t('force-stamp-out.edit') }}
                      </v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
          </v-data-table>

        </v-row>
      </v-container>
    </div>

    <v-dialog
      v-model="forceStampOut.visible"
      width="828"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ t('force-stamp-out.title') }}
        </v-card-title>
        <v-card-text>
          <div class="d-flex stamp-start">
            <p>{{ t('force-stamp-out.edit-start.label') }}</p>
            <v-switch
              v-model="forceStampOut.editStart"
              :label="forceStampOut.editStart ? t('force-stamp-out.edit-start.yes') : t('force-stamp-out.edit-start.no')"
              color="primary"
              hide-details
            ></v-switch>
          </div>
          <p v-if="forceStampOut.editStart"><b>{{ t('force-stamp-out.stamp-start-label') }}</b></p>
          <div v-if="forceStampOut.editStart" class="d-flex stamp-start-widget pb-4">
            <v-date-picker v-model="forceStampOut.start.date"/>
            <v-time-picker v-model="forceStampOut.start.time" format="24hr"/>
          </div>
          <p><b>{{ t('force-stamp-out.stamp-end-label') }}</b></p>
          <div class="d-flex stamp-end">
            <v-date-picker v-model="forceStampOut.date"/>
            <v-time-picker v-model="forceStampOut.time" format="24hr"/>
          </div> 
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="doForceStampOutCancel()"
            text
            :disabled="processingForcedStampout"
          >
            <v-icon>mdi-close</v-icon>
            {{ t('force-stamp-out.cancel') }}
          </v-btn>
          <v-btn
            @click="doForceStampOutComplete()"
            color="primary"
            elevation="0"
            :disabled="processingForcedStampout"
            :loading="processingForcedStampout"
          >
            <v-icon>mdi-check</v-icon>
            {{ t('force-stamp-out.apply') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import { EventBus } from '@/event-bus'
import axios from 'axios'
import moment from 'moment'
import toolbar from '@/components/layouts/Navigation'
import loading from '@/components/layouts/Loading'
import AppFilterDatePicker from '@/components/vuetify/AppFilterDatePicker'
import UserFilterWidget from '@/components/filter-components/UserFilterWidget'
import AppFilterSearchField from '@/components/vuetify/AppFilterSearchField'
import CustomerMapSelect from '@/components/CustomerMapSelect'
import MapDisplay from '@/components/MapDisplay'

export default {
  name: 'TimestampsIndex',
  components: {
    MapDisplay,
    CustomerMapSelect,
    toolbar,
    loading,
    AppFilterDatePicker,
    UserFilterWidget,
    AppFilterSearchField
  },
  data() {
    return {
      loading: false,
      dataLoaded: false,
      dialog: false,
      searchTerm: '',
      searchTermCustomer: '',
      totalTimelogs: 0,
      timelogItems: [],
      dateStart: moment().subtract(28, 'days').format('YYYY-MM-DD'),
      dateEnd: moment().format('YYYY-MM-DD'),
      users: [],
      customers: [],
      selectedUser: null,
      selectedCustomer: null,
      filterChanged: false,
      options: {
        itemsPerPage: 25
      },
      initialSortBy: '',
      initialSortDesc: false,
      message: null,
      isDownloadingCSV: false,
      error: null,
      httpCancelHandler: null,
      bar: false,
      forceStampOut: {
        visible: false,
        editStart: false,
        id: null,
        date: null,
        time: null,
        start: {
          date: null,
          time: null,
        }
      },
      processingForcedStampout: false
    }
  },
  mounted() {
    EventBus.$on('filter-date-changed', function (msg) {
      if (msg.id === 'dateStart') {
        this.dateStart = msg.newDate
      } else {
        this.dateEnd = msg.newDate
      }
      this.filterChanged = true
    }.bind(this))
  },
  beforeDestroy() {
    EventBus.$off('filter-date-changed')
    EventBus.$off('http-error')
  },
  computed: {
    readyToDownload: function () {
      return !this.isDownloadingCSV
    },
    headers() {
      return [
        {
          text: this.t('table.date'),
          align: 'start',
          sortable: false,
          value: 'date'
        },
        {
          text: this.t('table.number'),
          value: 'number',
          sortable: false,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.staff_number'),
          value: 'staff_number',
          sortable: false,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.lastname'),
          value: 'lastname',
          sortable: false,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.firstname'),
          value: 'firstname',
          sortable: false,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.object'),
          value: 'object',
          sortable: false,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.stamp-in-dist'),
          value: 'stampin',
          sortable: false,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.stamp-in-date'),
          value: 'stampindate',
          sortable: false
        },
        {
          text: this.t('table.stamp-out-dist'),
          value: 'stampout',
          sortable: false,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.stamp-out-date'),
          value: 'stampoutdate',
          sortable: false,
        },
        {
          text: this.t('table.time'),
          value: 'time',
          sortable: false,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.total'),
          value: 'total',
          sortable: false,
          cellClass: 'text-middle'
        },
        {
          value: 'actions',
          sortable: false,
          width: 50
        }
      ]
    }
  },
  watch: {
    selectedUser: function (val) {
      this.filterChanged = true
    },
    searchTermCustomer: function (val) {
      this.loading = false
      if (val !== '') {
        this.getSearchResultCustomer(encodeURIComponent(val))
      }
    },
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    }
  },
  filters: {
    momentDate: function (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    momentTime: function (date) {
      return moment(date).format('HH:mm:ss')
    },
    momentDateTime: function (date) {
      return moment(date).format('DD.MM.YYYY HH:mm')
    },
    momentWeekday: function (date) {
      return moment(date).locale('de').format('dddd')
    },
    momentSecondsToHours: function (seconds) {
      return (Math.round((seconds / 3600) * 100) / 100).toFixed(2)
    }
  },
  methods: {
    t: function (key) {
      return this.$t('timelog-stamp.list.' + key);
    },
    reload() {
      this.$nextTick(function () {
        this.timelogItems = []
        this.filterChanged = false
        this.getDataFromApi()
      }.bind(this))
    },
    getDataFromApi() {
      this.loading = true
      this.dataLoaded = false
      this.apiCall().then(data => {
        this.timelogItems = data.timelogItems
        this.totalTimelogs = data.total
        this.loading = false
        this.dataLoaded = true
      })
    },
    apiCall() {
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options

        let url = this.prepareUrl(sortBy, sortDesc, page, itemsPerPage)

        HTTP.get('timestamps' + url).then(function (response) {
          let timelogItems = response.data.data
          let total = response.data.total

          resolve({
            timelogItems,
            total,
          })
        }.bind(this)).catch(function (error) {
          if (!error.status) {
            this.error = true
          }
        }.bind(this))
      })
    },
    applyFilter() {
      this.reload()
    },
    getSearchResultCustomer(val) {
      if (this.cancelHTTP) {
        this.cancelHTTP()
      }
      HTTP.get('customers?q=' + val, {
        cancelToken: new axios.CancelToken(function executor(c) {
          this.cancelHTTP = c
        }.bind(this))
      }).then(function (response) {
        this.customers = response.data.data
        this.error = false
        this.dataLoaded = true
      }.bind(this)).catch(function (error) {
        this.errorCancel = error
      }.bind(this))
    },
    setCustomer(id, name) {
      this.selectedCustomer = id
      this.customers = []
      this.searchTermCustomer = name
      this.filterChanged = true
    },
    clearSearchCustomer() {
      this.selectedCustomer = null
      this.customers = []
      this.searchTermCustomer = ''
      this.filterChanged = true
    },
    saveAsCSV() {
      this.isDownloadingCSV = true

      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      let url = this.prepareUrl(sortBy, sortDesc, page, itemsPerPage)

      HTTP.post('dataexport/excel/timestamps' + url, {}, { responseType: 'arraybuffer' }).then(function (response) {
        let name = 'Zeitstempeln'
        let headers = response.headers
        let blob = new Blob([response.data], {type: headers['content-type']})
        let link = document.createElement('a')
        link.style = 'display: none'
        link.href = window.URL.createObjectURL(blob)
        link.download = 'TimeStamps_' + moment().format('DDMMYYYY-HHmm') + '_' + name.replace(' ', '-') + '.xlsx'
        document.body.appendChild(link)
        link.click()
        setTimeout(function () {
          document.body.removeChild(link)
          window.URL.revokeObjectURL(blob)
        }, 100)
        this.isDownloadingCSV = false
      }.bind(this))
    },
    prepareUrl(sortBy, sortDesc, page, itemsPerPage) {
      let queryStringParts = []
      if (this.dateStart) {
        queryStringParts.push('after=' + encodeURIComponent(moment.utc(this.dateStart).toISOString()))
      }
      if (this.dateEnd) {
        queryStringParts.push('before=' + encodeURIComponent(moment.utc(this.dateEnd).toISOString()))
      }
      if (this.selectedUser) {
        queryStringParts.push('q=' + this.selectedUser.id)
      }
      if (this.selectedCustomer) {
        queryStringParts.push('c=' + this.selectedCustomer)
      }
      queryStringParts.push('sortBy=' + sortBy[0])
      let sort = sortDesc[0] ? 'desc' : 'asc'
      queryStringParts.push('sortDesc=' + sort)
      page = page ? page : 1
      queryStringParts.push('page=' + page)
      queryStringParts.push('itemsPerPage=' + itemsPerPage)
      return '?' + queryStringParts.join('&')
    },
    async doForceStampOut(timestamp) {
      this.forceStampOut.id = timestamp.id
      this.forceStampOut.start.date = moment(timestamp.stamped_in).format('YYYY-MM-DD')
      this.forceStampOut.start.time = moment(timestamp.stamped_in).format('HH:mm')
      this.forceStampOut.visible = true
    },
    async doForceStampOutComplete() {
      if (!this.forceStampOut.date || !this.forceStampOut.time) {
        this.$root.infoNotification.showMessage(this.t('force-stamp-out.validation.end-time-not-set'))
        return
      }

      if (moment(this.forceStampOut.start.date).add(this.forceStampOut.start.time) >= moment(this.forceStampOut.date).add(this.forceStampOut.time)) {
        this.$root.infoNotification.showMessage(this.t('force-stamp-out.validation.start-after-end-time'))
        return
      }

      this.processingForcedStampout = true

      let timestamp = moment(this.forceStampOut.date).add(this.forceStampOut.time).utc()
      let startTimestamp = this.forceStampOut.editStart ? moment(this.forceStampOut.start.date).add(this.forceStampOut.start.time).utc() : null
      let data = {
        timestamp: timestamp
      }
      if (startTimestamp) {
        data['startTimestamp'] = startTimestamp
      }
      await HTTP.post('timestamp/force-stampout/' + this.forceStampOut.id, data).then(function () {
        this.doForceStampOutCancel()
        this.reload()
      }.bind(this)).catch(function (error) {
        if (error.response) {
          this.$root.infoNotification.showMessage(error.response)
        } else {
          this.$root.infoNotification.showMessage(this.t('create.unknown-error') + error)
        }  
      }.bind(this))
    },
    doForceStampOutCancel() {
      this.forceStampOut.id = null
      this.forceStampOut.date = null
      this.forceStampOut.time = null
      this.forceStampOut.visible = false
      this.forceStampOut.editStart = false
    }
  }
}
</script>

<style scoped lang="scss">
.radius-value-wrapper {
  display: flex;

  .radius-map {
    margin-left: auto;
    position: relative;
    top: -2px;
  }

  &.radius-alert {

    .radius-value {
      color: var(--v-error-base);
      font-weight: 600;
    }
  }
}

.stamp-start {
  padding-bottom: 4px;
  margin-bottom: 16px;
  border-bottom: 1px solid #DDD;

  p {
    margin-bottom: 0;
    line-height: 32px;
    padding-right: 16px;
  }
}
</style>
