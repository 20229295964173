var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"has-hero-container"},[_c('div',[_c('toolbar',{attrs:{"dataLoaded":true}},[_vm._v(_vm._s(_vm.t('title')))]),_c('div',{staticClass:"sp-hero sp-bg-light-blue"},[_c('v-container',{staticClass:"hero-filter",attrs:{"fluid":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.applyFilter();}}},[(_vm.filterStore.hasChanges)?_c('app-filter-apply-button',{on:{"click":function($event){return _vm.applyFilter();}}}):_vm._e(),(!_vm.filterStore.hasChanges)?_c('app-refresh-button',{on:{"click":function($event){return _vm.applyFilter();}}}):_vm._e(),_c('v-slide-group',{attrs:{"show-arrows":""}},[_c('v-slide-item',[_c('div',[_c('div',{staticClass:"filter-item"},[_c('v-text-field',{attrs:{"label":_vm.t('year'),"filled":""},model:{value:(_vm.filterStore.filter.year),callback:function ($$v) {_vm.$set(_vm.filterStore.filter, "year", $$v)},expression:"filterStore.filter.year"}})],1)])]),_c('v-slide-item',[_c('div',{staticClass:"filter-item filter-search-list"},[_c('user-filter-widget',{attrs:{"includeInactive":true},model:{value:(_vm.filterStore.filter.selectedUser),callback:function ($$v) {_vm.$set(_vm.filterStore.filter, "selectedUser", $$v)},expression:"filterStore.filter.selectedUser"}})],1)])],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"data-table-toolbar"},[_c('v-spacer')],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"calculate-widths":"","headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"sort-by":_vm.filterStore.table.sortBy,"sort-desc":_vm.filterStore.table.sortDesc,"page":_vm.filterStore.table.currentPage,"items-per-page":_vm.filterStore.table.itemsPerPage,"footer-props":{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            'items-per-page-options': [25, 50, 100, 200],
            pageText: '{0}-{1} von {2}',
            'items-per-page-text': _vm.$t('components.data-table.items-per-page')
          }},on:{"update:options":function($event){_vm.options=$event},"update:sortBy":function($event){return _vm.$set(_vm.filterStore.table, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.filterStore.table, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.filterStore.table, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.filterStore.table, "sortDesc", $event)},"update:page":function($event){return _vm.$set(_vm.filterStore.table, "currentPage", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.filterStore.table, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.filterStore.table, "itemsPerPage", $event)}},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"ellipsis"},[_vm._v(_vm._s(item.user_display_name))])]}},{key:"item.vacation_carry_over",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatMinutesAsHours(item.vacation_carry_over_minutes)))])]}},{key:"item.vacation_credit",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatMinutesAsHours(item.vacation_credit_minutes)))])]}},{key:"item.vacation_total_available",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatMinutesAsHours(item.vacation_carry_over_minutes + item.vacation_credit_minutes)))])]}},{key:"item.vacation_used",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatMinutesAsHours(item.vacation_used_minutes)))])]}},{key:"item.vacation_balance",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatMinutesAsHours(item.vacation_carry_over_minutes + item.vacation_credit_minutes - item.vacation_used_minutes)))])]}},{key:"item.flextime_carry_over",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatMinutesAsHours(item.flextime_carry_over_minutes)))])]}},{key:"item.flextime_bookings",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatMinutesAsHours(item.flextime_bookings_minutes)))])]}},{key:"item.flextime_target",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatMinutesAsHours(item.flextime_target_minutes)))])]}},{key:"item.flextime_actual",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatMinutesAsHours(item.flextime_actual_minutes)))])]}},{key:"item.flextime_balance",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatMinutesAsHours(item.flextime_carry_over_minutes + item.flextime_bookings_minutes + item.flextime_actual_minutes - item.flextime_target_minutes)))])]}},{key:"item.edit",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{staticClass:"edit-row-table",attrs:{"to":{ path: '/time-management/balances/' + item.user_id }}},[_c('v-icon',{staticClass:"edit-row-button",attrs:{"small":""}},[_vm._v(" edit ")])],1)]}}],null,true)},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"slot":"progress","color":"primary","indeterminate":""},slot:"progress"})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }