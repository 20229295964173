<template>
  <div>

    <toolbar :dataLoaded="true">
      <span v-if="!isFinished">{{ t('edit.title') }}</span>
      <span v-else>{{ t('edit.title-readonly') }}</span>
    </toolbar>

    <v-container fluid class="edit-screen has-delete-button">

      <loading :error="errorLoading" :dataLoaded="dataLoaded"></loading>

      <v-form ref="form">

        <v-row v-if="dataLoaded">

          <v-col class="content-area" cols="12" sm="12" md="8"></v-col>

          <v-col class="meta-area" cols="12" sm="12" md="4">

            <v-dialog
                v-model="dialog"
                content-class="delete-dialog"
                width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    elevation="0"
                    color="error"
                    class="delete-enrollment icon-left"
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon>mdi-delete</v-icon>
                  {{ t('delete.delete') }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  {{ t('delete.delete-question') }}
                </v-card-title>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="primary"
                      text
                      @click="dialog = false"
                  >
                    <v-icon>mdi-close</v-icon>
                    {{ t('delete.cancel') }}
                  </v-btn>
                  <v-btn
                      color="primary"
                      elevation="0"
                      class="delete-task"
                      @click="deleteItem()"
                  >
                    <v-icon>mdi-check</v-icon>
                    {{ t('delete.confirm') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>

        </v-row>

        <v-row v-if="dataLoaded">

          <!-- Left Column -->
          <v-col class="content-area" cols="12" sm="12" md="8">

            <v-card
                elevation="0"
            >
              <v-card-title>{{ t('details.group-title') }}</v-card-title>
              <v-card-text>

                <v-text-field
                    :label="t('details.title') + '*'"
                    counter="120"
                    v-model="enrollmentData.title"
                    filled
                    :disabled="isFinished"
                    :rules="[
								() => !!enrollmentData.title || $t('validation.required.title'),
								() => (enrollmentData.title && enrollmentData.title.length <= 120) || $t('validation.length.title')
							]"
                ></v-text-field>

                <v-textarea
                    :label="t('details.description')"
                    class="no-validation"
                    v-model="enrollmentData.description"
                    filled
                    :disabled="editDisabled || isFinished"
                ></v-textarea>

                <div class="custom-label">{{ t('details.time-range') }}</div>

                <div class="survey-start">
                  <app-date-time-picker
                      :label="t('details.startdate') + '*'"
                      v-model="enrollmentData.start_date"
                      date-format="dd.MM.yyyy"
                      :id="'startDate'"
                      :clearable="false"
                      :disabled="editDisabled || isFinished"
                  ></app-date-time-picker>
                  <span v-if="isSubmitted && !enrollmentData.start_date"
                        class="md-error">{{ $t('validation.required.start-date') }}</span>
                </div>

                <div class="survey-end">
                  <app-date-time-picker
                      :label="t('details.enddate') + '*'"
                      v-model="enrollmentData.end_date"
                      date-format="dd.MM.yyyy"
                      :id="'endDate'"
                      :clearable="false"
                      :disabled="isFinished"
                  ></app-date-time-picker>
                  <span v-if="isSubmitted && !enrollmentData.end_date"
                        class="md-error">{{ $t('validation.required.end-date') }}</span>
                </div>

              </v-card-text>
            </v-card>

          </v-col>

          <!-- Right Column -->
          <v-col class="meta-area" cols="12" sm="12" md="4">

            <v-card
                elevation="0"
            >
              <v-card-title>{{ $t('components.permissiongroup-select.label') + '*' }}</v-card-title>
              <v-card-text>

                <v-select
                    :title="$t('components.permissiongroup-select.select')"
                    :label="$t('components.permissiongroup-select.select')"
                    v-model="enrollmentData.assigned_permission_groups"
                    :items="permissionGroups"
                    multiple
                    item-text="name"
                    item-value="id"
                    :rules="[() => enrollmentData.assigned_permission_groups.length > 0 || $t('validation.required.permission-groups')]"
                    :disabled="editDisabled || isFinished"
                >
                  <template slot="item" slot-scope="data">
                    {{ data.item.name }} ({{ data.item.user_count }})
                  </template>
                </v-select>

              </v-card-text>
            </v-card>

            <v-card
                elevation="0"
                class="sp-mar-top-3"
            >
              <v-card-title>{{ t('push.group-title') }}</v-card-title>
              <v-card-text>

                <v-switch
                    v-model="enrollmentData.push_on_start"
                    inset
                    :label="enrollmentData.push_on_start ? t('push.yes') : t('push.no')"
                    :disabled="editDisabled || isFinished"
                ></v-switch>

              </v-card-text>
            </v-card>

          </v-col>

        </v-row>

        <v-row v-if="dataLoaded">

          <!-- Left Column -->
          <v-col class="content-area" cols="12" sm="12" md="8">

            <v-card
                elevation="0"
            >
              <v-card-title v-if="!isEditQuestion">{{ t('add-question.group-title') }}</v-card-title>
              <v-card-title v-else>{{ t('add-question.group-title-edit') }}</v-card-title>
              <v-card-text>

                <v-select
                    :title="t('add-question.question-type') + '*'"
                    :label="t('add-question.question-type') + '*'"
                    v-model="selectedQuestionType"
                    v-on:change="onselectedQuestionTypeChange"
                    :items="questionTypes"
                    item-text="name"
                    item-value="id"
                ></v-select>

                <div class="question-description">{{ selectedQuestionTypeDescription }}</div>

                <v-text-field
                    :label="t('add-question.question-title') + '*'"
                    :title="t('add-question.question-title') + '*'"
                    v-model="selectedQuestionTitle"
                    filled
                ></v-text-field>

                <v-textarea
                    :label="t('add-question.question-description')"
                    :title="t('add-question.question-description')"
                    class="no-validation"
                    v-model="selectedQuestionDescription"
                    filled
                ></v-textarea>

                <div class="sp-mar-bot-3" v-if="[2, 3, 5].includes(selectedQuestionType)">
                  <div v-if="!editDisabled" class="custom-label">{{ t('add-question.add-choice') + '*' }}</div>
                  <div v-if="editDisabled" class="custom-label">{{ t('add-question.choices') }}</div>
                  <div class="sp-mar-bot-1 selected-options-list">
                    <div v-for="(option, index) in selectedOptions" :key="index">
                      <md-button v-if="!editDisabled" @click.native="removeOption(index)"
                                 class="sp-text-blue sp-mar-r-0" :title="t('add-question.remove')">
                        <md-icon class="sp-text-blue">do_not_disturb_on</md-icon>
                      </md-button>
                      <md-icon v-if="editDisabled && [2].includes(selectedQuestionType)"
                               class="sp-float-left disabled-icon">radio_button_checked
                      </md-icon>
                      <md-icon v-if="editDisabled && [3].includes(selectedQuestionType)"
                               class="sp-float-left disabled-icon">check_box
                      </md-icon>
                      <span>{{ option }}</span>
                      <md-radio name="exitOption" v-if="[5].includes(selectedQuestionType)" v-model="exitOption"
                                :md-value="index">Exitoption
                      </md-radio>
                    </div>
                  </div>
                  <div v-if="!editDisabled" class="add-option">
                    <md-button @click.native="addOption()" class="sp-text-blue sp-mar-r-0"
                               :title="t('add-question.add')">
                      <md-icon class="sp-text-blue">add_circle</md-icon>
                    </md-button>
                    <md-input-container>
                      <md-input v-model="selectedOption" name="questionOption"></md-input>
                    </md-input-container>
                  </div>
                </div>

                <div class="button-wrapper" v-if="selectedQuestionType && selectedQuestionTitle && validOptions">

                  <v-btn
                      v-if="!editDisabled && !isEditQuestion"
                      color="primary"
                      elevation="0"
                      outlined
                      class="sp-float-right icon-left"
                      @click="addQuestion()"
                  >
                    <v-icon>mdi-plus</v-icon>
                    {{ t('add-question.add') }}
                  </v-btn>

                  <v-btn
                      v-if="isEditQuestion"
                      color="primary"
                      text
                      class="icon-left"
                      @click="cancelUpdateQuestion()"
                  >
                    <v-icon>mdi-close</v-icon>
                    {{ t('add-question.cancel') }}
                  </v-btn>

                  <v-btn
                      v-if="!editDisabled && isEditQuestion"
                      color="primary"
                      elevation="0"
                      outlined
                      class="sp-float-right icon-left"
                      @click="updateQuestion()"
                  >
                    <v-icon>mdi-check</v-icon>
                    {{ t('add-question.update') }}
                  </v-btn>

                </div>

              </v-card-text>
            </v-card>

          </v-col>

          <!-- Right Column -->
          <v-col class="meta-area" cols="12" sm="12" md="4">

            <v-card
                elevation="0"
            >
              <v-card-title>{{ t('add-question.components') + '*' }}</v-card-title>
              <v-card-text class="drag-wrapper">

                <draggable element="div" v-model="questions" :sort="true" :disabled="editDisabled">
                  <div class="drag-element" v-for="(question, index) in questions" :key="index">
                    <div class="sp-float-left question-label">
                      <md-icon v-if="[1].includes(question.type)">star_rate</md-icon>
                      <md-icon v-if="[2].includes(question.type)">radio_button_checked</md-icon>
                      <md-icon v-if="[3].includes(question.type)">check_box</md-icon>
                      <md-icon v-if="[4].includes(question.type)">message</md-icon>
                      <md-icon v-if="[5].includes(question.type)">exit_to_app</md-icon>
                      {{ question.title }}
                    </div>
                    <md-icon v-if="!editDisabled" class="sp-text-blue sp-float-right cta-icon"
                             @click.native="editQuestion(question, index)" :title="t('add-question.edit')">edit
                    </md-icon>
                    <md-icon v-if="editDisabled" class="sp-text-blue sp-float-right cta-icon"
                             @click.native="editQuestion(question, index)" :title="t('add-question.view')">visibility
                    </md-icon>
                    <md-icon v-if="!editDisabled" class="sp-text-blue sp-float-right cta-icon"
                             @click.native="deleteQuestion(question, index)" :title="t('add-question.delete')">delete
                    </md-icon>
                    <div class="sp-clearfix"></div>
                  </div>
                </draggable>
                <div v-if="isEditQuestion" class="disable-drag"></div>
                <span v-if="isSubmitted && !data.questions"
                      class="md-error">{{ $t('validation.required.question-components') }}</span>

              </v-card-text>
            </v-card>

          </v-col>

        </v-row>

      </v-form>

    </v-container>

    <v-snackbar
        ref="snackbar"
        v-model="snackbar"
        timeout="4000"
    >
      {{ errorMessage }}
    </v-snackbar>

    <transition name="fade">
      <div v-if="dataLoaded" class="bottom-navigation modal-nav">
        <v-container fluid>
          <v-row>
            <cancel-button-dialog
                ref="cancelButtonDialog"
                :go-to-name="indexRouteName"
                :data-edited="dataEdited"
                @cancel="doCancel">
              >
            </cancel-button-dialog>
            <v-spacer></v-spacer>
            <v-btn
                v-if="enrollmentData.status === 'PUBLISHED' && !editDisabled"
                color="primary"
                elevation="0"
                outlined
                class="icon-left"
                @click="onSubmit(true, false)"
            >
              <v-icon>mdi-email-open</v-icon>
              {{ t('switch-to-draft') }}
            </v-btn>
            <v-btn
                v-if="!isFinished"
                color="primary"
                elevation="0"
                class="icon-left"
                @click="onSubmit(false, false)"
            >
              <v-icon>mdi-check</v-icon>
              {{ t('update') }}
            </v-btn>
            <v-btn
                v-if="enrollmentData.status === 'DRAFT' && !editDisabled"
                color="primary"
                elevation="0"
                class="icon-left"
                @click="onSubmit(false, true)"
            >
              <v-icon>mdi-check</v-icon>
              {{ t('update-and-publish') }}
            </v-btn>
          </v-row>
        </v-container>
      </div>
    </transition>

  </div>
</template>
<script>
import {HTTP} from './../auth'
import {EventBus} from './../event-bus'
import loading from '../components/layouts/Loading.vue'
import toolbar from '../components/layouts/Navigation.vue'
import AppDateTimePicker from '../components/vuetify/AppDateTimePicker'
import moment from 'moment'
import draggable from 'vuedraggable'
import CancelButtonDialog from "../components/layouts/CancelButtonDialog"

export default {
  name: 'EnrollmentsEdit',
  components: {
    loading,
    toolbar,
    AppDateTimePicker,
    draggable,
    CancelButtonDialog
  },
  data() {
    return {
      editDisabled: false,
      permissionGroups: [],
      processing: false,
      data: {},
      dataLoaded: false,
      errorLoading: false,
      error: null,
      errorMessage: 'Die Daten konnten nicht gespeichert werden, prüfen Sie Ihre Verbindung und versuchen Sie es nochmal.',
      cancelHTTP: null,
      errorCancel: null,
      enrollmentData: {},
      questions: [],
      selectedQuestionType: null,
      selectedQuestionTypeDescription: '',
      selectedQuestionTitle: null,
      selectedQuestionDescription: null,
      selectedOption: null,
      selectedOptions: [],
      exitOption: null,
      isEditQuestion: false,
      editQuestionIndex: null,
      isSubmitted: false,
      isEditing: true,
      isFinished: false,
      initalStartDate: null,
      initialPushOnStart: null,
      pushOnStart: false,
      config: {
        headers: {'content-type': 'application/json'}
      },
      snackbar: false,
      dialog: false,
      dataEdited: false,
      cancelConfirmed: false,
      indexRouteName: 'Enrollments'
    }
  },
  emits: ['cancel'],
  computed: {
    validOptions: function () {
      return [1, 4].includes(this.selectedQuestionType) ||
          ([2, 3].includes(this.selectedQuestionType) && this.selectedOptions.length > 0) ||
          ([5].includes(this.selectedQuestionType) && this.selectedOptions.length > 0 && this.exitOption !== null)
    },
    questionTypes() {
      return [
        {
          id: 1,
          name: this.t('add-question.types.rating.title'),
          description: this.t('add-question.types.rating.description')
        },
        {
          id: 2,
          name: this.t('add-question.types.single-choice.title'),
          description: this.t('add-question.types.single-choice.description')
        },
        {
          id: 3,
          name: this.t('add-question.types.multiple-choice.title'),
          description: this.t('add-question.types.multiple-choice.description')
        },
        {
          id: 4,
          name: this.t('add-question.types.text-input.title'),
          description: this.t('add-question.types.text-input.description')
        },
        {
          id: 5,
          name: this.t('add-question.types.exit-option.title'),
          description: this.t('add-question.types.exit-option.description')
        }
      ]
    }
  },
  created() {
    this.itemId = this.$route.params.id
    EventBus.$on('reload-item', function () {
      this.getEnrollment()
    }.bind(this))
    this.getPermissionGroups()
  },
  beforeDestroy() {
    EventBus.$off('reload-item')
  },
  methods: {
    t: function (key) {
      return this.$t('enrollments.detail.' + key);
    },
    doCancel() {
      this.$emit('cancel');
    },
    // Watch for changes if data edited
    fireWatcherForDataChange() {
      this.$watch(
          (vm) => [vm.enrollmentData, vm.enrollmentData.assigned_permission_groups, vm.questions],
          (newVal, oldVal) => {
            if (oldVal != undefined) {
              this.dataEdited = true
            }
          },
          {
            immediate: true,
            deep: true,
          }
      );
    },
    moment: function () {
      return moment();
    },
    getPermissionGroups() {
      HTTP.get('permission-groups?sortBy[]=name').then(function (response) {
        this.permissionGroups = response.data.data
        this.getEnrollment()
      }.bind(this)).catch(function (error) {
        this.error = error
        this.openSnackbar()
      }.bind(this))
    },
    getEnrollment() {
      HTTP.get('enrollments/' + this.itemId).then(function (response) {
        this.enrollmentData = response.data
        this.editDisabled = this.enrollmentData.status === 'PUBLISHED' && moment().isAfter(this.enrollmentData.start_date)
        this.isFinished = this.enrollmentData.status === 'PUBLISHED' && moment().isAfter(this.enrollmentData.end_date)
        this.questions = this.enrollmentData.questions
        this.questions.forEach((element) => {
          element.options = JSON.parse(element.options)
        })
        this.enrollmentData.anonymized = !!this.enrollmentData.anonymized
        this.initalStartDate = this.enrollmentData.start_date
        this.enrollmentData.push_on_start = !!this.enrollmentData.push_on_start
        this.initialPushOnStart = this.enrollmentData.push_on_start

        const tempPermGroupsIds = []
        this.enrollmentData.assigned_permission_groups.forEach((element) => {
          tempPermGroupsIds.push(element.permission_group_id)
        })
        this.enrollmentData.assigned_permission_groups = tempPermGroupsIds

        this.dataLoaded = true
        this.fireWatcherForDataChange()
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
          this.dataLoaded = false
        }
      }.bind(this))
    },
    onSubmit(changeToDraft, changeToPublished) {

      this.isSubmitted = true

      if (this.$refs.form.validate() && this.enrollmentData.start_date && this.enrollmentData.end_date && this.questions.length > 0) {

        if (this.processing === true) {
          return
        }
        this.processing = true
        this.dataLoaded = false

        if (changeToDraft) {
          this.enrollmentData.status = 'DRAFT'
        }

        if (changeToPublished) {
          this.enrollmentData.status = 'PUBLISHED'
        }

        this.reorderQuestions()

        const json = JSON.stringify({
          enrollmentData: this.enrollmentData
        })

        HTTP.post('enrollments/' + this.enrollmentData.id, {'data': json})
            .then(function (response) {
              this.data = response.data

              if (changeToDraft) {
                // delete cron job if exists
                this.abortCron(response.data.id, 'enrollments')
              }

              if (changeToPublished) {
                // add cronjob or push immediately if push on start enabled
                if (this.enrollmentData.push_on_start) {

                  if (this.isRunning(this.data)) {

                    this.push(response.data.id, 'enrollments', 'request')

                  } else {

                    var cronDataNew = {}
                    cronDataNew.id = response.data.id
                    cronDataNew.type = 'enrollments'
                    cronDataNew.publish_date = this.enrollmentData.start_date

                    HTTP.post('cron', cronDataNew, this.config)
                        .then(function (response) {
                          //
                        })
                        .catch(function (error) {
                          console.log(error)
                        }.bind(this))
                  }
                }
              }

              // if status == 'PUBLISHED' and start date of push on start changed
              if (!changeToDraft && !changeToPublished && this.enrollmentData.status === 'PUBLISHED' && (this.initalStartDate != this.enrollmentData.start_date || this.initialPushOnStart != this.enrollmentData.push_on_start)) {

                if (this.enrollmentData.push_on_start) {

                  // update cronjob if start_date > now()
                  if (moment().isBefore(this.data.start_date)) {

                    var cronDataUpdate = {}
                    cronDataUpdate.id = response.data.id
                    cronDataUpdate.type = 'enrollments'
                    cronDataUpdate.publish_date = this.enrollmentData.start_date

                    HTTP.post('cron', cronDataUpdate, this.config)
                        .then(function (response) {
                          //
                        })
                        .catch(function (error) {
                          console.log(error)
                        }.bind(this))
                  }

                  // delete cronjob and push immediately if start_date <= now() and survey not finished
                  if (this.isRunning(this.data)) {
                    this.abortCron(response.data.id, 'enrollments')
                    this.push(response.data.id, 'enrollments', 'request')
                  }
                }
              }

              this.dataEdited = false
              this.processing = false
              this.$router.push({name: this.indexRouteName})

            }.bind(this))
            .catch(function (error) {
              if (error.response.data.errors) {
                this.message = error.response.data.message
              }
              this.errorMessage = 'Die Daten konnten nicht gespeichert werden, prüfen Sie Ihre Verbindung und versuchen Sie es nochmal.'
              this.openSnackbar()
              console.log(error)
              this.processing = false
            }.bind(this))
      }
    },
    addOption() {
      if (this.selectedOption) {
        this.selectedOptions.push(this.selectedOption)
        this.selectedOption = null
      }
    },
    removeOption(index) {
      this.selectedOptions.splice(index, 1)
    },
    addQuestion() {
      // in case user forgets to add the last option
      if (this.selectedOption != null) {
        this.addOption()
      }
      this.questions.push({
        order: this.questions.length + 1,
        type: this.selectedQuestionType,
        title: this.selectedQuestionTitle,
        description: this.selectedQuestionDescription,
        options: [2, 3, 5].includes(this.selectedQuestionType) ? this.selectedOptions : null,
        exitoption: [5].includes(this.selectedQuestionType) ? this.exitOption : null
      })
      this.resetSelectedQuestion()
    },
    editQuestion(question, index) {
      this.selectedQuestionType = question.type
      this.selectedQuestionTypeDescription = this.questionTypes.filter(type => type.id === question.type)[0].description
      this.selectedQuestionTitle = question.title
      this.selectedQuestionDescription = question.description
      this.selectedOptions = question.options ? question.options : []
      this.exitOption = [5].includes(question.type) ? question.exitoption : null
      this.isEditQuestion = true
      this.editQuestionIndex = index
    },
    updateQuestion() {
      // in case user forgets to add the last option
      if (this.selectedOption != null) {
        this.addOption()
      }
      let id = this.questions[this.editQuestionIndex].id
      let order = this.questions[this.editQuestionIndex].order
      this.questions[this.editQuestionIndex] = {
        id: id,
        order: order,
        type: this.selectedQuestionType,
        title: this.selectedQuestionTitle,
        description: this.selectedQuestionDescription,
        options: [2, 3, 5].includes(this.selectedQuestionType) ? this.selectedOptions : null,
        exitoption: [5].includes(this.selectedQuestionType) ? this.exitOption : null
      }
      this.cancelUpdateQuestion()
    },
    cancelUpdateQuestion() {
      this.resetSelectedQuestion()
      this.isEditQuestion = false
      this.editQuestionIndex = null
    },
    deleteQuestion(question, index) {
      this.questions.splice(index, 1)
    },
    reorderQuestions() {
      let order = 1
      this.questions.forEach((element) => {
        element.order = order
        order++
      })
    },
    resetSelectedQuestion() {
      this.selectedQuestionType = null
      this.selectedQuestionTypeDescription = ''
      this.selectedQuestionTitle = null
      this.selectedQuestionDescription = null
      this.selectedOption = null
      this.selectedOptions = []
      this.exitOption = null
    },
    onselectedQuestionTypeChange(event) {
      this.selectedQuestionTypeDescription = this.questionTypes.filter(type => type.id === event)[0].description
      this.selectedOptions = []
    },
    deleteItem() {
      HTTP.delete('enrollments/' + this.enrollmentData.id, this.enrollmentData)
          .then(function (response) {
            // delete pending cron job if exists
            this.abortCron(this.enrollmentData.id, 'enrollments')
            this.$router.push({name: this.indexRouteName})
          }.bind(this)).catch(function (error) {
        this.error = error
        this.openSnackbar()
      }.bind(this))
    },
    isRunning(survey) {
      return moment().isAfter(survey.start_date) && moment().isBefore(survey.end_date)
    },
    push(id, type, source) {
      let pushData = {}
      pushData.id = id
      pushData.type = type
      pushData.source = source

      HTTP.post('push', pushData, this.config)
          .then(function (response) {
            //
          })
          .catch(function (error) {
            console.log(error)
          }.bind(this))
    },
    abortCron(id, type) {
      let cronDataAbort = {}
      cronDataAbort.id = id
      cronDataAbort.type = type

      HTTP.post('abortcron', cronDataAbort, this.config)
          .then(function (response) {
            //
          })
          .catch(function (error) {
            console.log(error)
          }.bind(this))
    },
    openSnackbar() {
      setTimeout(() => {
        this.snackbar = true
      }, 2000)
    }
  }
}
</script>

<style lang="scss" scoped>

.custom-label {
  clear: both;
  padding: 16px 0;
  font-size: 16px;
}

.custom-label-inline {
  width: 50px;
  float: left;
  line-height: 52px;
  font-size: 16px;
}

.survey-start {
  float: left;
  width: calc(50% - 16px);

  .md-error {
    display: block;
  }
}

.survey-end {
  float: right;
  width: calc(50% - 16px);

  .md-error {
    display: block;
  }
}

.button-wrapper {
  padding-top: 16px;
}

.md-radio {
  width: 100%;
}

.delete-enrollment {
  margin: 0;
  float: right;
}

.add-option button,
.selected-options-list button {
  float: left;
  padding: 0;
  margin: 0;
  width: 36px;
  min-width: unset;
}

.selected-options-list span {
  display: block;
  line-height: 36px;
}

.add-option .md-input-container {
  width: calc(100% - 36px);
  padding: 0;
  margin: 0;
  min-height: unset;
}

.disable-drag {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}

.drag-element {
  display: block;
  padding-right: 10px;
  border: 0;
  cursor: grab;

  &.sortable-chosen {
    cursor: grabbing;
  }

  .cta-icon {
    cursor: pointer;
  }
}

.drag-element .md-icon,
.selected-options-list .disabled-icon {
  position: relative;
  top: -1px;
  margin-right: 4px;
  color: #979797;
}

.selected-options-list .disabled-icon {
  top: 5px;
}

.selected-options-list .md-radio {
  position: relative;
  top: -10px;
  margin-left: 36px;
}

.drag-element .question-label {
  line-height: 22px;
}

.question-description {
  padding: 0 12px 16px 12px;
  min-height: 32px;
  font-style: italic;
  color: #999;
}

</style>