<template>
  <div>
    <p v-show="!video" class="md-error">{{ $t('validation.required.video') }}</p>

    <input
        style="display:none;"
        :ref="'input-' + index"
        type="file"
        :name="'video-' + index"
        accept="video/*"
        @change="setBlockVideo($event)"
    />

    <span v-if="video === '' && !sizeExceeded" class="video-size-hint">
            <v-icon>mdi-information-outline</v-icon>
            {{ $t('components.blocks-editor.blocks.video.max-size') }}: {{ sizeLimitMb }}MB
        </span>
    <span v-if="sizeExceeded" class="video-size-error">
            <v-icon>mdi-alert-outline</v-icon>
            {{ $t('components.blocks-editor.blocks.video.size-exceeded') }} ({{ sizeLimitMb }}MB)
        </span>

    <div v-if="isUploading">
      <v-progress-linear slot="progress" color="primary" indeterminate/>
    </div>

    <div v-if="initialVideoBlockId && !isUploading && !video">
      <div class="video-wrapper">
        <video :src="initialVideoData" controls></video>
      </div>
    </div>

    <div v-if="video && !isUploading && video instanceof Object">
      <div class="video-wrapper">
        <video :src="tempVideoSrc(video)" controls></video>
      </div>
    </div>

    <div v-show="true" class="sp-float-right sp-mar-top-2">
      <v-btn
          elevation="0"
          color="primary"
          outlined
          class="icon-left"
          @click="showBlockVideoChooser()"
      >
        <v-icon>mdi-plus</v-icon>
        {{ $t('components.blocks-editor.blocks.video.upload') }}
      </v-btn>
    </div>
  </div>
</template>
<script>

import {HTTP} from "@/auth";
import {Buffer} from "buffer";

export default {
  name: "ContentEditorBlockVideo",
  props: {
    index: {},
    initialVideoBlockId: {},
    contentType: {
        default: 'news'
    }
  },
  emits: ['video-selected'],
  data() {
    return {
      sizeLimitMb: 150,
      initialVideoData: null,
      video: '',
      sizeExceeded: false,
      isUploading: false,
    }
  },
  async mounted() {
    if (this.initialVideoBlockId) {
      let response = await HTTP.get(this.contentType + '/image/' + this.initialVideoBlockId, {responseType: 'arraybuffer'})
      this.initialVideoData = 'data:video/mp4;base64,' + new Buffer(response.data, 'binary').toString('base64')
    }
  },
  computed: {
    videoFileInput() {
      return this.$refs['input-' + this.index]
    },
  },
  methods: {
    setBlockVideo(e) {
      const videoFile = e.target.files[0]
      if (videoFile.type.indexOf('video/') === -1) {
        alert('Bitte eine Videodatei auswählen')
        return
      }
      if (typeof FileReader === 'function') {
        this.isUploading = true
        const reader = new FileReader()
        reader.onload = (event) => {
          this.isUploading = false
          let sizeExceeded = videoFile.size > this.sizeLimitMb * 1024 * 1024
          if (sizeExceeded) {
            return
          }
          this.$emit('video-selected', videoFile)
          this.videoFileInput.value = null
          this.video = videoFile
        }
        reader.readAsDataURL(videoFile)
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    tempVideoSrc(video) {
      if (this.editorFocused) {
        return
      }
      return URL.createObjectURL(video)
    },
    showBlockVideoChooser() {
      this.videoFileInput.click()
    },
  }
}
</script>


<style scoped lang="scss">

</style>
