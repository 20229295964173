<template>
  <v-select
    v-model="selectedFilterStatus"
    :label="label"
    :items="filterStatus"
    item-text="name"
    item-value="slug"
    :multiple="multiple"
    :rules="rules"
    chips
    :dataCy="dataCy"
  ></v-select>
</template>
  
<script>
export default {
  name: "QCNotificationsStatusFilter",
  props: {
    dataCy: {
      default: ''
    },
    value: {
      type: [String, Array],
      default: () => [null]       
    },
    filterLabel: String,
    multiple: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    mode: {
      type: String,
      default: 'create'
    },
    status: {
      type: String,
      default: 'submitted'
    }
  },
  data() {
    return {
      selectedFilterStatus: this.value
    }
  },
  computed: {
    label() {
        return this.filterLabel && this.filterLabel !== '' ? this.filterLabel : this.$t('components.qc-notifications-filters.status-filter.label')
    },
    filterStatus() {
      let statusValues = []

      if (!this.status || this.status === 'submitted' || this.multiple) {
        statusValues.push(
          {
            name: this.$t('qc-notifications.data.status.submitted'),
            slug: 'submitted'
          }
        )
      }

      statusValues.push(
        {
          name: this.$t('qc-notifications.data.status.approved'),
          slug: 'approved'
        }
      )

      if (this.mode === 'edit' || this.multiple) {
        statusValues.push(
          {
            name: this.$t('qc-notifications.data.status.invoiced'),
            slug: 'invoiced'
          },
          {
            name: this.$t('qc-notifications.data.status.deleted'),
            slug: 'deleted'
          }
        )
      }

      return statusValues
    }
  },
  watch: {
    selectedFilterStatus: function (val) {
          this.$emit('input', val)
    },
    value: function(val) {
        this.selectedFilterStatus = val
    }
  }
}
</script>
