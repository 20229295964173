<template>
  <md-sidenav v-if="showMenu !== false && user && modules" class="md-left sp-sidenav md-fixed" ref="sidenavigation"
              :md-swipeable="true">

    <md-whiteframe md-tag="md-toolbar" md-elevation="0" class="sp-sidenav-toolbar">
      <md-layout md-column>
        <div class="sp-sidenav-logo">
          <router-link :to="{ path: '/dashboard' }"></router-link>
        </div>
      </md-layout>
    </md-whiteframe>


    <md-list>
      <!-- FOR LOOP-->

      <template v-for="(item, index) in activeModules" v-if="showModel(item) " >
        <timesheet-list-item :item="item" :active-modules="activeModules" :user="user" v-if="item.module_name == 'timesheet'"></timesheet-list-item>
        <chat-list-item  :item="item" :modules="modules" :user="user" v-else-if="item.module_name == 'chat' && user"></chat-list-item>
        <servappt-list-item  :item="item" :modules="modules" :user="user" v-else-if="item.module_name == 'servappt' && user"></servappt-list-item>
        <checklist-list-item :item="item" :modules="modules" :user="user" v-else-if="item.module_name == 'quality_report' && user" ></checklist-list-item>
        <survey-list-item :item="item" :modules="modules" :user="user" v-else-if="item.module_name == 'surveys' && user  && user.role === 'admin'" ></survey-list-item>
        <enrollments-list-item :item="item" :modules="modules" :user="user" v-else-if="item.module_name == 'enrollments' && user  && user.role === 'admin'" ></enrollments-list-item>
        <timelog-list-item :item="item" :modules="modules" :user="user" v-else-if="item.module_name == 'timelog_targethours' && user  && user.role === 'admin'"></timelog-list-item>
        <shop-list-item :item="item" :modules="modules"  :user="user" v-else-if="item.module_name == 'shop' && user"></shop-list-item>
        <object-control-list-item :item="item" :modules="modules"  :user="user" v-else-if="item.module_name == 'object_control' && user"></object-control-list-item>
        <learning-portal-list-item :item="item" :modules="modules"  :user="user" v-else-if="item.module_name == 'learning_portal' && user"></learning-portal-list-item>

        <!-- Default Item -->
        <md-list-item v-else v-show="isPermission(item.permissions)">
          <router-link :to="{ path: item.slug }">
            <v-icon>{{ item.icon }}</v-icon>
            <span class="module-title">{{ item.title }}</span>
          </router-link>
        </md-list-item>

        <md-divider 
          v-if="item.module_name == 'timesheet' ||
          item.module_name == 'chat' ||
          item.module_name == 'servappt' ||
          item.module_name == 'quality_report' ||
          item.module_name == 'surveys' && user  && user.role === 'admin' ||
          item.module_name == 'enrollments' && user  && user.role === 'admin' ||
          item.module_name == 'timelog_targethours' && user  && user.role === 'admin' ||
          isPermission(item.permissions)">
        </md-divider>
      </template>

      <md-list-item v-if="user.role === 'admin' || user.role === 'coordinator'">
        <router-link :to="{ path: '/users' }">
          <v-icon>mdi-account-group-outline</v-icon>
          <span class="module-title">{{ t('users') }}</span>
        </router-link>
      </md-list-item>
      <md-divider/>

      <!-- Einstellungen -->
      <md-list-item v-if="user && user.role === 'admin'">
        <v-icon>mdi-cogs</v-icon>
        <span class="module-title">{{ t('settings') }}</span>
        <md-list-expand>
          <md-list>
            <md-list-item class="md-inset">
              <router-link :to="{ path: '/settings/global' }">
                <span>{{ t('settings-general') }}</span>
              </router-link>
            </md-list-item>

            <md-list-item class="md-inset">
              <router-link :to="{ path: '/settings/client' }">
                <span>{{ t('settings-client') }}</span>
              </router-link>
            </md-list-item>

            <md-list-item class="md-inset" v-if="moduleEnabled('timesheet')">
              <router-link :to="{ path: '/settings/timetracking' }">
                <span>{{ t('settings-time') }}</span>
              </router-link>
            </md-list-item>

            <md-list-item class="md-inset">
              <router-link :to="{ path: '/settings/permission-groups' }">
                <span>{{ t('settings-permissiongroups') }}</span>
              </router-link>
            </md-list-item>

            <md-list-item class="md-inset" v-if="moduleEnabled('ideabox')">
              <router-link :to="{ path: '/settings/ideabox' }">
                <span>{{ t('settings-ideabox') }}</span>
              </router-link>
            </md-list-item>

            <md-list-item class="md-inset" v-if="canAccessAbsenceReasonSettings()">
              <router-link :to="{ path: '/settings/absences-reasons' }">
                <span>{{ t('settings-absencereasons') }}</span>
              </router-link>
            </md-list-item>

            <md-list-item class="md-inset" v-if="canAccessAbsenceEmailSettings()">
              <router-link :to="{ path: '/settings/absence' }">
                <span>{{ t('settings-absenceemails') }}</span>
              </router-link>
            </md-list-item>

            <md-list-item class="md-inset" v-if="canAccessExpenseEmailSettings()">
              <router-link :to="{ path: '/settings/expense-types' }">
                <span>{{ t('settings-expensetypes') }}</span>
              </router-link>
            </md-list-item>


            <md-list-item class="md-inset" v-if="canAccessExpenseEmailSettings()">
              <router-link :to="{ path: '/settings/expense' }">
                <span>{{ t('settings-expenseemails') }}</span>
              </router-link>
            </md-list-item>


            <md-list-item class="md-inset" v-if="moduleEnabled('tasks')">
              <router-link :to="{ path: '/settings/tasks' }">
                <span>{{ t('settings-taskcategories') }}</span>
              </router-link>
            </md-list-item>

            <md-list-item class="md-inset" v-if="moduleEnabled('news')">
              <router-link :to="{ path: '/settings/news-categories' }">
                <span>{{ t('settings-newscategories') }}</span>
              </router-link>
            </md-list-item>

            <md-list-item class="md-inset" v-if="moduleEnabled('jobs')">
              <router-link :to="{ path: '/settings/jobs-categories' }">
                <span>{{ t('settings-jobcategories') }}</span>
              </router-link>
            </md-list-item>

            <md-list-item class="md-inset" v-if="moduleEnabled('timelog_wv') || moduleEnabled('surveys_wv')">
              <router-link :to="{ path: '/settings/webview' }">
                <span>{{ t('settings-webview') }}</span>
              </router-link>
            </md-list-item>

            <md-list-item class="md-inset">
              <router-link :to="{ path: '/settings/upload/import' }">
                <span>{{ t('settings-apiimport') }}</span>
              </router-link>
            </md-list-item>

            <md-list-item class="md-inset">
              <router-link :to="{ path: '/settings/privacy-policy' }">
                <span>{{ t('settings-privacypolicy') }}</span>
              </router-link>
            </md-list-item>

            <md-list-item class="md-inset" v-if="moduleEnabled('work_report')">
              <router-link :to="{ path: '/settings/work-reports/selection-lists' }">
                <span>{{ t('settings-workreports-selectionlists') }}</span>
              </router-link>
            </md-list-item>

            <md-list-item class="md-inset" v-if="moduleEnabled('work_report')">
              <router-link :to="{ path: '/settings/work-reports/settings' }">
                <span>{{ t('settings-workreports-settings') }}</span>
              </router-link>
            </md-list-item>

            <md-list-item class="md-inset" v-if="moduleEnabled('chat') && isAdmin()">
              <router-link :to="{ path: '/settings/chat' }">
                <span>{{ t('settings-chat') }}</span>
              </router-link>
            </md-list-item>

            <md-list-item class="md-inset" v-if="moduleEnabled('qc_notifications')">
              <router-link :to="{ path: '/settings/qc-notifications' }">
                <span>{{ t('qc-notifications') }}</span>
              </router-link>
            </md-list-item>

            <md-list-item class="md-inset">
              <router-link :to="{ path: '/settings/menu' }">
                <span>{{ t('menu') }}</span>
              </router-link>
            </md-list-item>
          </md-list>
        </md-list-expand>
      </md-list-item>
      <md-divider></md-divider>
      <!-- Einstellungen END -->

      <!-- Profile START -->
      <md-list-item v-if="user">
        <router-link :to="{ path: '/me' }">
          <v-icon>face</v-icon>
          <span class="module-title">{{ t('profile') }}</span>
        </router-link>
      </md-list-item>
      <md-divider></md-divider>
      <!-- Profile END -->

      <!-- Logout END -->
      <md-list-item @click.native="logout">
        <v-icon>exit_to_app</v-icon>
        <span class="module-title">{{ t('logout') }}</span>
      </md-list-item>
      <!-- Logout END -->

    </md-list>

    <div class="quick-settings">
      <locale-switcher></locale-switcher>
    </div>

    <div style="margin-left: 20px; color: #888888; font-size: 9px;">Version {{ buildId }} ({{ gitId }})</div>

  </md-sidenav>
</template>

<script>
import { hasPermission } from '@/services/PermissionService'
import { hasModule } from '@/services/LicenseService'
import store from 'store'
import { EventBus } from './../../event-bus'
import auth from '@/auth/index'
import LocaleSwitcher from '@/components/layouts/LocaleSwitcher'
import TimesheetListItem from './list-items/TimesheetListItem.vue'
import ChatListItem from './list-items/ChatListItem'
import ServapptListItem from './list-items/ServapptListItem'
import ChecklistListItem from './list-items/ChecklistListItem'
import SurveyListItem from './list-items/SurveyListItem'
import EnrollmentsListItem from './list-items/EnrollmentsListItem'
import TimelogListItem from './list-items/TimelogListItem'
import ShopListItem from './list-items/ShopListItem.vue'
import ObjectControlListItem from './list-items/ObjectControlListItem'
import LearningPortalListItem from './list-items/LearningPortalListItem'

let BUILDID = process.env.VUE_APP_BUILDID ?? 'local'
let GITCOMMIT = process.env.VUE_APP_GITCOMMIT ?? process.env.VUE_APP_GITINFO

export default {
  name: 'SideNavigation',
  components: {
    LocaleSwitcher,
    TimesheetListItem,
    ChatListItem,
    ServapptListItem,
    ChecklistListItem,
    SurveyListItem,
    EnrollmentsListItem,
    TimelogListItem,
    ShopListItem,
    ObjectControlListItem,
    LearningPortalListItem
  },
  data() {
    return {
      user: null,
      customIconAuswahlfelder: './../../assets/images/icn-auswahlfelder.svg',
      modules: [],
      buildId: BUILDID,
      gitId: GITCOMMIT ? GITCOMMIT.slice(0, 8) : 'n/a',
      VUE_APP_APP_CHAT: process.env.VUE_APP_APP_CHAT,
      activeModules: null,
    }
  },
  mounted() {
    this.updateMenu();
    this.getUser();

    EventBus.$on('module-config-changed', function () {
      this.updateMenu();
    }.bind(this));
  },
  props: {
    dataLoaded: {
      type: Boolean,
      required: false,
      default: false
    },
    menu: {
      type: Boolean,
      required: false,
      default: true
    },
    back: {
      type: Boolean,
      required: false,
      default: false
    },
    path: {
      type: String,
      required: false,
      default: ''
    },
    event: {
      required: false,
      default: false
    },
    cancel: {
      required: false,
      default: false
    },
    cancelLeft: {
      required: false,
      default: false
    },
    ok: {
      type: Boolean,
      required: false,
      default: false
    },
    edit: {
      type: Boolean,
      required: false,
      default: false
    },
    search: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    showMenu() {
      return (!this.back && !this.cancel && !this.cancelLeft)
    }
  },
  methods: {
    t: function (key) {
      return this.$t('navigation.' + key);
    },
    getUser() {
      this.user = store.get('user')
    },
    updateMenu() {
      let modules = store.get('modules')
      this.activeModules = store.get('userModules')
      let moduleNames = [];

      modules.forEach(item => {
        if ( item.active === true) {
          moduleNames.push(item.module_name);
        }
      });

      this.modules = moduleNames;
    },
    showModel(item) {
      return this.moduleEnabled(item.module_name) && item.backend && item.active && item.main_item && this.checkPermission(item.permissions);
    },
    isPermission(permissionString) {
      // Check if the permissionString is null or empty
      if (permissionString === null || permissionString.trim() === '') {
        return true; // return true if permissions not defined
      }

      // Split the permissionString into an array of permissions
      let permissions = permissionString.split(",");

      // Check if any of the permissions exist in the system
      return hasPermission(permissions);
    },
    moduleEnabled(string) {
      return hasModule(string)
    },
    canAppointmentsSee() {
      return hasPermission('servappt_appointment_manage') || this.isAdmin();
    },
    canRequestsSee() {
      return hasPermission('servappt_request_manage') || this.isAdmin();
    },
    canAreaSee() {
      return hasPermission('servappt_area_manage') || this.isAdmin();
    },
    canAccessAbsenceReasonSettings() {
      return hasModule('absence') && (hasPermission(['absence_reason_manage']) || this.isAdmin())
    },
    canAccessAbsenceEmailSettings() {
      return hasModule('absence') && (hasPermission(['absence_settings_email']) || this.isAdmin())
    },
    canAccessExpenseEmailSettings() {
      return hasModule('expense') && (hasPermission(['expense_settings_email']) || this.isAdmin())
    },
    logout() {
      auth.logout()
    },
    toggle() {
      if (this.$refs.sidenavigation) {
        this.$refs.sidenavigation.toggle()
      }
    },
    checkPermission(permissionString) {
      if (permissionString == null) {
        return true;
      }

      let permissionArray = permissionString.split(",");
      return hasPermission(permissionArray) || this.isAdmin();

    },
    isAdmin() {
      return this.user && this.user.role === "admin"
    },
  }
}
</script>

<style>
.module-title {
  margin-right: auto;
  margin-left: 20px;
}
</style>