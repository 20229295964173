<template>
  <div>
    <index-list
      :title="t('title')"
      :headers="headers"
      initialSortBy="name"
      :initialSortDesc="false"
      :loadData="loadData"
    >
      <template v-slot:toolbar-buttons-right>
        <create-button to-path="/settings/absences-reasons/create" />
      </template>
      <template #column-name="{ item }">
        <span class="ellipsis"><b>{{ item.name }}</b></span>
      </template>

      <template #column-edit="{ item }">
        <router-link :to="{ path: '/settings/absences-reasons/' + item.id }" data-cy="editButton" class="edit-row-table">
          <v-icon small class="edit-row-button">edit</v-icon>
        </router-link>
      </template>
    </index-list>
  </div>
</template>

<script>
import { HTTP } from "./../auth"
import CreateButton from "@/components/CreateButton.vue"
import IndexList from "@/components/IndexList.vue"

export default {
  name: "AbsencesReasonsIndex",
  components: {
    IndexList,
    CreateButton
  },
  computed: {
    headers() {
      return [
        {
          text: this.t("table.name"),
          align: "start",
          sortable: true,
          value: "name",
          cellClass: "text-middle"
        },
        {
          text: this.t("table.edit"),
          value: "edit",
          sortable: false,
          align: "center",
          width: 90,
          cellClass: "text-middle"
        }
      ]
    }
  },
  methods: {
    t: function(key) {
      return this.$t("absences.reasons.list." + key)
    },
    async loadData(options){
      let response = await HTTP.get("absences/reasons", { params: options })
      return {
        items: response.data.data,
        totalItems: response.data.total
      }
    }
  }
}
</script>
