<template>
  <div>
    <BasicNewContainer
      :title="t('create.title')"
      ref="editor"
      @cancel="close"
      :saveAction="saveItem"
      @saved="onSaved"
      :has-changes="hasChanges">
      <absences-reasons-content v-model="data" />
    </BasicNewContainer>
  </div>
</template>

<script>
import { HTTP } from "./../auth"
import BasicNewContainer from "@/components/inputs/BasicNewContainer"
import AbsencesReasonsContent from "@/views/AbsencesReasonsContent.vue"

export default {
  name: "AbsencesReasonsCreate",
  components: {
    AbsencesReasonsContent,
    BasicNewContainer
  },
  data() {
    return {
      data: {
        name: ""
      },
      dataOriginal: null,
      indexRouteName: "AbsencesReasonsIndex"
    }
  },
  created() {
    this.dataOriginal = JSON.parse(JSON.stringify(this.data))
  },
  mounted() {
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal)
    }
  },
  methods: {
    t: function(key) {
      return this.$t("absences.reasons.detail." + key)
    },
    saveItem() {
      return HTTP.post("absences/reasons", this.data)
    },
    async onSaved() {
      this.dataOriginal = null
      this.close()
    },
    close() {
      this.$router.push({ name: this.indexRouteName })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
