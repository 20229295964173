<template>
    <v-select
        class=""
        v-model="selectedFilterStatus"
        :label="$t('components.contact-type-filter.label')"
        :items="filterStatus"
        item-text="title"
        item-value="id"
        chips
        :data-cy="dataCy"
    ></v-select> 
</template>
  
<script>
import { HTTP } from '@/auth'

export default {
    name: "ContactTypeFilter",
    model: {
        prop: 'model',
        event: 'input'
    },
    props: {
        dataCy: {
            default: ''
        },
        model: {
            type: [String, Array, Number],
            default: () => [null]       
        },
    },
    data() {
        return {
            selectedFilterStatus: this.value,
            filterStatus: [],
        }
    },
    watch: {
        selectedFilterStatus: function (val) {
            this.$emit('input', val)
        },
        model: function(val) {
            this.selectedFilterStatus = val
        }
    },
    created() {
        this.loadData()
    },
    methods: {
        loadData() {
            HTTP.get('qc_notifications/receipt_types').then(function (response) {
                this.filterStatus = response.data
            }.bind(this)).catch(function (error) {
                console.log(error)
            }.bind(this))
        }
    }
}
</script>
