<template>
  <v-btn
    elevation="0"
    color="primary"
    class="icon-left"
    data-cy="createButton"
    :to="{ path: toPath}"
  >
    <v-icon>mdi-plus</v-icon>
    {{ $t("create-button.content") }}
  </v-btn>
</template>

<style scoped lang="scss">

</style>
<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    toPath: {
      type: String,
      required: true,
    },
  },
});
</script>