<template>
    <v-text-field
        type="number"
        filled
        :label="label"
        v-model.number="localValue"
        :suffix="suffix"
        @keypress="filter($event)"
        @keyup="formatNumber"
        :rules="rules"
    ></v-text-field>
</template>

<script>
export default {
    name: 'NumberInput',
    props: {
        value: {
            default: null
        },
        label: {
            type: String,
            default: ''
        },
        suffix: {
            type: String,
            default: ''
        },
        decimals: {
            type: Number,
            default: 0
        },
        rules: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            localValue: this.value
        }
    },
    watch: {
        localValue: function (val) {
            this.$emit('input', val)
        }
    },
    methods: {
        filter(evt) {
            evt = (evt) ? evt : window.event
            let expect = evt.target.value.toString() + evt.key.toString()          
            if (!/^[0-9]*\.?[0-9]*$/.test(expect)) {
                evt.preventDefault()
            } else {
                return true
            }
        },
        countDecimals(value) {
            if (value) {
                if (Math.floor(value) === value) return 0
                return value.toString().split(".")[1].length || 0
            } else {
                return 0
            }
        },
        formatNumber() {
            if (this.countDecimals(this.localValue) > this.decimals) {
                this.localValue = Number.parseFloat(this.localValue).toFixed(this.decimals)
            }
        }
    }
}
</script>