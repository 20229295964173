<template>
  <div>
    <index-list
      :title="t('title')"
      :headers="headers"
      initialSortBy="name"
      :initialSortDesc="false"
      :loadData="loadData"
    >
      <template v-slot:toolbar-buttons-right>
        <create-button to-path="/time-management/sites/create" />
      </template>

      <template #column-name="{ item }">
        <span class="ellipsis"><b>{{ item.name }}</b></span>
      </template>

      <template #column-edit="{ item }">
        <router-link :to="{ path: '/time-management/sites/' + item.id }" data-cy="editButton" class="edit-row-table">
          <v-icon small class="edit-row-button">mdi-rename-box</v-icon>
        </router-link>
      </template>

      <template #column-target-hours="{ item }">
        <router-link :to="{ path: '/time-management/sites/' + item.id + '/target-hours' }" data-cy="editButton" class="edit-row-table">
          <v-icon small class="edit-row-button">mdi-pencil</v-icon>
        </router-link>
      </template>
    </index-list>
  </div>
</template>

<script>
import { HTTP } from "@/auth"
import CreateButton from "@/components/CreateButton"
import IndexList from "@/components/IndexList"

export default {
  name: "TimelogSettingsSitesIndex",
  components: {
    IndexList,
    CreateButton
  },
  computed: {
    headers() {
      return [
        {
          text: this.t("table-name"),
          align: "start",
          sortable: true,
          value: "name",
          cellClass: "text-middle"
        },
        {
          text: this.t("table-rename"),
          value: "edit",
          sortable: false,
          align: "center",
          width: 90
        },
        {
          text: this.t("table-target_hours"),
          value: "target-hours",
          sortable: false,
          align: "center",
          width: 90
        }
      ]
    }
  },
  methods: {
    t: function(key) {
      return this.$t("timelog-sites-index." + key)
    },
    async loadData(options) {
      let response = await HTTP.get("timelog/sites", { params: options })
      return {
        items: response.data,
        totalItems: response.data.length
      }
    }
  }
}
</script>
