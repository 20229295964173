<template>
  <div class="has-hero-container">
    <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

    <div class="sp-hero sp-bg-light-blue">

      <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter(); options.page = 1">

        <app-filter-apply-refresh-button
          v-if="filterStore.hasChanges"
          @click="applyFilter(); options.page = 1"
        />

        <v-slide-group show-arrows>
          <v-slide-item>
            <div class="filter-search-list">
              <div class="filter-item">
                <div class="filter-item-wrapper">
                  <app-filter-search-field
                    v-model="filterStore.filter.searchTerm"
                    :filterLabel="$t('components.app-filter-search-field.label.user')"
                    clearable
                    append-icon="mdi-magnify"
                  />
                </div>
              </div>
            </div>
          </v-slide-item>

          <v-slide-item>
            <div class="filter-item user-roles-filter">
              <v-select
                v-model="filterStore.filter.roles"
                :label="t('filter.roles')"
                :items="roles"
                item-text="name"
                item-value="code"
                multiple
                chips
              ></v-select>
            </div>
          </v-slide-item>

          <v-slide-item>
            <div class="filter-item user-permissions-filter">
              <permission-group-selector
                v-model="filterStore.filter.permission_groups"
                mode="dropdown"/>
            </div>
          </v-slide-item>

          <v-slide-item>
            <div class="filter-item user-active-filter">
              <v-checkbox
                class="mt-0"
                v-model="filterStore.filter.activeStates"
                :label="t('filter.show-active')"
                value="active"
                hide-details="auto"
                @change="validateActiveStates($event)"
              />
              <v-checkbox
                class="mt-0"
                v-model="filterStore.filter.activeStates"
                :label="t('filter.show-inactive')"
                value="inactive"
                hide-details="auto"
                @change="validateActiveStates($event)"
              />
            </div>
          </v-slide-item>
        </v-slide-group>
      </v-container>
    </div>

    <v-container fluid>

      <v-row class="data-table-toolbar">
        <v-spacer></v-spacer>
        <div v-show="dataLoaded && items.length > 0">
          <v-btn
            outlined
            color="primary"
            elevation="0"
            class="icon-left"
            :disabled="!readyToDownload"
            :loading="isDownloadingExport"
            @click="exportExcel()"
            data-cy="excelButton"
          >
            <v-icon>mdi-download</v-icon>
            {{ t('excel-export') }}
          </v-btn>
        </div>

        <div>
          <v-btn
            elevation="0"
            color="primary"
            class="icon-left"
            :to="{ path: getCreatePath() }"
            :disabled="isDownloadingExport"
          >
            <v-icon>mdi-plus</v-icon>
            {{ t('create') }}
          </v-btn>
        </div>
      </v-row>

      <v-row>

        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalItems"
          :loading="loading"
          class="elevation-0"
          :sort-by.sync="filterStore.table.sortBy"
          :sort-desc.sync="filterStore.table.sortDesc"
          :page.sync="filterStore.table.currentPage"
          :items-per-page.sync="filterStore.table.itemsPerPage"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            'items-per-page-options': [25, 50, 100, 200],
            pageText: '{0}-{1} von {2}',
            'items-per-page-text': t('table.items-per-page')
          }"
        >
          <template v-slot:[`item.picture`]="{ item }">
            <span v-if="!item.number.startsWith('CU')" class="user-imported" title="importiert"><md-icon>refresh-auto</md-icon></span>
            <span v-if="item.profile_image !== ''" class="user-profile-image">
              <img class="user-profile-image"
                v-if="item.profile_image"
                :src="baseUrl + '/api/users/image/' + item.id + '/' + item.profile_image" />
            </span>
            <span v-else class="user-profile-image user-placeholder"><md-icon>person</md-icon></span>
          </template>

          <template v-slot:[`item.firstname`]="{ item }">
            <span><v-icon v-if="!item.active" :title="t('inactive')" class="inactive-hint">mdi-alert-outline</v-icon>{{ item.firstname }}</span>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            {{ item.name }}
          </template>

          <template v-slot:[`item.number`]="{ item }">
            {{ item.number }}
          </template>

          <template v-slot:[`item.username`]="{ item }">
            {{ item.username }}
          </template>

          <template v-slot:[`item.role`]="{ item }">
            {{ $t('users.data.roles.' + item.role) }}
          </template>

          <template v-slot:[`item.is_online`]="{ item }">
            <span v-if="item.is_online" class="state-online">Online</span>
            <span v-else><span class="state-offline">Offline</span><span class="online-date">{{ formatDateTime(item.last_login) }}</span></span>
          </template>

          <template v-slot:[`item.push`]="{ item }">
            <span v-if="item.device_info && item.device_info.length > 0">
              <v-icon v-if="item.device_info[0].push_enabled" color="success">mdi-cellphone-check</v-icon>
              <v-icon v-else color="warning">mdi-cellphone-remove</v-icon>
            </span>
          </template>

          <template v-slot:[`item.edit`]="{ item }">
            <router-link v-show="isAdminOrCoordinator()" :to="{ path: '/users/' + item.id }">
              <v-icon small class="edit-row-button">
                edit
              </v-icon>
            </router-link>
          </template>
        </v-data-table>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { HTTP } from '@/auth'
import axios from 'axios'
import store from 'store'
import moment from 'moment'
import _ from 'lodash'
import toolbar from '@/components/layouts/Navigation'
import search from '@/components/inputs/Search'
import { EventBus } from '@/event-bus.js'
import edit from '@/views/UsersEdit'
import create from '@/views/UsersCreate'
import loading from '@/components/layouts/Loading'
import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
import AppFilterSearchField from '@/components/vuetify/AppFilterSearchField'
import PermissionGroupSelector from '@/components/PermissionGroupSelector'
import AppFilterApplyRefreshButton from '@/components/vuetify/AppFilterApplyRefreshButton'
import { useUserFilterStore } from '@/stores/UserFilterStore'

export default {
  name: 'UsersIndex',
  components: {
    AppFilterApplyRefreshButton,
    AppFilterSearchField,
    AppFilterApplyButton,
    toolbar,
    search,
    edit,
    create,
    loading,
    PermissionGroupSelector
  },
  setup() {
    const filterStore = useUserFilterStore()
    return { filterStore }
  },
  data() {
    return {
      loading: false,
      items: [],
      totalItems: null,
      options: {},
      isShowSearchfield: false,
      isDownloadingExport: false,
      users: {},
      user: null,
      results: 0,
      dataLoaded: false,
      showEdit: false,
      editFormItemId: null,
      showCreate: false,
      message: null,
      error: false,
      cancelHTTP: null,
      errorCancel: null,
      baseUrl: process.env.VUE_APP_API_BASE_URL ?? ''
    }
  },
  created() {
    this.getUser()
    this.setTableOptions()
    EventBus.$on('search', function () {
      if (this.isShowSearchfield) {
        this.isShowSearchfield = false
      } else {
        this.isShowSearchfield = true
      }
    }.bind(this))
    EventBus.$on('cancel-user', function (msg) {
      this.showEdit = false
      this.showCreate = false
    }.bind(this))
    EventBus.$on('update-user', function (msg) {
      this.dataLoaded = false
      this.message = msg.message
      this.showEdit = false
      this.showCreate = false
      var index = _.findIndex(this.users.data, ['id', msg.item.id])
      if (typeof this.users.data[index] !== 'undefined') {
        this.users.data[index].role = msg.item.role
      }
      this.openSnackbar()
      this.getUsers()
    }.bind(this))
    EventBus.$on('reload', function (msg) {
      this.reload()
    }.bind(this))
  },
  beforeDestroy() {
    EventBus.$off('cancel-user')
    EventBus.$off('update-user')
    EventBus.$off('reload')
    EventBus.$off('search')
  },
  computed: {
    roles() {
      return [
        {"code": "admin", "name": this.$t('users.data.roles.admin')},
        {"code": "coordinator", "name": this.$t('users.data.roles.coordinator')},
        {"code": "employee", "name": this.$t('users.data.roles.employee')},
        {"code": "customer", "name": this.$t('users.data.roles.customer')},
        {"code": "none", "name": this.$t('users.data.roles.none')},
      ];
    },
    headers() {
      let headers = [
        {
          text: this.t('table.profile-picture'),
          align: 'start',
          sortable: false,
          value: 'picture'
        },
        {
          text: this.t('table.firstname'),
          value: 'firstname',
          sortable: true,
          width: 200,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.lastname'),
          value: 'name',
          sortable: true,
          width: 200,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.number'),
          value: 'number',
          sortable: false,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.staff_number'),
          value: 'staff_number',
          sortable: false,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.username'),
          value: 'username',
          sortable: true,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.role'),
          value: 'role',
          sortable: false,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.online-status'),
          value: 'is_online',
          sortable: false,
          cellClass: 'text-middle'
        }
      ]

      if (this.user && this.user.role === 'admin') {
        headers.push(
          {
            text: this.t('table.push'),
            value: 'push',
            sortable: false,
            align: 'center'
          }
        )
      }

      headers.push(
        {
          text: this.t('table.edit'),
          value: 'edit',
          sortable: false,
          align: 'center'
        }
      )
      
      return headers
    }
  },
  watch: {
    options: {
      handler() {
        this.getUsers()
      },
      deep: true,
    }
  },
  methods: {
    t: function (key) {
      return this.$t('users.list.' + key)
    },
    getUser() {
      this.user = store.get('user')
    },
    isAdminOrCoordinator() {
      return this.user && (this.user.role === "admin" || this.user.role === "coordinator")
    },
    reload() {
      this.$nextTick(function () {
        this.getUsers()
      }.bind(this))
    },
    setTableOptions() {
      this.options.itemsPerPage = this.filterStore.table.itemsPerPage
      this.options.page = this.filterStore.table.currentPage
      this.options.sortBy = this.filterStore.table.sortBy
      this.options.sortDesc = this.filterStore.table.sortDesc
    },
    getCreatePath() {
      let path = '/users/create';
      const queryParams = this.parseQueryParams(window.location.search)

      if (typeof queryParams.customer_id !== "undefined") {
        path += "?customer_id=" + queryParams.customer_id
      }
      return path
    },
    getUsers() {
      this.loading = true
      let roleFilter = this.filterStore.filter.roles.join(",")
      let nameFilter = this.filterStore.filter.searchTerm ?? ''
      let activeFilter = this.filterStore.filter.activeStates.join(",")
      let permissionGroupFilter = this.filterStore.filter.permission_groups

      const queryParams = this.parseQueryParams(window.location.search);
      let queryString = 'users?q=' + nameFilter + '&role=' + roleFilter + '&activeFilter=' + activeFilter + "&permission_groups=" + permissionGroupFilter

      if (typeof queryParams.customer_id !== "undefined") {
        queryString += "&customer_id=" + queryParams.customer_id
      }

      HTTP.get(queryString, {params: this.options}).then(function (response) {
        this.items = response.data.data
        this.totalItems = response.data.total
        this.dataLoaded = true
        this.error = false
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
        }
      }.bind(this))
        .finally(() => {
          this.loading = false;
        })
    },
    parseQueryParams(url) {
      const params = {}
      const queryString = url.split('?')[1]

      if (queryString) {
        const keyValuePairs = queryString.split('&')

        keyValuePairs.forEach(keyValuePair => {
          const [key, value] = keyValuePair.split('=')
          const decodedKey = decodeURIComponent(key)
          const decodedValue = decodeURIComponent(value || '')
          params[decodedKey] = decodedValue
        })
      }

      return params
    },
    applyFilter() {
      this.filterStore.filterApplied()
      this.getUsers()
    },
    getSearchResult(val) {
      if (this.cancelHTTP) {
        this.cancelHTTP()
      }
      HTTP.get('users?q=' + val, {
        cancelToken: new axios.CancelToken(function executor(c) {
          this.cancelHTTP = c
        }.bind(this))
      }).then(function (response) {
        this.users.data = response.data
        this.countResults()
        this.dataLoaded = true
        this.error = false
      }.bind(this)).catch(function (error) {
        this.errorCancel = error
      }.bind(this))
    },
    createNew() {
      this.showCreate = true
    },
    countResults() {
      this.results = this.users.data.length
    },
    showEditForm(userId) {
      this.editFormItemId = userId
      this.showEdit = true
    },
    openSnackbar() {
      let that = this
      setTimeout(() => {
        that.$refs.snackbar.open()
      }, 2000)
    },
    readyToDownload: function () {
      return this.dataLoaded && !this.isDownloadingExport
    },
    exportExcel: function () {
      let roleFilter = this.filterStore.filter.roles.join(",")
      let nameFilter = this.filterStore.filter.searchTerm ?? ''
      let activeFilter = this.filterStore.filter.activeStates.join(",")
      let permissionGroupFilter = this.filterStore.filter.permission_groups

      const queryParams = this.parseQueryParams(window.location.search)
      let queryString = 'dataexport/excel/users?q=' + nameFilter + '&role=' + roleFilter + '&activeFilter=' + activeFilter + "&permission_groups=" + permissionGroupFilter

      if (typeof queryParams.customer_id !== "undefined") {
        queryString += "&customer_id=" + queryParams.customer_id
      }

      this.isDownloadingExport = true

      HTTP.post(queryString, {params: this.options}, {responseType: 'arraybuffer'}).then(function (response) {
        let headers = response.headers
        let blob = new Blob([response.data], {type: headers['content-type']})
        let link = document.createElement('a')
        link.style = 'display: none'
        link.href = window.URL.createObjectURL(blob)
        link.download = 'Export_' + moment().format('DDMMYYYY-HHmm') + '.xlsx'
        document.body.appendChild(link)
        link.click()
        setTimeout(function () {
          document.body.removeChild(link)
          window.URL.revokeObjectURL(blob)
        }, 100)
        this.isDownloadingExport = false
      }.bind(this))
    },
    validateActiveStates(event) {
      if (event.length == 0) {
        this.filterStore.filter.activeStates = ['active', 'inactive']
      }
    }
  }
}
</script>

<style scoped lang="scss">
.user-roles-filter,
.user-permissions-filter {
  min-width: 320px;

  ::v-deep .v-select__selections {

    input {
      width: 0;
    }
  }
}

.user-active-filter {
  padding-top: 14px !important;
}

.inactive-hint {
  position: relative;
  top: -2px;
  margin-right: 4px;
}

.user-profile-image {
  position: absolute;
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.30);
}

.user-name {
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 16px !important;
}

.user-role {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
}

.state-online {
  color: #71AE5C;
  font-weight: bold;
}

.state-offline {
  color: #FB8C00;
  font-weight: bold;
}

.online-date {
  display: block;
  font-size: 12px;
  line-height: 1;
}

.user-name,
.user-role {
  padding-left: 56px;
}

.user-placeholder i {
  display: block;
  font-size: 30px;
  line-height: 36px;
  width: 40px;
  height: 40px;
  text-align: center;
  color: rgba(0, 0, 0, 0.30);
}

.user-imported {
  position: absolute;
  z-index: 1;
  top: 5px;
  left: 40px;
  border-radius: 100%;
  border: 1px solid #999;
  background: #FFF;
  overflow: hidden;
  cursor: pointer;

  i {
    color: #999;
  }
}

.md-theme-default a:not(.md-button):hover {
  text-decoration: none;
}
</style>
