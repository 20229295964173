<template>
  <v-btn
    elevation="0"
    color="primary"
    class="filter-apply icon-left"
    @click="$emit('click', arguments)"
    data-cy="applyButton"
  >
    <v-icon>mdi-check</v-icon>
    {{ $t('components.filter-apply.label') }}
  </v-btn>
</template>

<script>
export default {
  name: "AppFilterApplyButton"
}
</script>
